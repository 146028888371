import { PhotoThumbnailSizes } from '@site-mate/dashpivot-shared-library';

export const PhotoThumbnailDropdownOptions = [
  {
    label: 'XS',
    value: PhotoThumbnailSizes.ExtraSmall,
  },
  {
    label: 'S',
    value: PhotoThumbnailSizes.Small,
  },
  {
    label: 'M',
    value: PhotoThumbnailSizes.Medium,
  },
  {
    label: 'L',
    value: PhotoThumbnailSizes.Large,
  },
  {
    label: 'XL',
    value: PhotoThumbnailSizes.ExtraLarge,
  },
];
