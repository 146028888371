import { Component, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  selector: 'cc-location-column-template-preview',
  templateUrl: './location-column-template-preview.component.html',
  styleUrl: './location-column-template-preview.component.scss',
})
export class LocationColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
