<ng-select
  *ngIf="filter"
  placeholder="Select or search"
  i18n-placeholder="Select or search"
  class="select-multiple"
  [class.empty]="!filter.selectedValues?.length"
  bindValue="value"
  [items]="filter.options"
  [multiple]="true"
  autofocus
  [(ngModel)]="filter.selectedValues"
  (change)="onSelectionChange()"
>
</ng-select>
