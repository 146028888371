import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { CategorySourceTypes, CategoryTypes } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  templateUrl: './category-template-preview.component.html',
  styleUrls: ['./category-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTemplatePreviewComponent {
  @Input() field: FieldWeb;

  readonly categoryTypes = CategoryTypes;
  readonly categorySourceTypes = CategorySourceTypes;
}
