<div class="form-group row app-control-row sm-flex sm-cursor-grab sm-flex-row">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>

  <div class="app-control-label form-inline">
    <cc-template-dropdown-radio-input
      [options]="options"
      [fieldId]="model.id"
      [value]="model.categoryType"
      (selectOption)="onSelectOption($event)"
      [identifier]="identifier"
      [hierarchy]="hierarchy"
      iconClass="cc-icon-list"
    ></cc-template-dropdown-radio-input>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>

  <div class="sm-flex sm-w-full sm-flex-auto sm-flex-col sm-p-2" *ngIf="fullList | async as fullListQuery">
    <div class="sm-flex sm-flex-col sm-gap-y-2" *ngIf="listIdentifiers$ | async as listIdentifiersQuery">
      <div class="sm-flex sm-flex-row sm-flex-nowrap sm-items-center sm-gap-x-2">
        <label i18n class="category-label">List source:</label>
        <div class="form-inline">
          <div class="form-check">
            <label class="default-label form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="{{ model.id + 'categorySource' }}"
                [value]="controlType.categorySource.manual"
                [(ngModel)]="model.categorySource"
                (ngModelChange)="onChangeListSource($event)"
              />
              <span i18n>Manual typed</span>
            </label>
          </div>
          <div class="ml-3 form-check">
            <label class="default-label form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="{{ model.id + 'categorySource' }}"
                [value]="controlType.categorySource.list"
                [(ngModel)]="model.categorySource"
                (ngModelChange)="onChangeListSource($event)"
              />
              <span i18n>Link to a List</span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="model.categorySource === controlType.categorySource.manual">
        <div class="input-group">
          <input
            type="text"
            class="form-control border-right-0 input-add-item"
            placeholder="list item"
            i18n-placeholder="list item"
            #categoryItem
            (keyup.enter)="addRow(categoryItem)"
          />
          <div class="input-group-append">
            <button class="btn btn-secondary btn-add-item" type="button" i18n (click)="addRow(categoryItem)">
              Add list item
            </button>
          </div>
          <div class="sm-flex sm-items-center">
            <span class="sm-mx-2" i18n>Default colour:</span>
            <cc-style-color-picker
              [(style)]="model.defaultColor"
              (styleChange)="updated.emit(model)"
            ></cc-style-color-picker>
          </div>
        </div>
        <div>
          <label class="default-label category-label" i18n>List items:</label>
          <div *ngIf="model.items.length === 0">
            <div class="sm-text-default-red" i18n>Please add list items above</div>
            <div>
              <img
                *ngIf="isList"
                src="./assets/images/category-empty-state-list.png"
                alt="list"
                class="placeholder-list"
              />
            </div>
            <div>
              <img
                *ngIf="isInline"
                src="./assets/images/category-empty-state-inline.png"
                alt="inline"
                class="placeholder-inline"
              />
            </div>
          </div>
        </div>
        <div class="app-category-item-container" [class.app-category-item-container-list]="isList">
          <cc-app-category-item
            *ngFor="let row of model.items | slice: 0 : displayableCount"
            [row]="row"
            [showControl]="true"
            [editingRow]="editingRow"
            (onEdit)="onRowEdit($event)"
            [displayColorPicker]="isList || isInline"
            (remove)="removeItem($event)"
          ></cc-app-category-item>
          <div class="mt-2 text-center w-100" *ngIf="model.items.length > allItems.resumedAmount">
            <button
              type="button"
              class="btn btn-primary show-all-manually-typed"
              (click)="toggleAllItems()"
              *ngIf="!allItems.enabled"
            >
              Show All ({{ model.items.length }})
            </button>
            <button
              type="button"
              class="btn btn-primary show-less-manually-typed"
              (click)="toggleAllItems()"
              *ngIf="allItems.enabled"
            >
              Show Less ({{ allItems.resumedAmount }})
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="model.categorySource === CategorySourceTypes.List">
        <div class="sm-flex sm-flex-row sm-flex-nowrap sm-items-center sm-gap-x-2">
          <label i18n class="category-label">Project list:</label>
          <ng-select
            class="category-list"
            [(ngModel)]="model.reference"
            (change)="onListSelected($event)"
            i18n-placeholder="Select a list"
            placeholder="Select a list"
            bindValue="_id"
            *ngIf="listIdentifiersQuery.state !== queryStates.Loading"
            [class.border-danger]="fullListQuery.state === queryStates.Error"
          >
            <ng-option
              *ngIf="fullListQuery.state === queryStates.Error"
              [value]="availableIdentifierForInvalidList._id"
            >
              <span>{{ availableIdentifierForInvalidList.name }}</span>
            </ng-option>
            <ng-option *ngFor="let list of listIdentifiersQuery.data" [value]="list._id">
              <span>{{ list.name }}</span>
            </ng-option>
          </ng-select>

          <p *ngIf="fullListQuery.state === queryStates.Error" class="sm-text-default-red">
            {{ fullListQuery.error.message }}
          </p>

          <div class="d-block list-deployment-status" *ngIf="app.isOrganisationTemplate">
            <label
              for="{{ model?.id }}autoDeployChildList"
              class="default-label mr-2 align-middle d-inline-block interactive"
              >Auto-deploy a child list</label
            >
            <input
              type="checkbox"
              id="{{ model?.id }}autoDeployChildList"
              [(ngModel)]="model.autoDeployChildList"
              class="align-middle"
              data-cy="auto-deploy-checkbox"
            />
            <a
              href="https://intercom.help/dashpivot/en/articles/4499919-list-deployment"
              target="_blank"
              class="ml-2 align-middle btn btn-sm cc-btn-subtle-dark-gray-helper"
            >
              <i class="cc-icon cc-icon-question"></i>
              <span i18>Learn more</span>
            </a>
          </div>
        </div>

        <div class="sm-pt-2">
          <div>
            <label class="default-label category-label" i18n>List items:</label>
            <div
              *ngIf="fullListQuery.state === queryStates.Loading"
              class="sm-flex sm-items-center sm-gap-2"
              data-test="loading"
            >
              <i class="cc-icon cc-icon-spinner-lg" aria-hidden="true"></i>
              <div class="sm-text-secondary-text">Loading list items...</div>
            </div>

            <div
              *ngIf="
                fullListQuery.state === queryStates.Error ||
                (fullListQuery.state === queryStates.Fetched && fullListQuery.data.items.length === 0)
              "
              class="sm-text-secondary-text"
            >
              No list items available.
            </div>

            <ng-container
              *ngIf="!model.reference || fullListQuery.state === queryStates.Empty"
              #selectDropdown
            >
              <div class="sm-text-default-red" i18n>Please select a list from the dropdown above</div>
              <div>
                <img
                  *ngIf="isList"
                  src="./assets/images/category-empty-state-list.png"
                  alt="list"
                  class="placeholder-list"
                />
              </div>
              <div>
                <img
                  *ngIf="isInline"
                  src="./assets/images/category-empty-state-inline.png"
                  alt="inline"
                  class="placeholder-inline"
                />
              </div>
            </ng-container>
          </div>

          <div
            *ngIf="fullListQuery.state === queryStates.Fetched && fullListQuery.data.items.length > 0"
            class="app-category-item-container"
            [class.app-category-item-container-list]="isList"
          >
            <cc-app-category-item
              *ngFor="let row of fullListQuery.data.items | slice: 0 : displayableCount"
              [row]="row"
              [showControl]="false"
              [displayColorPicker]="isList || isInline"
            ></cc-app-category-item>
            <div
              class="mt-2 text-center w-100"
              *ngIf="fullListQuery.data.items.length > allItems.resumedAmount"
            >
              <button
                type="button"
                class="btn btn-primary show-all-referenced"
                (click)="toggleAllItems()"
                *ngIf="!allItems.enabled"
              >
                Show All ({{ fullListQuery.data.items.length }})
              </button>
              <button
                type="button"
                class="btn btn-primary show-less-referenced"
                (click)="toggleAllItems()"
                *ngIf="allItems.enabled"
              >
                Show Less ({{ allItems.resumedAmount }})
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <label
    class="default-label logic-control no-drag btn"
    (click)="onAddLogic()"
    *ngIf="!model.dependsOn?.fieldId"
  >
    <i class="mr-1 cc-icon cc-icon-logic"></i>
    <span class="control-label" i18n>Add logic</span>
  </label>
</div>

<cc-logic-rule-list
  [field]="model"
  [fields]="controls"
  [template]="app"
  [metadataList]="metadataList"
  [companyId]="companyId"
  [parentId]="parentId"
  [fieldUpdated]="updated"
  (updated)="updated.emit($event)"
  [templateOrientation]="templateOrientation"
  [hasReachedPlanLimit]="hasReachedPlanLimit"
  [workspaceItemsLimit]="workspaceItemsLimit"
></cc-logic-rule-list>
