import { Injectable } from '@angular/core';
import { ObjectID } from 'bson';

import { CategorySourceTypes, TableCellKinds } from '@site-mate/dashpivot-shared-library';

import {
  DefaultCell,
  PrefilledCell,
  StandardDefaultCell,
  StandardPrefilledCell,
  ListDefaultCell,
  ListPrefilledCell,
  PrefilledTextDefaultCell,
  PrefilledTextPrefilledCell,
  FormulaDefaultCell,
  FormulaPrefilledCell,
  LocationDefaultCell,
  LocationPrefilledCell,
} from './table-cell.model';

@Injectable({
  providedIn: 'root',
})
export class TableCellService {
  private readonly nonStandardCellKinds: TableCellKinds[] = [
    TableCellKinds.Formula,
    TableCellKinds.PrefilledText,
    TableCellKinds.List,
    TableCellKinds.Location,
  ];

  cloneDefaultToPrefilledCell(defaultCell: DefaultCell): PrefilledCell {
    if (this.nonStandardCellKinds.includes(defaultCell.kind)) {
      return this.cloneDefaultToPrefilledNonStandardCell(defaultCell);
    }

    return this.cloneDefaultToPrefilledStandardCell(defaultCell);
  }

  private cloneDefaultToPrefilledStandardCell(defaultCell: StandardDefaultCell): StandardPrefilledCell {
    const newId = new ObjectID().toHexString();
    const { kind, description, width } = defaultCell;

    return {
      id: newId,
      _id: newId,
      headerColumnId: defaultCell.id,
      kind,
      description,
      width,
    };
  }

  private cloneDefaultToPrefilledNonStandardCell(defaultCell: DefaultCell): PrefilledCell {
    switch (defaultCell.kind) {
      case TableCellKinds.Formula:
        return this.cloneDefaultToPrefilledFormulaCell(defaultCell);
      case TableCellKinds.PrefilledText:
        return this.cloneDefaultToPrefilledPrefilledTextCell(defaultCell);
      case TableCellKinds.List:
        return this.cloneDefaultToPrefilledListCell(defaultCell);
      case TableCellKinds.Location:
        return this.cloneDefaultToPrefilledLocationCell(defaultCell);
      default:
        throw new Error(`Unknown cell kind ${defaultCell.kind}`);
    }
  }

  private cloneDefaultToPrefilledListCell(defaultCell: ListDefaultCell): ListPrefilledCell {
    const newCell: ListPrefilledCell = this.cloneDefaultToPrefilledStandardCell(defaultCell);
    newCell.listSource = defaultCell.listSource;
    newCell.reference = defaultCell.reference;
    newCell.defaultColor = defaultCell.defaultColor;
    newCell.autoDeployChildList = defaultCell.autoDeployChildList;

    if (defaultCell.listSource === CategorySourceTypes.Manual) {
      const newManualListItems = defaultCell.manualListItems?.map(({ value, style }) => ({
        id: new ObjectID().toHexString(),
        value,
        style,
      }));
      newCell.manualListItems = newManualListItems;
    }

    return newCell;
  }

  private cloneDefaultToPrefilledPrefilledTextCell(
    defaultCell: PrefilledTextDefaultCell,
  ): PrefilledTextPrefilledCell {
    const newCell: PrefilledTextPrefilledCell = this.cloneDefaultToPrefilledStandardCell(defaultCell);
    newCell.content = defaultCell.content;

    return newCell;
  }

  private cloneDefaultToPrefilledFormulaCell(defaultCell: FormulaDefaultCell): FormulaPrefilledCell {
    const newCell: FormulaPrefilledCell = this.cloneDefaultToPrefilledStandardCell(defaultCell);
    newCell.formula = defaultCell.formula;
    newCell.formulaError = defaultCell.formulaError;

    return newCell;
  }

  private cloneDefaultToPrefilledLocationCell(defaultCell: LocationDefaultCell): LocationPrefilledCell {
    const newCell: LocationPrefilledCell = this.cloneDefaultToPrefilledStandardCell(defaultCell);
    newCell.item = null;

    return newCell;
  }

  clonePrefilledToDefaultCell(currentDefaultCell: DefaultCell, prefilledCell: PrefilledCell): DefaultCell {
    if (this.nonStandardCellKinds.includes(prefilledCell.kind)) {
      return this.clonePrefilledToDefaultNonStandardCell(currentDefaultCell, prefilledCell);
    }

    return this.clonePrefilledToDefaultStandardCell(currentDefaultCell, prefilledCell);
  }

  private clonePrefilledToDefaultStandardCell(
    currentDefaultCell: DefaultCell,
    prefilledCell: PrefilledCell,
  ): DefaultCell {
    const { kind, description } = prefilledCell;
    const { id, _id, heading, width, autoDeployChildList, defaultColor } = currentDefaultCell;

    return {
      id,
      _id,
      heading,
      kind,
      description,
      width,
      autoDeployChildList,
      defaultColor,
    };
  }

  private clonePrefilledToDefaultNonStandardCell(
    currentDefaultCell: DefaultCell,
    prefilledCell: PrefilledCell,
  ): DefaultCell {
    switch (prefilledCell.kind) {
      case TableCellKinds.Formula:
        return this.clonePrefilledToDefaultFormulaCell(currentDefaultCell, prefilledCell);
      case TableCellKinds.PrefilledText:
        return this.clonePrefilledToDefaultPrefilledTextCell(currentDefaultCell, prefilledCell);
      case TableCellKinds.List:
        return this.clonePrefilledToDefaultListCell(currentDefaultCell, prefilledCell);
      case TableCellKinds.Location:
        return this.clonePrefilledToDefaultStandardCell(currentDefaultCell, prefilledCell);
      default:
        throw new Error(`Unknown cell kind ${prefilledCell.kind}`);
    }
  }

  private clonePrefilledToDefaultListCell(
    currentDefaultCell: DefaultCell,
    prefilledCell: ListPrefilledCell,
  ): ListDefaultCell {
    const newCell: ListDefaultCell = this.clonePrefilledToDefaultStandardCell(
      currentDefaultCell,
      prefilledCell,
    );
    newCell.listSource = prefilledCell.listSource;
    newCell.reference = prefilledCell.reference;
    newCell.defaultColor = prefilledCell.defaultColor;
    newCell.autoDeployChildList = prefilledCell.autoDeployChildList;

    if (prefilledCell.listSource === CategorySourceTypes.Manual) {
      newCell.manualListItems = prefilledCell.manualListItems;
    }

    return newCell;
  }

  private clonePrefilledToDefaultPrefilledTextCell(
    currentDefaultCell: DefaultCell,
    prefilledCell: PrefilledTextPrefilledCell,
  ): PrefilledTextDefaultCell {
    const newCell: PrefilledTextDefaultCell = this.clonePrefilledToDefaultStandardCell(
      currentDefaultCell,
      prefilledCell,
    );
    newCell.content = prefilledCell.content;

    return newCell;
  }

  private clonePrefilledToDefaultFormulaCell(
    currentDefaultCell: DefaultCell,
    prefilledCell: FormulaPrefilledCell,
  ): FormulaDefaultCell {
    const newCell: FormulaDefaultCell = this.clonePrefilledToDefaultStandardCell(
      currentDefaultCell,
      prefilledCell,
    );
    newCell.formula = prefilledCell.formula;
    newCell.formulaError = prefilledCell.formulaError;

    return newCell;
  }
}
