export enum FormulaErrorTypes {
  EMPTY = '#EMPTY!',
  CYCLE = '#CYCLE!',
  VALUE = '#VALUE!',
  REF = '#REF!',
  NOTEQUALSIGN = '#NOTEQUALSIGN!',
  DIV0 = '#DIV/0!',
  NUM = '#NUM!',
  NAME = '#NAME?',
  NA = '#N/A',
  ERROR = '#ERROR!',
  NESTED = '#NESTED!',
}

export const FormulasErrors = new Map([
  [
    FormulaErrorTypes.EMPTY, // custom error, not part of hyperformula
    { message: 'Formula input required.', type: FormulaErrorTypes.EMPTY, severity: 'high' },
  ],
  [
    FormulaErrorTypes.NOTEQUALSIGN, // custom error, not part of hyperformula
    { message: 'Start formula with an = sign.', type: FormulaErrorTypes.NOTEQUALSIGN, severity: 'high' },
  ],
  [
    FormulaErrorTypes.NAME,
    {
      message: 'Formula name/function is not supported by Dashpivot.',
      type: FormulaErrorTypes.NAME,
      severity: 'high',
    },
  ],
  [
    FormulaErrorTypes.CYCLE,
    {
      message:
        'Circular dependency detected in formula. The formula is referencing itself in a circular manner.',
      type: FormulaErrorTypes.CYCLE,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.VALUE,
    {
      message: 'Invalid input value detected in formula.',
      type: FormulaErrorTypes.VALUE,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.REF,
    {
      message: 'Invalid reference detected in formula.',
      type: FormulaErrorTypes.REF,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.DIV0,
    {
      message: 'Formulas cannot be divided by zero.',
      type: FormulaErrorTypes.DIV0,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.NUM,
    {
      message: 'Invalid numeric value detected in formula.',
      type: FormulaErrorTypes.NUM,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.NA,
    {
      message: 'Value in function is not available.',
      type: FormulaErrorTypes.NA,
      severity: 'medium',
    },
  ],
  [
    FormulaErrorTypes.ERROR,
    {
      message: 'Unknown error detected in formula.',
      type: FormulaErrorTypes.ERROR,
      severity: 'high',
    },
  ],
  [
    FormulaErrorTypes.NESTED,
    {
      message: 'Unable to support more than five levels of nested parentheses.',
      type: FormulaErrorTypes.NESTED,
      severity: 'high',
    },
  ],
]);
