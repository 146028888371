import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-table-diff',
  templateUrl: './form-table-diff.component.html',
  styleUrls: ['./form-table-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTableDiffComponent implements OnInit {
  @Input() field: FieldWeb;

  ngOnInit(): void {
    this.field.readOnly = true;
  }
}
