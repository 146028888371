<div class="form-group row app-control-row sm-flex sm-cursor-grab sm-flex-row">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>

  <div class="app-control-label form-inline form-group">
    <div class="inline-block mr-2">
      <button type="button" class="btn btn-secondary app-control-dropdown">
        <i class="cc-icon cc-icon-user"></i>
      </button>
    </div>
    <div class="form-control no-drag" [class.has-error]="model._invalid">
      <input
        type="text"
        [(ngModel)]="model.description"
        (ngModelChange)="personFieldDescriptionUpdated()"
        class="sm-w-full sm-rounded sm-py-[8px] sm-pl-legacy-2.4 sm-outline-none"
      />
      <i
        *ngIf="model._invalid"
        [gwTooltip]="personFieldNameError"
        gwTooltipIndicatorPosition="left"
        gwTooltipWindowClass="sm-text-default-text"
        class="cc-icon cc-icon-warning sm-cursor-pointer sm-pl-0.4 sm-text-default-red"
      ></i>
    </div>
  </div>
  <div class="sm-flex sm-items-center sm-gap-2 sm-p-2">
    <ng-select
      [readonly]="true"
      [class.disabled]="true"
      class="dropdown-selector sm-min-w-2 sm-w-fit"
      name="personSelector"
      [placeholder]="'Select...'"
    >
    </ng-select>
  </div>
</div>
