/* eslint-disable max-lines */
import { Component, ElementRef, inject, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  EventTypes,
  FieldKinds,
  ILegacyList,
  LegacyColumnWidthUnits,
  TableCellKinds,
  TableColumn,
  TableColumnWidths,
  TableColumnWidthUnits,
  TableDisplayMode,
  TemplateOrientationMaxColumnWidths,
  TemplateOrientations,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { TemplateListService } from 'app/apps/template-list.service';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';
import { AccountService } from 'app/shared/service/account.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { FormulaV2TemplateEngineService } from 'app/shared/service/formula-v2-template-engine/formula-v2-template-engine.service';
import { FormulaV2ValidationService } from 'app/shared/service/formula-v2-validation/formula-v2-validation.service';
import { HotFormulaParserEngineService } from 'app/shared/service/hot-formula-parser-engine.service';
import { environment } from 'environments/environment';

import { AppTableColumnService } from './app-table-column.service';
import { AppTableRowService } from './app-table-row.service';
import { FormulaBarService } from './formula-bar/formula-bar.service';
import { TableDisplayModes } from './table-display-modes';

@Component({
  selector: 'cc-app-table',
  templateUrl: 'app-table.component.html',
  styleUrls: ['app-table.component.scss'],
})
export class TableTemplateComponent extends TemplateFieldBaseComponent implements OnInit {
  private readonly templateListService = inject(TemplateListService);
  readonly TableCellKinds = TableCellKinds;
  readonly bannerKeys = BannerKeys;
  readonly bannerMetadata = BannerMetadata;

  readonly tableColumnWidths = TableColumnWidths;
  @ViewChild('tableCore') table: ElementRef;

  unSubscribe = new Subject<void>();
  tableDisplayModes = TableDisplayModes.list();
  readonly templateOrientations = TemplateOrientations;
  landscapeOverflowThreshold = TemplateOrientationMaxColumnWidths[TemplateOrientations.Landscape];
  portraitOverflowThreshold = TemplateOrientationMaxColumnWidths[TemplateOrientations.Portrait];
  displayErrorMessage = false;
  hoverColumn = null;

  readonly columnWidthOptions = [
    { name: 'S', value: this.tableColumnWidths.Small },
    { name: 'M', value: this.tableColumnWidths.Medium },
    { name: 'L', value: this.tableColumnWidths.Large },
    { name: 'XL', value: this.tableColumnWidths.ExtraLarge },
  ];

  readonly disableRequiredOptionCellKinds = [TableCellKinds.ListProperty, TableCellKinds.Location];

  protected listPropertyTableCellBetaBannerText: string;

  horizontalTablesSupport = { enabled: environment.featureToggles.tableInversion };
  tableInFocus: string;

  protected listPropertyTableColumnIds = new Set<string>();

  companyPlanType: CompanyPlanTypes;
  showRequiredOptionByIndex: boolean[] = [];

  constructor(
    protected readonly i18nService: TmpI18NService,
    private readonly hotFormulaParserEngineService: HotFormulaParserEngineService,
    private readonly formulaBarService: FormulaBarService,
    private readonly formulaV2ValidationService: FormulaV2ValidationService,
    private readonly appTableRowService: AppTableRowService,
    private readonly appTableColumnService: AppTableColumnService,
    private readonly formulaV2TemplateEngineService: FormulaV2TemplateEngineService,
    private readonly eventsService: EventsService,
    private readonly accountService: AccountService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToFormulaOnFocus();
    this.syncListColumnMap();

    // Need to run a migraton to remove the code below;
    if (!this.model.rows) {
      this.model.rows = [];
    }

    if (!this.model.displayRowsMode) {
      this.model.displayRowsMode = TableDisplayModes.defaultMode().key as TableDisplayMode;
    }

    this.model.columns.forEach((column) => {
      column.width = column.width ?? TableColumnWidths.Legacy;
    });

    this.checkForColumnOverflow();

    this.listPropertyTableCellBetaBannerText = this.i18nService.getMessage('listPropertyTableTellBetaBanner');

    this.accountService.currentAccount.pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.companyPlanType = this.accountService.getAccountPlanType();
    });
    this.showRequiredOptionByIndex = this.model.columns.map((_, index) => this.showRequiredOption(index));
  }

  private subscribeToFormulaOnFocus() {
    this.formulaBarService.formulaOnFocus$.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
      this.tableInFocus = event?.tableId;
    });
  }

  private syncListColumnMap() {
    if (this.model.kind !== FieldKinds.Table) {
      return;
    }

    const listColumnMap = new Map<number, ILegacyList['_id']>();

    this.model.columns.forEach((column, index) => {
      if (column.kind === TableCellKinds.List && column.reference) {
        listColumnMap.set(index, column.reference);
      }

      if (column.kind === TableCellKinds.ListProperty) {
        const columnId = String(column._id ?? column.id);
        this.listPropertyTableColumnIds.add(columnId);
      }
    });

    this.templateListService.setListColumnMap(this.model._id, listColumnMap);
  }

  onChange(changes: SimpleChanges) {
    if (changes.templateOrientation) {
      this.checkForColumnOverflow();
    }
  }

  changeColumnSize(width: TableColumnWidths) {
    this.checkForColumnOverflow();

    this.eventsService.trackEvent(EventTypes.TemplateTableColumnWidthApplied, { Context: width });
  }

  trackByColumnIds(index: number, column: TableColumn) {
    return column.id;
  }

  setHoverOnColumn(columnIndex: number) {
    this.hoverColumn = columnIndex;
  }

  onCellKindChange(column: TableColumn) {
    const columnId = String(column._id ?? column.id);

    if (this.listPropertyTableColumnIds.has(columnId) && column.kind !== TableCellKinds.ListProperty) {
      this.listPropertyTableColumnIds.delete(columnId);
    }

    if (column.kind === TableCellKinds.ListProperty) {
      this.listPropertyTableColumnIds.add(columnId);
    }

    if (column.kind === TableCellKinds.Formula) {
      setTimeout(() => this.openFormulaBar(column), 0);

      if (this.isFormulaV2) {
        this.formulaV2TemplateEngineService.calculateSheetContent(this.model);
        return;
      }
    }

    this.showRequiredOptionByIndex = this.model.columns.map((_, index) => this.showRequiredOption(index));
    this.updated.emit();
  }

  private openFormulaBar(column: TableColumn) {
    this.formulaBarService.openFormulaBar({
      id: column.id,
      formula: column.formula,
      formulaError: column.formulaError,
      tableId: this.model._id,
      focusOnOpen: true,
    });
  }

  onFormulaChange() {
    if (this.isFormulaV2) {
      this.formulaV2ValidationService.calculateAndVerifyTable(this.model);
    } else {
      this.hotFormulaParserEngineService.verifyItem(this.model);
    }
  }

  scrollToRight() {
    const t = this.table.nativeElement;
    const offset = t.scrollWidth - t.getBoundingClientRect().width;
    if (offset > 0) {
      t.scrollLeft = offset;
    }
  }

  addColumn(columnIndex?: number) {
    this.appTableColumnService.addColumn(this.model, columnIndex);
    this.notifyAddColumnEvent(columnIndex);
    if (columnIndex !== undefined) {
      const targetColumn = this.model.columns[columnIndex];
      if (targetColumn.kind === TableCellKinds.ListProperty) {
        this.listPropertyTableColumnIds.add(String(targetColumn._id || targetColumn.id));
      }
    }

    this.updated.emit();
    this.checkForColumnOverflow();
    setTimeout(() => this.scrollToRight(), 100);
  }

  removeColumn(index: number) {
    const column = this.model.columns[index];
    this.listPropertyTableColumnIds.delete(String(column._id ?? column.id));
    this.appTableColumnService.removeColumn(index, this.model);
    this.updated.emit();
    this.syncListColumnMap();
    this.checkForColumnOverflow();
  }

  moveColumnLeft(index: number) {
    this.appTableColumnService.moveColumnLeft(index, this.model);
    this.updated.emit();
    this.syncListColumnMap();
  }

  moveColumnRight(index: number) {
    this.appTableColumnService.moveColumnRight(index, this.model);
    this.updated.emit();
    this.syncListColumnMap();
  }

  addRow(rowIndex: number) {
    this.appTableRowService.addRow(rowIndex, this.model);

    this.updated.emit();
    this.notifyAddRowEvent(rowIndex);
  }

  private notifyAddRowEvent(rowIndex: number) {
    const addRowEvent =
      rowIndex === undefined
        ? EventTypes.TemplateTableRowAdded
        : EventTypes.TemplatePrefilledTableRowMidwayAdded;

    this.eventsService.trackEvent(addRowEvent);
  }

  private notifyAddColumnEvent(columnIndex: number) {
    const addColumnEvent =
      columnIndex === undefined
        ? EventTypes.TemplateTableColumnAdded
        : EventTypes.TemplateTableColumnMidwayAdded;

    const Context = this.isPrefilledTable ? 'Prefilled' : 'Default';

    this.eventsService.trackEvent(addColumnEvent, { Context });
  }

  removeRow(rowIndex: number) {
    this.appTableRowService.removeRow(rowIndex, this.model, this.isFormulaV2);

    this.eventsService.trackEvent(EventTypes.TemplateTableRowRemoved);
    this.updated.emit();
  }

  moveUp(index: number) {
    if (index !== 0) {
      this.appTableRowService.moveRowUp(index, this.model, this.isFormulaV2);
      this.updated.emit();
    }
  }

  moveDown(index: number) {
    if (index !== this.model.rows.length - 1) {
      this.appTableRowService.moveRowDown(index, this.model, this.isFormulaV2);
      this.updated.emit();
    }
  }

  formulaColumnLabel(_column: TableColumn, headerIndex: number) {
    return this.hotFormulaParserEngineService.getColumnLabel(headerIndex);
  }

  isRequiredColumn(column: TableColumn) {
    const isRequiredColumn = this.isPrefilledTable || column.kind !== TableCellKinds.PrefilledText;
    if (!isRequiredColumn) {
      column.isRequired = false;
    }
    return isRequiredColumn;
  }

  checkForColumnOverflow() {
    const totalRowWidth = this.model.columns.reduce((acc, col) => acc + this.getColumnWidth(col.width), 0);

    const isOrientationPortrait = this.templateOrientation === this.templateOrientations.Portrait;
    const isOrientationLandscape = this.templateOrientation === this.templateOrientations.Landscape;

    const isColumnOverflow =
      (isOrientationPortrait && totalRowWidth > this.portraitOverflowThreshold) ||
      (isOrientationLandscape && totalRowWidth > this.landscapeOverflowThreshold);

    this.displayErrorMessage = isColumnOverflow;
  }

  private getColumnWidth(width: TableColumnWidths): number {
    if (width === TableColumnWidths.Legacy) {
      return LegacyColumnWidthUnits[this.templateOrientation];
    }
    return TableColumnWidthUnits[width];
  }

  get hasFormulaColumn() {
    return this.hotFormulaParserEngineService.hasFormulaColumn(this.model);
  }

  get isFormulaV2() {
    return this.app?.formulasVersion === 2;
  }

  get isPrefilledTable() {
    return this.model.kind === FieldKinds.PrefilledTable;
  }

  extendFirstCellTypeToAllRows(columnIndex: number) {
    this.appTableRowService.extendFirstCellTypeToAllRows(columnIndex, this.model);
    this.updated.emit();
  }

  ngOnDestroy() {
    this.templateListService.removeListColumnMap(this.model._id);
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  showRequiredOption(columnIndex: number): boolean {
    const columnKind = this.isPrefilledTable
      ? this.model.rows[0].columns[columnIndex].kind
      : this.model.columns[columnIndex].kind;
    return !this.disableRequiredOptionCellKinds.includes(columnKind);
  }
}
