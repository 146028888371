<div
  class="field-item {{ headerFooterField.size }} {{ headerFooterField.kind }} sm-items-center"
  [class.error-state]="hasPrefilledTextError"
  [class.no-drag]="hasPrefilledTextError"
>
  <div class="sm-flex sm-items-center sm-space-x-1 sm-w-full {{ headerFooterField.kind }}-container">
    <i
      class="cc-icon cc-icon-grip-dot-vertical sm-text-secondary-text"
      aria-hidden="true"
      data-test="field-grip"
    ></i>
    <div class="field-icon-container sm-flex sm-w-full sm-items-center">
      <div class="form-component-text sm-w-full sm-align-middle">
        <!-- Normal Fields -->
        <div *ngIf="headerFooterField.kind !== headerFooterFieldKinds.PrefilledText">
          <ng-container *ngIf="hasPreviewText; else noPreviewText">
            <span>
              <strong>{{ headerFooterField.name }} :</strong>
              [{{ headerFooterField.contentPreviewText }}]
            </span>
          </ng-container>
        </div>
        <!-- Prefilled Text Field -->
        <cc-header-footer-prefilled-text-field
          *ngIf="headerFooterField.kind === headerFooterFieldKinds.PrefilledText"
          [template]="app"
          [headerFooterField]="headerFooterField"
          [hasPrefilledTextError]="hasPrefilledTextError"
          (setPrefilledTextError)="setPrefilledTextError($event)"
          (updated)="updated.emit(headerFooterField)"
        >
        </cc-header-footer-prefilled-text-field>
      </div>
      <ng-template #noPreviewText>
        <span [class.bracketed-label]="headerFooterField.contentPreviewText">{{
          headerFooterField.contentPreviewText ?? headerFooterField.name
        }}</span>
      </ng-template>
    </div>
  </div>
  <button type="button" class="btn remove-btn" (click)="remove.emit()">
    <em class="cc-icon cc-icon-close sm-text-default-red"></em>
  </button>
</div>
<div *ngIf="hasPrefilledTextError" class="popover-container" data-test="popover-container">
  <div class="popover-content">
    <p i18n>Text cannot exceed {{ characterLimit }} characters</p>
  </div>
</div>
