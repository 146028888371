import { Directive, ViewContainerRef, ElementRef } from '@angular/core';

@Directive({
  selector: '[ccTemplateFieldHost]',
})
export class TemplateFieldHostDirective {
  constructor(
    public readonly viewContainerRef: ViewContainerRef,
    public readonly element: ElementRef,
  ) {}
}
