import { Field } from '@site-mate/dashpivot-shared-library';

import { CategoryAnalyticsFilterComponent } from './category/analytics/category-analytics-filter.component';
import { FormFieldAnalyticsFilter } from './common/analytics/form-field-analytics-filter';

export class FormFieldAnalyticsFilterFactory {
  private static resolver = {
    category: CategoryAnalyticsFilterComponent,
    list: CategoryAnalyticsFilterComponent,
    user: CategoryAnalyticsFilterComponent,
  };

  static getFilter(field: Field) {
    const component = this.resolver[field.kind];

    if (!component) {
      return null;
    }

    return new FormFieldAnalyticsFilter(component, field);
  }
}
