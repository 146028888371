import { Component, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-created-date-thumbnail',
  templateUrl: './created-date-thumbnail.component.html',
})
export class CreatedDateThumbnailComponent {
  @Input() field: FieldWeb;
}
