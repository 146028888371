<div class="font-weight-bold mb-3">{{ field.description }}</div>

<div class="form-table">
  <table class="table table-bordered table-responsive">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type="text" class="form-control" disabled value="Name" /></td>
        <td><input type="text" class="form-control" disabled value="Company" /></td>
        <td><input type="text" class="form-control" disabled value="Signature" /></td>
        <td><input type="text" class="form-control" disabled value="Date" /></td>
        <td><input type="text" class="form-control" disabled value="Time" /></td>
        <td><input type="text" class="form-control" disabled value="Location" /></td>
      </tr>
    </tbody>
  </table>
</div>
