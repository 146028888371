<div class="font-weight-bold mb-3">{{ field.description }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required:</span> <input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>

<div class="app-control-control">
  <button *ngIf="!field.isManualSignature" type="button" class="btn btn-success disabled sm-cursor-grab" i18n>
    Signature
  </button>
  <div *ngIf="field.isManualSignature">
    <button type="button" class="btn btn-success disabled sm-cursor-grab" i18n>Sign manually</button>
  </div>
</div>
