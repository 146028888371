<div class="form-group row app-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline">
    <div
      ngbDropdown
      class="sm-relative sm-mr-2 sm-inline-block"
      #dropdown="ngbDropdown"
      (mouseenter)="dropdown.open()"
      (mouseleave)="dropdown.close()"
    >
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-secondary app-control-dropdown"
          (click)="$event.preventDefault(); dropdown.open()"
          ngbDropdownAnchor
        >
          <i class="cc-icon cc-icon-calendar-clock sm-align-middle"></i>
        </button>
      </div>
      <div ngbDropdownMenu class="dropdown-menu px-3" (click)="$event.stopPropagation(); dropdown.close()">
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Date"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'dateType'"
            [value]="controlType.dateTimeRange"
            [attr.data-cy]="controlType.dateTimeRange"
            (change)="calculateModel()"
            [(ngModel)]="model.dateType"
            [id]="'text-date-0-' + identifier"
          />
          <label i18n [for]="'text-date-0-' + identifier" class="default-label form-check-label"
            >Date time range</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Date"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'dateType'"
            [value]="controlType.dateRange"
            [attr.data-cy]="controlType.dateRange"
            (change)="calculateModel()"
            [(ngModel)]="model.dateType"
            [id]="'text-date-1-' + identifier"
          />
          <label i18n [for]="'text-date-1-' + identifier" class="default-label form-check-label"
            >Date range</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Date"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'dateType'"
            [value]="controlType.singleDateTime"
            [attr.data-cy]="controlType.singleDateTime"
            (change)="calculateModel()"
            [(ngModel)]="model.dateType"
            [id]="'text-date-2-' + identifier"
          />
          <label i18n [for]="'text-date-2-' + identifier" class="default-label form-check-label"
            >Single date time</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Date"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'dateType'"
            [value]="controlType.singleDate"
            [attr.data-cy]="controlType.singleDate"
            (change)="calculateModel()"
            [(ngModel)]="model.dateType"
            [id]="'text-date-3-' + identifier"
          />
          <label i18n [for]="'text-date-3-' + identifier" class="default-label form-check-label"
            >Single date</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Date"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'dateType'"
            [value]="controlType.expiryDate"
            [attr.data-cy]="controlType.expiryDate"
            (change)="calculateModel()"
            [(ngModel)]="model.dateType"
            [id]="'text-date-4-' + identifier"
          />
          <label i18n [for]="'text-date-4-' + identifier" class="default-label form-check-label"
            >Expiry date</label
          >
        </div>
      </div>
    </div>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>
  <div class="app-control-control sm-flex sm-overflow-x-auto">
    <div class="app-date-control">
      <div class="sm-relative sm-flex sm-w-full">
        <input
          type="text"
          class="input-text-base date-input"
          placeholder="Start Date"
          readonly
          i18n-placeholder="Start Date"
        />
        <span class="input-group-btn">
          <button class="btn btn-secondary disabled" type="button">
            <i class="cc-icon cc-icon-calendar-clock sm-text-black"></i>
          </button>
        </span>
      </div>
    </div>
    <div *ngIf="model.isDateAndTime">
      <select class="input-text-base time-input" disabled>
        <option i18n>Start Time</option>
      </select>
    </div>
    <div class="text-muted form-control-static sm-mx-1" *ngIf="!model.isSingleDate">&mdash;</div>
    <div *ngIf="!model.isSingleDate">
      <div class="input-group">
        <input
          type="text"
          class="input-text-base date-input"
          placeholder="End Date"
          readonly
          i18n-placeholder="End Date"
        />
        <span class="input-group-btn">
          <button class="btn btn-secondary disabled" type="button">
            <i class="cc-icon cc-icon-calendar-clock sm-text-black"></i>
          </button>
        </span>
      </div>
    </div>
    <div *ngIf="model.isDateAndTime && !model.isSingleDate">
      <select class="input-text-base time-input" disabled>
        <option i18n>End Time</option>
      </select>
    </div>
    <div class="expiry-date sm-ml-auto" *ngIf="model.isExpiryDate">
      <i class="cc-icon cc-icon-warning sm-text-default-orange"></i><span i18n>Expires in # days</span>
    </div>
  </div>
</div>
