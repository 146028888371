<div class="font-weight-bold mb-3">{{ field.description }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required: </span><input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>
<ng-select
  [readonly]="true"
  [class.disabled]="true"
  class="dropdown-selector sm-min-w-2 sm-w-fit"
  name="personSelector"
  [placeholder]="'Select...'"
>
</ng-select>
