<div class="dropdown-form sm-full">
  <div class="sm-flex sm-w-full sm-gap-2">
    <div class="sm-flex sm-items-start sm-gap-1">
      <div class="inline-form-text-container">
        <span i18n>If</span>
      </div>
      <div>
        <cc-search-and-select
          data-test="logic-types"
          customClass="has-badge height-auto"
          placeholder="Select..."
          i18n-placeholder="Select..."
          name="{{ complementaryDropdownNames.Type + '-' + complementaryRule._id }}"
          minWidth="200px"
          [items]="complementaryTypesDropdowns"
          [(selected)]="selectedComplementaryType"
          [invalid]="isInputInvalid(complementaryDropdownNames.Type)"
          (change)="onInputChange($event, complementaryDropdownNames.Type, !!complementaryRule._invalid)"
          [disabled]="disabled"
        ></cc-search-and-select>
        <span *ngIf="isInputInvalid(complementaryDropdownNames.Type)" class="invalid-message">
          {{ complementaryRule._invalidMessage ?? inputRequiredMessage }}
        </span>
      </div>
      <div class="inline-form-text-container">
        <span i18n>is</span>
      </div>
      <div>
        <cc-search-and-select
          data-test="logic-operators"
          customClass="height-auto"
          placeholder="Select..."
          i18n-placeholder="Select..."
          name="{{ complementaryDropdownNames.Operator + '-' + complementaryRule._id }}"
          minWidth="200px"
          [items]="complementaryOperatorsDropdown"
          [(selected)]="selectedComplementaryOperator"
          [invalid]="isInputInvalid(complementaryDropdownNames.Operator)"
          [disabled]="!complementaryRule.type || disabled"
          (change)="onInputChange($event, complementaryDropdownNames.Operator, !!complementaryRule._invalid)"
        ></cc-search-and-select>
        <span *ngIf="isInputInvalid(complementaryDropdownNames.Operator)" class="invalid-message">
          {{ complementaryRule._invalidMessage ?? inputRequiredMessage }}
        </span>
      </div>
    </div>

    <div class="sm-flex-grow sm-gap-1">
      <cc-search-and-select
        data-test="logic-values"
        customClass="single-line no-clear-all-button height-auto"
        name="{{ complementaryDropdownNames.Values + '-' + complementaryRule._id }}"
        placeholder="Select..."
        i18n-placeholder="Select..."
        minWidth="200px"
        [items]="complementaryValuesDropdown"
        [(selected)]="selectedComplementaryValues"
        [clearable]="complementaryRule.operator === complementaryLogicOperators.Equals"
        [multiple]="complementaryRule.operator === complementaryLogicOperators.Equals"
        [disabled]="!complementaryRule.type || !complementaryRule.operator || disabled"
        [invalid]="isInputInvalid(complementaryDropdownNames.Values)"
        (change)="onInputChange($event, complementaryDropdownNames.Values, !!complementaryRule._invalid)"
      >
        <ng-template #customOptionTemplate let-option>
          <cc-permission-type-values-dropdown-item [option]="option" />
        </ng-template>
      </cc-search-and-select>
      <span *ngIf="isInputInvalid(complementaryDropdownNames.Values)" class="invalid-message">
        {{ complementaryRule._invalidMessage ?? inputRequiredMessage }}
      </span>
    </div>
  </div>

  <div>
    <button type="button" (click)="onDelete()">
      <i class="cc-icon cc-icon-close delete-button-icon"></i>
    </button>
  </div>
</div>
