import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

import { AppType } from 'app/apps/app-control-type.model';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';

@Component({
  selector: 'cc-app-date',
  templateUrl: 'date-template.component.html',
  styleUrls: [
    '../../common/template/form-control.component.scss',
    './date-template.component.scss',
    '../edit/form-date.component.scss',
  ],
})
export class DateTemplateComponent extends TemplateFieldBaseComponent implements AfterViewInit {
  appType = AppType;

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.model.isExpiryDate) {
      this.model.dateType = this.controlType.expiryDate;
    } else if (this.model.isSingleDate) {
      if (this.model.isDateAndTime) {
        this.model.dateType = this.controlType.singleDateTime;
      } else {
        this.model.dateType = this.controlType.singleDate;
      }
    } else if (this.model.isDateAndTime) {
      this.model.dateType = this.controlType.dateTimeRange;
    } else {
      this.model.dateType = this.controlType.dateRange;
    }
    this.changeDetectionRef.detectChanges();
  }

  calculateModel() {
    // eslint-disable-next-line default-case
    switch (this.model.dateType) {
      case this.controlType.dateTimeRange:
        this.model.isSingleDate = false;
        this.model.isDateAndTime = true;
        this.model.isExpiryDate = false;
        break;
      case this.controlType.dateRange:
        this.model.isSingleDate = false;
        this.model.isDateAndTime = false;
        this.model.isExpiryDate = false;
        break;
      case this.controlType.singleDateTime:
        this.model.isSingleDate = true;
        this.model.isDateAndTime = true;
        this.model.isExpiryDate = false;
        break;
      case this.controlType.singleDate:
        this.model.isSingleDate = true;
        this.model.isDateAndTime = false;
        this.model.isExpiryDate = false;
        break;
      case this.controlType.expiryDate:
        this.model.isSingleDate = true;
        this.model.isDateAndTime = false;
        this.model.isExpiryDate = true;
        break;
    }
  }
}
