import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { FormFieldTemplatePreviewFactory } from 'app/form-fields/form-field-template-preview-factory';
import { FieldWeb } from 'app/shared/model/item.model';

import { FormFieldTemplatePreviewHostDirective } from './form-field-template-preview-host.directive';

@Component({
  selector: 'cc-form-field-template-preview',
  templateUrl: './form-field-template-preview.component.html',
  styleUrls: ['./form-field-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldTemplatePreviewComponent implements AfterViewInit {
  @ViewChild(FormFieldTemplatePreviewHostDirective, { static: true })
  fieldHost: FormFieldTemplatePreviewHostDirective;

  @Input() field: FieldWeb;
  @Input() orientation: TemplateOrientations;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.render();
  }

  private render() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      FormFieldTemplatePreviewFactory.getRenderComponent(this.field),
    );
    const { viewContainerRef } = this.fieldHost;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const componentInstance = componentRef.instance as any;
    componentInstance.field = this.field;
    if (componentInstance.orientation) {
      componentInstance.orientation = this.orientation;
    }

    this.ref.detectChanges();
  }
}
