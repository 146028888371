<div class="form-group row app-control-row app-table-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label app-table-control-label form-inline sm-space-x-2">
    <div class="btn-group app-control-dropdown sm-h-6">
      <button type="button" class="btn btn-secondary app-control-dropdown sm-justify-center">
        <img
          data-test="sitemate-square"
          src="assets/icons/multi-colored-icons/sitemate-square.svg"
          alt="sitemate-logo"
        />
      </button>
    </div>

    <input type="text" class="form-control no-drag sm-h-6" [(ngModel)]="model.description" />

    <div class="app-control-row-nowrap" *ngIf="horizontalTablesSupport.enabled">
      <label class="default-label" [for]="model.id + 'displayRowsMode' + tableDisplayModes[0].key" i18n>
        Display rows on mobile:
      </label>
      <div *ngFor="let tableDisplayMode of tableDisplayModes" class="app-control-row-nowrap">
        <input
          type="radio"
          [name]="model.id + 'displayRowsMode'"
          [id]="model.id + 'displayRowsMode' + tableDisplayMode.key"
          [(ngModel)]="model.displayRowsMode"
          [value]="tableDisplayMode.key"
          class="app-control-spacer app-control-large-radio"
        />
        <label class="default-label" [for]="model.id + 'displayRowsMode' + tableDisplayMode.key" i18n>{{
          tableDisplayMode.description
        }}</label>
      </div>
    </div>
  </div>
  <div class="default-text-class sm-gap-2">
    <span class="sm-mr-4" i18n>
      This table enables a contactless sign-on process, by scanning the digital ID of Sitemate app users
    </span>
    <a
      href="https://intercom.help/dashpivot/en/articles/3901878-setting-up-a-touch-less-sign-on-process"
      target="_blank"
      data-test="sign-on-learn-more"
      class="btn btn-sm cc-btn-subtle-gray-helper"
    >
      <i class="cc-icon cc-icon-question"></i>
      <span i18>Learn more</span>
    </a>
    <a
      [href]="signOnPosterURL"
      class="btn btn-sm cc-btn-subtle-gray-helper"
      data-test="sign-on-download-poster"
      target="_blank"
    >
      <i class="cc-icon cc-icon-download"></i>
      <span i18>Download Poster</span>
    </a>
  </div>
  <div class="default-text-class sm-gap-1" data-test="sm-forms-warning">
    <i class="cc-icon cc-icon-information"></i>
    <span i18n>Forms submitted from the Sitemate app cannot use this field (it will appear disabled)</span>
  </div>
  <div class="sm-cursor-grab sm-p-2">
    <table aria-hidden="true">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="sm-w-[50px]"></td>
          <td *ngFor="let value of tableBodyValues">
            <input type="text" class="form-control table-input" disabled [value]="value" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
