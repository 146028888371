import { Input, OnInit, Directive, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ObjectId } from 'bson';
import { Observable } from 'rxjs';

import { Field, FieldKinds, List, Template, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { appControlType } from 'app/apps/app-control-type.model';
import { IField } from 'app/apps/template-default-fields/field.model';
import { FieldWeb } from 'app/shared/model/item.model';

import {
  FieldActionsType,
  IFieldActionsConfig,
} from './template-field-actions/template-field-actions.component';
import { IFieldSettingsAction, FieldSettingsNames } from '../types/field-settings.type';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class TemplateFieldBaseComponent implements OnInit {
  readonly identifier: number;
  controlType = appControlType;
  FieldKinds = FieldKinds;
  hierarchy: string;
  fieldActionsConfig: Record<FieldActionsType, IFieldActionsConfig>;
  fieldSettingsActions: IFieldSettingsAction[] = [];

  @Input() model: FieldWeb;
  @Input() app: Template;
  @Input() controls: Field[];
  @Input() metadataList: Observable<List[]>;
  @Input() companyId: string;
  @Input() parentId: string;
  @Input() templateOrientation: TemplateOrientations;
  @Input() headerFooterField: IField;
  @Input() hasReachedPlanLimit: boolean;
  @Input() workspaceItemsLimit: number;

  @Output() updated = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Output() required = new EventEmitter();
  @Output() hideInFormPDF = new EventEmitter();
  @Output() excludeInFormCloning = new EventEmitter();

  constructor() {
    // Must be associated here
    this.identifier = Date.now() * Math.random();
  }

  ngOnInit(): void {
    if (this.model && !this.model.id) {
      this.model.id = new ObjectId().toHexString();
    }

    this.hierarchy = this.app.isOrganisationTemplate ? 'Organisation' : 'Team';

    this.setFieldActionsConfig();
    this.setFieldSettingsActions();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(changes: SimpleChanges): void {}

  private setFieldActionsConfig() {
    this.fieldActionsConfig = {
      delete: { isVisible: true, onUpdate: this.remove },
      clone: { isVisible: true, onUpdate: this.clone },
    };
  }

  private setFieldSettingsActions() {
    if (this.model?.kind) {
      const fieldsWithHiddenRequiredToggle = [
        FieldKinds.PreFilledText,
        FieldKinds.Signature,
        FieldKinds.Table,
        FieldKinds.PrefilledTable,
        FieldKinds.SignonTable,
      ];
      const isRequiredVisible = !fieldsWithHiddenRequiredToggle.includes(this.model.kind);

      const isRequired: IFieldSettingsAction = {
        isVisible: isRequiredVisible,
        onUpdate: this.required,
        icon: 'asterisk',
        label: 'Required',
        fieldName: FieldSettingsNames.IsRequired,
      };

      const hideInFormPDF: IFieldSettingsAction = {
        isVisible: true,
        onUpdate: this.hideInFormPDF,
        icon: 'file-pdf',
        label: 'Hide in Form PDF Exports',
        fieldName: FieldSettingsNames.IsHiddenInFormPDFExports,
      };

      const excludeInFormCloning: IFieldSettingsAction = {
        isVisible: this.model.kind !== FieldKinds.PreFilledText,
        onUpdate: this.excludeInFormCloning,
        icon: 'clone',
        label: 'Exclude in Form Cloning',
        fieldName: FieldSettingsNames.isExcludedInFormCloning,
      };

      this.fieldSettingsActions = [isRequired, hideInFormPDF, excludeInFormCloning];
    }
  }
}
