import { Component, Input } from '@angular/core';

import { SegmentService } from 'app/segment/segment.service';

import { MultiSelectFilter } from './multi-select-filter';
import { FormRegisterViewFilterBaseComponent } from '../form-register-view-filter-base.component';

@Component({
  selector: 'cc-reg-view-multi-select-filter',
  templateUrl: 'form-register-view-multi-select-filter.component.html',
  styleUrls: ['../form-register-view-filter.component.scss'],
})
export class FormRegisterViewMultiSelectFilterComponent extends FormRegisterViewFilterBaseComponent {
  get filterContextType(): string {
    return 'MultiSelect';
  }
  @Input() model: MultiSelectFilter;

  constructor(protected readonly segmentService: SegmentService) {
    super(segmentService);
  }
}
