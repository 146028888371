<div class="sm-flex sm-items-center sm-space-x-0.4">
  <input
    type="text"
    #prefilledTextContent
    class="sm-w-full sm-border-none sm-outline-none"
    [class.error-state]="hasPrefilledTextError"
    data-test="prefilled-text"
    placeholder="Type text..."
    [(ngModel)]="headerFooterField.content"
    [value]="headerFooterField.content ?? ''"
    (change)="onUpdate(prefilledTextContent)"
    (keyup)="onKeyUp(prefilledTextContent)"
  />
  <i class="cc-icon cc-icon-warning sm-text-default-red" aria-hidden="true" *ngIf="hasPrefilledTextError"></i>
</div>
