import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  PhotoThumbnailSizes,
} from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { FieldWeb } from 'app/shared/model/item.model';

import { PhotoThumbnailDropdownOptions } from './photo-thumbnail-dropdown-options.model';

@Component({
  selector: 'cc-photo-thumbnail-dropdown',
  templateUrl: './photo-thumbnail-dropdown.component.html',
  styleUrls: [
    './photo-thumbnail-dropdown.component.scss',
    '../../../common/template/form-control.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoThumbnailDropdownComponent {
  @Input() model: FieldWeb;
  @Input() hierarchy: string;
  @Output() photoThumbnailChange = new EventEmitter<PhotoThumbnailSizes>();

  readonly photoThumbnailOptions = PhotoThumbnailDropdownOptions;

  constructor(private readonly segmentService: SegmentService) {}

  onPhotoThumbnailChange() {
    this.photoThumbnailChange.emit();
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.TemplateFieldDropdownOptionsClicked, {
        Context: 'Photos',
        Hierarchy: this.hierarchy,
      }),
      this.segmentService,
    );
  }
}
