<div
  [formGroup]="form"
  ngbDropdown
  class="mr-2 d-inline-block"
  #dropdown="ngbDropdown"
  (mouseenter)="dropdown.open()"
  (mouseleave)="dropdown.close()"
>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-secondary app-control-dropdown"
      (click)="$event.preventDefault(); dropdown.open()"
      ngbDropdownAnchor
    >
      <i class="cc-icon cc-icon-table"></i>
    </button>
  </div>
  <div ngbDropdownMenu (click)="$event.stopPropagation(); dropdown.close()">
    <label
      i18n
      ccSegmentCTA="Template Field Dropdown Options"
      ctaContext="Table"
      [ctaHierarchy]="hierarchy"
      *ngFor="let tableType of tableTypes"
    >
      <input
        formControlName="tableType"
        type="radio"
        [value]="tableType.type"
        [attr.data-cy]="tableType.type"
      />
      <span> {{ tableType.title }} </span>
    </label>
  </div>
</div>
