import { Component, OnInit, ViewChild } from '@angular/core';

import { FieldKinds, ThumbnailKinds } from '@site-mate/dashpivot-shared-library';

import { TemplateDropdownRadioInputComponent } from 'app/apps/template-edit/template-dropdown-radio-input/template-dropdown-radio-input.component';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ConfirmService } from 'app/shared/service/confirm.service';

@Component({
  selector: 'cc-app-text',
  templateUrl: 'app-text.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', './app-text.component.scss'],
})
export class AppTextComponent extends TemplateFieldBaseComponent implements OnInit {
  @ViewChild('dropdownRadioSelector') dropdownRadioSelector: TemplateDropdownRadioInputComponent;

  constructor(
    protected readonly i18nService: TmpI18NService,
    protected readonly confirmService: ConfirmService,
  ) {
    super();
  }
  autoFocus = false;
  isPrefilledTextType = false;
  editingPrefillText;
  editorToolbarOption = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ];

  options = [
    { value: FieldKinds.SingleLineInput, label: 'Single line' },
    { value: FieldKinds.MultiLineInput, label: 'Multi line' },
    { value: FieldKinds.PreFilledText, label: 'Prefilled' },
  ];

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.model.content) {
      this.clearContent();
    }

    this.isPrefilledTextType = this.model.kind === FieldKinds.PreFilledText;
  }

  private clearContent() {
    this.model.content = '';
  }

  async onSelectOption(option: FieldKinds) {
    if (this.isUserSwitchingToUnsupportedThumbnailKind(option)) {
      await this.openWarningModal(option);
    } else {
      this.setOption(option);
    }
  }

  isUserSwitchingToUnsupportedThumbnailKind(option: FieldKinds): boolean {
    if (!this.app.displayFormThumbnailBy.includes(this.model.id)) {
      return false;
    }

    const supportedThumbnailKinds = Object.values(ThumbnailKinds) as string[];
    return !supportedThumbnailKinds.includes(option);
  }

  private setOption(option: FieldKinds) {
    this.clearContent();
    if (option === FieldKinds.PreFilledText) {
      this.autoFocus = true;
    }
    this.model.kind = option;
  }

  openWarningModal(option: FieldKinds): Promise<void> {
    return this.confirmService
      .confirm('updateThumbnailField', { confirmButtonText: 'Update', cancelButtonText: 'Cancel' })
      .then(() => {
        this.clearInvalidDisplayFormThumbnailBy();
        this.setOption(option);
      })
      .catch(() => this.dropdownRadioSelector.setOption(this.model.kind));
  }

  clearInvalidDisplayFormThumbnailBy() {
    this.app.displayFormThumbnailBy = this.app.displayFormThumbnailBy.filter((id) => id !== this.model.id);
  }
}
