import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  IPersonFieldData,
  IUserWithSummaryOrWithoutData,
  PersonFieldUtils,
} from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';
import { SitemateUsersService } from 'app/users/sitemate-users.service';

@Component({
  selector: 'cc-person-thumbnail',
  templateUrl: './person-thumbnail.component.html',
  styleUrls: ['./person-thumbnail.component.scss'],
})
export class PersonThumbnailComponent implements OnInit {
  @Input() field: FieldWeb;
  person$ = new BehaviorSubject<(IUserWithSummaryOrWithoutData & { fullName: string }) | null>(null);

  constructor(private readonly sitemateUsersService: SitemateUsersService) {}

  get userIds(): string[] {
    return (this.field.data as IPersonFieldData)?.userIds || [];
  }

  ngOnInit(): void {
    const { userIds } = this;
    if (userIds.length > 0) {
      this.initializePerson(userIds[0]);
    }
  }

  private initializePerson(userId: string) {
    const response: Map<
      string,
      Observable<IUserWithSummaryOrWithoutData>
    > = this.sitemateUsersService.getUserDataSubjects([userId]);

    response.get(userId).subscribe({
      next: (userProfile) => {
        this.person$.next({
          ...userProfile,
          fullName: PersonFieldUtils.personFieldLabel(userProfile),
        });
      },
      error: () => this.person$.next(null),
    });
  }
}
