import { Directive, ViewContainerRef, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Directive({
  selector: '[ccTableColumnTemplatePreviewHost]',
})
export class TableColumnTemplatePreviewHostDirective {
  @Input() column: TableColumn;

  constructor(public readonly viewContainerRef: ViewContainerRef) {}
}
