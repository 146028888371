import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ObjectId } from 'bson';
import { BehaviorSubject, filter, takeWhile } from 'rxjs';

import {
  CategorySourceTypes,
  ILegacyList,
  ItemStates,
  TableCellData,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { ListIdentifierOnly, TemplateListService } from 'app/apps/template-list.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { FetchedQuery, Query } from 'app/shared/model/query/query.model';
import { ToastrService } from 'app/shared/service/toastr.service';

import { ITemplateTableCell } from '../template-table-cell.interface';

@Component({
  styleUrls: ['template-table-list-cell.component.scss'],
  templateUrl: 'template-table-list-cell.component.html',
})
export class TemplateTableListCellComponent implements ITemplateTableCell, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly templateListsService = inject(TemplateListService);
  private readonly toastrService = inject(ToastrService);
  private readonly fb = inject(FormBuilder);

  @Input() cellData: TableCellData;
  @Input() companyId: string;
  @Input() index: number;
  @Input() table: FieldWeb;
  @Input() isOrganisationTemplate: boolean;

  readonly CategorySourceTypes = CategorySourceTypes;
  editingListItem: boolean;

  protected readonly listIdentifiers$ = this.templateListsService.listIdentifiers$;
  protected readonly fullList = new BehaviorSubject<Query<ILegacyList>>({ state: QueryStates.Loading });
  protected readonly tableColumnWidths = TableColumnWidths;
  protected availableIdentifierForInvalidList: { name: string; _id: string };
  protected readonly QueryStates = QueryStates;
  public listCellForm: FormGroup;

  ngOnInit(): void {
    this.setupForm();
    this.getFullList();
  }

  private setupForm() {
    this.cellData.listSource = this.cellData.listSource || CategorySourceTypes.List;
    this.listCellForm = this.fb.group({
      listSource: [this.cellData.listSource],
      reference: [this.cellData.reference || null],
      autoDeployChildList: [this.cellData.autoDeployChildList],
    });

    this.listCellForm
      .get('listSource')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onListSourceChange.bind(this));

    this.listCellForm
      .get('autoDeployChildList')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((autoDeployChildList) => {
        this.cellData.autoDeployChildList = autoDeployChildList;
      });

    this.listCellForm
      .get('reference')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reference) => {
        this.cellData.reference = reference;
        this.templateListsService.onListReferenceChange(this.table._id, this.index, reference);
      });
  }

  private getFullList() {
    this.templateListsService
      .getFullLegacyList(this.cellData.reference)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        takeWhile(
          (listQuery) =>
            listQuery.state !== QueryStates.Fetched || listQuery.data._id === this.cellData.reference,
        ),
      )
      .subscribe((listQuery) => {
        if (listQuery.state === QueryStates.Loading || listQuery.state === QueryStates.Empty) {
          this.fullList.next(listQuery);
          return;
        }

        if (listQuery.state === QueryStates.Error) {
          this.availableIdentifierForInvalidList = { name: '', _id: '' };
          this.handleUnavailableList();
          return;
        }

        if (listQuery.state === QueryStates.Fetched) {
          this.handleFetchedList(listQuery);
        }
      });
  }

  private handleFetchedList(listQuery: FetchedQuery<ILegacyList>) {
    if (listQuery.data.state === ItemStates.Deleted) {
      this.handleDeletedList(listQuery);
      return;
    }

    this.listIdentifiers$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((listIdentifiersQuery) => listIdentifiersQuery.state === QueryStates.Fetched),
      )
      .subscribe((listIdentifiers: FetchedQuery<ListIdentifierOnly[]>) => {
        if (!listIdentifiers.data.find(({ _id }) => _id === this.cellData.reference)) {
          this.availableIdentifierForInvalidList = { name: listQuery.data.name, _id: listQuery.data._id };
          this.handleUnavailableList();
          return;
        }

        this.fullList.next({
          state: QueryStates.Fetched,
          data: listQuery.data,
        });
      });
  }

  private handleUnavailableList() {
    this.fullList.next({
      state: QueryStates.Error,
      error: new Error('The linked list does not exist within this folder. Please remove this list.'),
    });
  }

  private handleDeletedList(listQuery: FetchedQuery<ILegacyList>) {
    this.availableIdentifierForInvalidList = { name: listQuery.data.name, _id: listQuery.data._id };
    this.fullList.next({
      state: QueryStates.Error,
      error: new Error('The linked list has been deleted from this workspace. Please link a new list.'),
    });
    this.listCellForm.get('reference').reset();
    this.toastrService.errorByKey('listInTemplateIsDeleted', {
      toastClass: 'ngx-toastr toast-long-message',
    });
  }

  onListSourceChange(sourceType: CategorySourceTypes) {
    this.cellData.listSource = sourceType;
    if (sourceType === CategorySourceTypes.List) {
      delete this.cellData.manualListItems;
    } else if (sourceType === CategorySourceTypes.Manual) {
      this.listCellForm.get('reference').reset();
      this.cellData.content = [];
    }
  }

  addListItem(event: HTMLInputElement) {
    if (event.value) {
      this.cellData.manualListItems = this.cellData.manualListItems || [];

      this.cellData.manualListItems.push({
        id: String(new ObjectId()),
        value: event.value,
        style: this.cellData.defaultColor,
      });
    } else {
      this.toastrService.warnByKey('inputTextBeforeAdd');
    }
    event.value = '';
    event.focus();
  }

  removeListItem(id: string) {
    this.cellData.manualListItems = this.cellData.manualListItems.filter((i) => i.id !== id);
  }

  onEditListItem(item) {
    this.editingListItem = item;
  }

  onListSelected() {
    this.getFullList();
  }
}
