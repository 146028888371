import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  templateUrl: './formula-column-template-preview.component.html',
  styleUrls: ['./formula-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
