/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  CellValue,
  FieldKinds,
  HyperFormula,
  HyperFormulaEngine,
  RawCellContent,
  TableCellData,
  TableCellKinds,
  TableColumn,
  SimpleCellAddress,
  SimpleCellRange,
  GlobalItemModels,
} from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';
import { Positions } from 'app/shared/model/positions.enum';
import { UserService } from 'app/user/user.service';

import { FormulasErrors, FormulaErrorTypes } from '../formula-v2-validation/formula-error-messages';

@Injectable({ providedIn: 'root' })
export class FormulaV2TemplateEngineService {
  private formulaEngine: HyperFormulaEngine;
  private numberValidationCounter = 1;
  private hf: HyperFormula;
  private readonly userHasAccessToFormulasV2Subject = new BehaviorSubject(false);
  readonly userHasAccessToFormulasV2$ = this.userHasAccessToFormulasV2Subject.asObservable();

  constructor(private readonly userService: UserService) {
    this.formulaEngine = new HyperFormulaEngine();
    this.listenToUserUpdates();
  }

  private listenToUserUpdates() {
    this.userService.currentUser.pipe(filter((user) => Boolean(user))).subscribe((user) => {
      const userHasAccess = this.userService.isAdmin() && Boolean((user as any).hasAccessToFormulasV2);
      this.userHasAccessToFormulasV2Subject.next(userHasAccess);
    });
  }

  public get userHasAccessToFormulasV2(): boolean {
    return this.userHasAccessToFormulasV2Subject.value;
  }

  createEngine(): HyperFormula {
    this.hf = this.formulaEngine.initializeFormula();
    return this.hf;
  }

  getSheetId(tableReference: string) {
    return this.hf.getSheetId(tableReference);
  }

  getCellPrecedents(cellAddress: SimpleCellAddress): (SimpleCellRange | SimpleCellAddress)[] {
    return this.hf.getCellPrecedents(cellAddress);
  }

  createSheet(tableReference: string) {
    const sheetName = this.hf.addSheet(tableReference);
    const sheetId = this.hf.getSheetId(sheetName);

    return { sheetId, sheetName };
  }

  doesSheetExist(tableReference: string): boolean {
    return tableReference && this.hf.doesSheetExist(tableReference);
  }

  getSheetContent(tableReference: string): CellValue[][] {
    const sheetId = this.hf.getSheetId(tableReference);
    return this.hf.getSheetValues(sheetId);
  }

  calculateSheetContent(table: FieldWeb): string[][] {
    const rawTable = this.calculateRawContent(table);

    const sheetId = this.hf.getSheetId(table.tableReference);
    this.hf.setSheetContent(sheetId, rawTable);

    return rawTable;
  }

  private calculateRawContent(table: FieldWeb): string[][] {
    let rawTable: string[][] = [];

    if (table.kind === FieldKinds.Table) {
      rawTable = [this.getColumnValues(table.columns)];
    }
    if (table.kind === FieldKinds.PrefilledTable) {
      rawTable = table.rows.map((row) => this.getColumnValues(row.columns as TableColumn[]));
    }

    return rawTable;
  }

  getParsedValues(tableReference: string): CellValue[][] {
    const sheetId = this.hf.getSheetId(tableReference);
    return this.hf.getSheetValues(sheetId);
  }

  isBlankCell(cellAddress: string, tableReference: string): boolean {
    const sheetId = this.hf.getSheetId(tableReference);
    return this.hf.calculateFormula(`=ISBLANK(${cellAddress})`, sheetId) === true;
  }

  private getColumnValues(columns: TableColumn[]): string[] {
    return columns.map((field) => this.getMockedFieldValue(field));
  }

  getMockedFieldValue(field: TableColumn): string {
    const uniqueNumber = this.getRandomNumber().toString();
    const mockedDate = '2021-06-21T00:00:00.000Z';
    const mockedTime = '1999-12-31T15:30:35.456Z';
    const mockedText = 'TEXT_VALUE';

    const getLPTCellValue = (): string => {
      const modelsMap = new Map<GlobalItemModels, () => string>([
        [GlobalItemModels.TextSimple, () => mockedText],
        [GlobalItemModels.NumberSimple, () => uniqueNumber],
        [GlobalItemModels.DateSimple, () => mockedDate],
        [GlobalItemModels.DateExpiry, () => mockedDate],
      ]);

      if (!field.model) {
        return mockedText;
      }

      return modelsMap.get(field.model)();
    };

    const fieldOptions = new Map<TableCellKinds, () => string>([
      [TableCellKinds.Formula, () => field?.formula ?? ''],
      [TableCellKinds.Date, () => mockedDate],
      [TableCellKinds.Time, () => mockedTime],
      [TableCellKinds.Number, () => uniqueNumber],
      [TableCellKinds.List, () => uniqueNumber],
      [TableCellKinds.ListProperty, () => getLPTCellValue()],
    ]);

    const isColumnNotSupported = !fieldOptions.has(field.kind);
    if (isColumnNotSupported) {
      return mockedText;
    }

    return fieldOptions.get(field.kind)();
  }

  isTableField(field: FieldWeb) {
    return [FieldKinds.Table, FieldKinds.PrefilledTable].includes(field.kind);
  }

  private getRandomNumber(): number {
    this.numberValidationCounter += 1;
    return this.numberValidationCounter + parseInt(Date.now().toString().slice(10), 10);
  }

  addColumn(model: FieldWeb, columnIndex: number) {
    const sheetId = this.hf.getSheetId(model.tableReference);

    try {
      this.hf.addColumns(sheetId, [columnIndex, 1]);
    } catch {
      this.rebuildHyperFormula(model);
    }
  }

  removeColumn(model: FieldWeb, columnIndex: number) {
    const sheetId = this.hf.getSheetId(model.tableReference);

    try {
      this.hf.removeColumns(sheetId, [columnIndex, 1]);
    } catch {
      this.rebuildHyperFormula(model);
    }
  }

  updateFormulasOnColumnMove(model: FieldWeb, position: Positions.Left | Positions.Right, index: number) {
    const sheetId = this.hf.getSheetId(model.tableReference);
    const targetColumn = position === Positions.Left ? index - 1 : index + 2;

    try {
      this.hf.moveColumns(sheetId, index, 1, targetColumn);
    } catch {
      this.rebuildHyperFormula(model);
    }
  }

  private rebuildHyperFormula(model: FieldWeb) {
    this.hf.rebuildAndRecalculate();
    this.calculateSheetContent(model);
  }

  addRow(model: FieldWeb, rowIndex: number) {
    if (this.tableHasInvalidReferences(model)) {
      this.calculateSheetContent(model);
    } else {
      const sheet = this.hf.getSheetId(model.tableReference);
      this.hf.addRows(sheet, [rowIndex, 1]);
      const newRow = this.translateRow(sheet, rowIndex, model.rows[0].columns.length);
      this.hf.setCellContents({ sheet, col: 0, row: rowIndex }, newRow);
    }
  }

  private translateRow(sheet: number, rowIndex: number, columnsLength: number): RawCellContent[][] {
    const startSourceCell = { sheet, row: rowIndex - 1, col: 0 };
    const endSourceCell = { sheet, row: rowIndex - 1, col: columnsLength - 1 };
    const startTargetCell = { sheet, row: rowIndex, col: 0 };
    const endTargetCell = { sheet, row: rowIndex, col: columnsLength - 1 };

    return this.hf.getFillRangeData(
      { start: startSourceCell, end: endSourceCell },
      { start: startTargetCell, end: endTargetCell },
    );
  }

  private tableHasInvalidReferences(model: FieldWeb): boolean {
    return model.rows.some(({ columns }) =>
      columns.some((field) => field?.formulaError === FormulasErrors.get(FormulaErrorTypes.REF).message),
    );
  }

  removeRow(model: FieldWeb, rowIndex: number) {
    const sheetId = this.hf.getSheetId(model.tableReference);
    this.hf.removeRows(sheetId, [rowIndex, 1]);
  }

  updateFormulasOnRowMove(model: FieldWeb, position: Positions.Up | Positions.Down, index: number) {
    const sheetId = this.hf.getSheetId(model.tableReference);
    const targetRow = position === Positions.Up ? index - 1 : index + 2;

    this.hf.moveRows(sheetId, index, 1, targetRow);
  }

  updateFormulas(model: FieldWeb): void {
    const sheetId = this.hf.getSheetId(model.tableReference);
    if (sheetId !== undefined) {
      const sheetContent = this.hf.getSheetSerialized(sheetId);

      const updateFormulaCells =
        model.kind === FieldKinds.Table ? this.updateDefaultTableFormulas : this.updatePrefilledTableFormulas;

      updateFormulaCells.bind(this)(model, sheetContent);
    }
  }

  private updatePrefilledTableFormulas(model: FieldWeb, sheetContent: RawCellContent[][]): void {
    model.rows.forEach((row, rowIndex) => {
      row.columns.forEach((cell, columnIndex) => {
        const cellContent = sheetContent?.[rowIndex]?.[columnIndex];
        this.setCellContent(cell, cellContent);
      });
    });
  }

  private updateDefaultTableFormulas(model: FieldWeb, sheetContent: RawCellContent[][]): void {
    model.columns.forEach((cell, columnIndex) => {
      const cellContent = sheetContent?.[0]?.[columnIndex];
      this.setCellContent(cell, cellContent);
    });
  }

  setCellContent(cell: TableCellData, cellContent: RawCellContent) {
    const cellFormula = cell?.formula?.toUpperCase();
    if (cellFormula?.includes('IFERROR(')) {
      return;
    }

    if (cell.kind === TableCellKinds.Formula && cellContent) {
      cell.formula = String(cellContent);
    }
  }

  setInitialData(tables: FieldWeb[]) {
    this.hf.batch(() => tables.forEach(this.calculateSheetContent.bind(this)));
  }

  destroyEngine() {
    this.numberValidationCounter = 1;
    this.formulaEngine.destroyFormula(this.hf);
  }

  renameSheet(oldName: string, newName: string) {
    const sheetId = this.hf.getSheetId(oldName);
    this.hf.renameSheet(sheetId, newName);
  }

  removeTablesFromFormulaEngine(fieldsToRemove: FieldWeb[]) {
    fieldsToRemove.filter(this.isTableField).forEach((field) => {
      this.removeSheet(field.tableReference);
    });
  }

  removeSheet(sheetName: string) {
    const sheetId = this.hf.getSheetId(sheetName);
    this.hf.removeSheet(sheetId);
  }

  checkV2Tables(fields: FieldWeb[]): FieldWeb[] {
    const tables = fields.filter(this.isTableField);
    this.registerNewTables(tables);
    this.updateTableReferences(tables);
    tables.forEach((table) => this.updateFormulas(table));
    return tables;
  }

  private registerNewTables(tables: FieldWeb[]) {
    tables.forEach((table, index) => {
      if (!this.doesSheetExist(table.tableReference)) {
        const temporaryTableReference = `TableNew${index + 1}`;
        table.tableReference = temporaryTableReference;
        this.createSheet(temporaryTableReference);
      }
    });
  }

  private updateTableReferences(tables: FieldWeb[]) {
    const changedTableReferenceMap = this.updateNamesAndGetMap(tables);

    if (changedTableReferenceMap.size) {
      this.renameMultipleSheets(changedTableReferenceMap);
    }
  }

  private updateNamesAndGetMap(tables: FieldWeb[]) {
    const changedTableReferences = new Map<string, string>();

    tables.forEach((table, index) => {
      const oldName = table.tableReference;
      const newName = `Table${index + 1}`;
      table.tableReference = newName;

      if (oldName && oldName !== newName) {
        changedTableReferences.set(oldName, newName);
      }
    });

    return changedTableReferences;
  }

  private renameMultipleSheets(changedTableReferences: Map<string, string>) {
    const temporaryNameMap = new Map<string, string>();

    [...changedTableReferences.entries()].forEach(([oldName, newName], index) => {
      const temporaryName = `TableTemp${index + 1}`;
      this.renameSheet(oldName, temporaryName);
      temporaryNameMap.set(temporaryName, newName);
    });

    [...temporaryNameMap.entries()].forEach(([temporaryName, newName]) => {
      this.renameSheet(temporaryName, newName);
    });
  }
}
