import { GlobalItemModels } from '@site-mate/dashpivot-shared-library';

export const ListCellTypes: Record<Exclude<GlobalItemModels, GlobalItemModels.Container>, string> = {
  [GlobalItemModels.AttachmentSimple]: 'Attachment',
  [GlobalItemModels.TextSimple]: 'Text',
  [GlobalItemModels.NumberSimple]: 'Number',
  [GlobalItemModels.DateSimple]: 'Date',
  [GlobalItemModels.DateExpiry]: 'Date Expiry',
  [GlobalItemModels.DatePlain]: 'Date Plain',
  [GlobalItemModels.DateRangePlain]: 'Date Range Plain',
  [GlobalItemModels.TimePlain]: 'Time Plain',
  [GlobalItemModels.Location]: 'Location',
};
