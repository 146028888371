import { Component, OnInit } from '@angular/core';

import { TableDisplayMode } from '@site-mate/dashpivot-shared-library';

import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TableDisplayModes } from 'app/form-fields/table/template/table-display-modes';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'cc-signon-table-template',
  templateUrl: './signon-table-template.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', './signon-table-template.component.scss'],
})
export class SignonTableTemplateComponent extends TemplateFieldBaseComponent implements OnInit {
  tableDisplayModes = TableDisplayModes.list();
  signOnPosterURL =
    'https://s3.ap-southeast-2.amazonaws.com/static.constructioncloud.com.au/posters/Contactless+Sign+Poster.pdf';

  horizontalTablesSupport = {
    enabled: environment.featureToggles.tableInversion,
  };

  tableBodyValues = ['Name', 'Company', 'Signature', 'Date', 'Time', 'Map', 'Latitude', 'Longitude'];

  constructor(protected readonly i18nService: TmpI18NService) {
    super();
  }

  ngOnInit() {
    this.model.displayRowsMode =
      this.model.displayRowsMode || (TableDisplayModes.defaultMode().key as TableDisplayMode);

    super.ngOnInit();
  }
}
