import { Directive, ViewContainerRef, Input, ElementRef } from '@angular/core';

import { FormFieldColumn } from './form-field-column';

@Directive({
  selector: '[ccFormFieldColumnHost]',
})
export class FormFieldColumnHostDirective {
  @Input()
  column: FormFieldColumn;

  @Input()
  rowIndex = 0;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public element: ElementRef,
  ) {}
}
