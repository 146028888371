import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  templateUrl: './date-column-template-preview.component.html',
  styleUrls: ['./date-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
