<div class="form-group row app-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline">
    <div
      ngbDropdown
      class="sm-relative sm-mr-2 sm-inline-block"
      #dropdown="ngbDropdown"
      (mouseenter)="dropdown.open()"
      (mouseleave)="dropdown.close()"
    >
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-secondary app-control-dropdown"
          (click)="$event.preventDefault(); dropdown.open()"
          ngbDropdownAnchor
        >
          <i class="cc-icon cc-icon-yes-no sm-align-middle"></i>
        </button>
      </div>
      <div ngbDropdownMenu class="dropdown-menu px-3" (click)="$event.stopPropagation(); dropdown.close()">
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Checkbox"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'checkboxType'"
            value="yesNo"
            data-cy="yesNo"
            [(ngModel)]="buttonType"
            (change)="updateModel()"
            [id]="'yes-no-radio-0-' + identifier"
          />
          <label i18n [for]="'yes-no-radio-0-' + identifier" class="default-label form-check-label"
            >Yes/No</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Checkbox"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'checkboxType'"
            value="yesNoNA"
            data-cy="yesNoNA"
            [(ngModel)]="buttonType"
            (change)="updateModel()"
            [id]="'yes-no-radio-1-' + identifier"
          />
          <label i18n [for]="'yes-no-radio-1-' + identifier" class="default-label form-check-label"
            >Yes/No/NA</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Checkbox"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'checkboxType'"
            value="yesNoText"
            data-cy="yesNoText"
            [(ngModel)]="buttonType"
            (change)="updateModel()"
            [id]="'yes-no-radio-2-' + identifier"
          />
          <label i18n [for]="'yes-no-radio-2-' + identifier" class="default-label form-check-label"
            >Yes/No with text</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Checkbox"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + 'checkboxType'"
            value="yesNoNAText"
            data-cy="yesNoNAText"
            [(ngModel)]="buttonType"
            (change)="updateModel()"
            [id]="'yes-no-radio-3-' + identifier"
          />
          <label i18n [for]="'yes-no-radio-3-' + identifier" class="default-label form-check-label"
            >Yes/No/NA with text</label
          >
        </div>
      </div>
    </div>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.content" />
  </div>
  <div class="app-control-control sm-flex sm-overflow-x-auto">
    <div class="flex-grow-1 sm-w-full">
      <div class="form-group">
        <textarea
          rows="5"
          class="form-control app-text-area"
          placeholder="Add your question here..."
          i18n-placeholder="Add your question here..."
          [(ngModel)]="model.description"
        >
        </textarea>
      </div>

      <div *ngIf="model.hasTextInput" class="sm-grid sm-grid-cols-12 sm-gap-4">
        <div class="sm-col-span-4">
          <input
            type="text"
            class="form-control sm-h-legacy-7.2 sm-w-full"
            placeholder="Add label text"
            i18n-placeholder="Add label text"
            name="label"
            [(ngModel)]="model.textInputLabel"
          />
        </div>
        <div class="sm-col-span-8">
          <input
            type="text"
            class="form-control sm-h-legacy-7.2 sm-w-full"
            readonly
            placeholder="Text box"
            i18n-placeholder="Text box"
          />
        </div>
      </div>
    </div>
    <div class="sm-ml-4">
      <div data-toggle="buttons" class="btn-group btn-group-toggle" role="group" *ngIf="!model.hasNaButton">
        <label class="default-label disabled btn btn-secondary btn-sm mb-0 radio-button">
          <input type="radio" /><span i18n>Yes</span>
        </label>
        <label class="default-label disabled btn btn-secondary btn-sm mb-0 radio-button">
          <input type="radio" /><span i18n>No</span>
        </label>
      </div>
      <div data-toggle="buttons" class="btn-group btn-group-toggle" role="group" *ngIf="model.hasNaButton">
        <label class="default-label disabled btn btn-secondary btn-sm mb-0 radio-button">
          <input type="radio" /><span i18n>Yes</span>
        </label>
        <label class="default-label disabled btn btn-secondary btn-sm mb-0 radio-button">
          <input type="radio" /><span i18n>No</span>
        </label>
        <label class="default-label disabled btn btn-secondary btn-sm mb-0 radio-button">
          <input type="radio" /><span i18n>NA</span>
        </label>
      </div>
    </div>
  </div>

  <label
    class="default-label logic-control no-drag btn"
    (click)="onAddLogic()"
    *ngIf="!model.dependsOn?.fieldId"
  >
    <i class="cc-icon cc-icon-logic mr-1"></i>
    <span class="control-label" i18n>Add logic</span>
  </label>
</div>

<cc-logic-rule-list
  [field]="model"
  [fields]="controls"
  [template]="app"
  [metadataList]="metadataList"
  [companyId]="companyId"
  [parentId]="parentId"
  [fieldUpdated]="updated"
  [templateOrientation]="templateOrientation"
  [hasReachedPlanLimit]="hasReachedPlanLimit"
  [workspaceItemsLimit]="workspaceItemsLimit"
></cc-logic-rule-list>
