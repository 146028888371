<div class="person-container">
  <ng-container *ngIf="userIds.length === 0; else loadPerson">
    <cc-user-avatar class="sm-mr-2"></cc-user-avatar> No user selected
  </ng-container>

  <ng-template #loadPerson>
    <ng-container *ngIf="person$ | async as person; else loading">
      <cc-user-avatar [user]="person.userDoesNotExist ? null : person"></cc-user-avatar>
      <span class="sm-ml-2" [class.sm-font-bold]="!person.userDoesNotExist">
        {{ person.fullName }}
      </span>
    </ng-container>
  </ng-template>

  <ng-template #loading>
    <i class="cc-icon cc-icon-spinner" aria-label="Loading spinner"></i>
  </ng-template>
</div>
