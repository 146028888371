import { Component, Input } from '@angular/core';

import { IField } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { FieldWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';

import { ApprovalSignatureService } from '../../approval-signature.service';

@Component({
  selector: 'cc-signature-logic-rule-list',
  templateUrl: './signature-logic-rule-list.component.html',
  styleUrls: ['./signature-logic-rule-list.component.scss'],
})
export class SignatureLogicRuleListComponent {
  @Input() field: FieldWeb;
  @Input() fields: IField[];
  @Input() canUpgradePlan: boolean;
  @Input() companyPlanType: CompanyPlanTypes;

  constructor(
    private readonly approvalSignatureService: ApprovalSignatureService,
    private readonly confirmService: ConfirmService,
  ) {}

  validateLogicRules() {
    this.field._invalid = this.approvalSignatureService.validateRuleFields(this.field.signatureRules);
  }

  async onDeleteRule(id: string) {
    try {
      await this.confirmService.confirmDelete('deleteRule');
      this.field.signatureRules = this.field.signatureRules.filter((rule) => rule._id !== id);

      this.validateLogicRules();
    } catch {
      // no-op
    }
  }
}
