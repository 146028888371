import { Directive, ViewContainerRef, ElementRef, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Directive({
  selector: '[ccFormFieldVersionDiffHost]',
})
export class FormFieldVersionDiffHostDirective {
  @Input() field: FieldWeb;

  constructor(
    public readonly viewContainerRef: ViewContainerRef,
    public readonly element: ElementRef,
  ) {}
}
