<div class="app-table-column-list-item sm-flex-wrap sm-gap-2">
  <div *ngIf="!isEdit" class="sm-flex sm-flex-wrap sm-gap-1">
    <div>{{ item.value }}</div>
    <a (click)="edit()" class="text-link">
      <small i18n>Edit</small>
    </a>
  </div>
  <div *ngIf="isEdit" class="w-100">
    <input
      type="text"
      class="form-control form-control-sm"
      name="category-item"
      [(ngModel)]="editedItemValue"
      ccFocus
      (keyup.enter)="saveEdit()"
    />
    <a (click)="saveEdit()" class="text-link">
      <small i18n>Done</small>
    </a>
    <a (click)="cancelEdit()" class="text-link">
      <small i18n>Cancel</small>
    </a>
  </div>
  <div class="sm-flex sm-gap-1" *ngIf="!isEdit">
    <cc-style-color-picker
      [(style)]="item.style"
      (styleChange)="styleUpdated($event)"
      [defaultStyle]="styleVariants.None"
    ></cc-style-color-picker>
    <button (click)="remove.emit(item.id)" type="button" title="Remove" i18n-title="Remove">
      <i class="cc-icon cc-icon-close sm-align-middle sm-text-default-red hover:sm-text-secondary-red"> </i>
    </button>
  </div>
</div>
