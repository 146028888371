import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { QuillToolbarConfig } from 'ngx-quill';
import { Subject, takeUntil } from 'rxjs';

import {
  EventTypes,
  FieldKinds,
  StyleVariants,
  TableCellKinds,
  TableColumn,
  TableColumnWidths,
  Template,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { TemplateListPropertyService } from 'app/apps/template-list-property.service';
import { TemplateListService } from 'app/apps/template-list.service';
import { TemplateTableFormulaCellComponent } from 'app/form-fields/table/template/table-cell-items/formula/template-table-formula-cell.component';
import { TemplateTableListCellComponent } from 'app/form-fields/table/template/table-cell-items/list/template-table-list-cell.component';
import { FieldWeb } from 'app/shared/model/item.model';
import { EventsService } from 'app/shared/service/events/events.service';
import { environment } from 'environments/environment';

import { TemplateTableListPropertyCellComponent } from './table-cell-items/list-property/template-table-list-property-cell.component';
import { ITemplateTableCell } from './table-cell-items/template-table-cell.interface';

@Component({
  selector: 'cc-app-table-column',
  templateUrl: 'app-table-column.component.html',
  styleUrls: [
    '../../common/template/form-control.component.scss',
    'app-table.component.scss',
    'app-table-column.component.scss',
  ],
  // TODO: this prevents changes in formulas being seen
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnTemplateComponent implements OnDestroy {
  private readonly smallCellDropdownPanelClassName = 'table-cell-dropdown-small';
  readonly enableLocationTableCellSupport = environment.featureToggles.locationTableCell;
  private readonly templateListPropertyService = inject(TemplateListPropertyService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly unsubscribe = new Subject<void>();
  private readonly eventsService = inject(EventsService);
  private readonly listPropertyIndex = 4;
  private readonly templateListService = inject(TemplateListService);
  private readonly disallowLocationPlanTypes = [CompanyPlanTypes.FreeTrial];
  readonly TableCellKinds = TableCellKinds;
  private component: ComponentRef<ITemplateTableCell>;
  @Input() column: TableColumn;
  @Input() index: number;
  @Input() item: FieldWeb;
  @Input() companyId: string;
  @Input() template: Template;
  @Input() companyPlanType: CompanyPlanTypes;
  @Input() width: TableColumnWidths;
  @Output() updated: EventEmitter<unknown> = new EventEmitter();
  @Output() formulaChange: EventEmitter<string> = new EventEmitter();

  readonly id = Date.now();
  readonly editorToolbarOption: QuillToolbarConfig = [
    ['bold', 'underline'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ color: [] }],
    [{ list: 'bullet' }],
    ['image'],
  ];
  editingPrefillText: boolean;

  columnTypes: { kind: TableCellKinds; label: string; disabled?: boolean; popoverMessage?: string }[] = [
    { kind: TableCellKinds.Attachment, label: 'Attachment' },
    { kind: TableCellKinds.Date, label: 'Date' },
    { kind: TableCellKinds.Formula, label: 'Formula' },
    { kind: TableCellKinds.List, label: 'List' },
    { kind: TableCellKinds.ManualSignature, label: 'Sign manually' },
    { kind: TableCellKinds.Number, label: 'Number' },
    { kind: TableCellKinds.Photo, label: 'Photo/Video reference' },
    { kind: TableCellKinds.PrefilledText, label: 'Prefilled text' },
    { kind: TableCellKinds.Signature, label: 'Signature' },
    { kind: TableCellKinds.Text, label: 'Text' },
    { kind: TableCellKinds.Time, label: 'Time' },
  ];

  @ViewChild('tableCell', { read: ViewContainerRef }) dynamicCell: ViewContainerRef;

  ngOnInit() {
    if (this.item.kind === FieldKinds.Table) {
      this.insertListPropertyColumnType();
      this.trackListPropertyTableCells();
    }
    if (!this.disallowLocationPlanTypes.includes(this.companyPlanType)) {
      this.insertLocationColumnTypeBeforeManualSignature();
    }
  }

  private insertListPropertyColumnType() {
    const column = { kind: TableCellKinds.ListProperty, label: 'List Property' };
    this.columnTypes.splice(this.listPropertyIndex, 0, column);
  }

  private trackListPropertyTableCells() {
    this.templateListPropertyService.listPropertyTableCellCounter$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.columnTypes[this.listPropertyIndex].disabled =
          this.templateListPropertyService.isLPTCCountExceeded;

        this.columnTypes = [...this.columnTypes];
      });
  }

  private insertLocationColumnTypeBeforeManualSignature() {
    const signManualIndex = this.columnTypes.findIndex(
      (column) => column.kind === TableCellKinds.ManualSignature,
    );

    const locationColumn = {
      kind: TableCellKinds.Location,
      label: 'Location',
      disabled: !environment.featureToggles.locationTableCell,
    };

    this.columnTypes.splice(signManualIndex, 0, locationColumn);
  }

  ngOnChanges(changes: SimpleChanges) {
    const tableCellKindChangeHandlerMap = new Map<TableCellKinds, (changes: SimpleChanges) => void>([
      [TableCellKinds.List, this.handleListColumnChanges],
      [TableCellKinds.ListProperty, this.handleListPropertyColumnChanges],
    ]);

    if (this.component && tableCellKindChangeHandlerMap.has(this.column.kind)) {
      tableCellKindChangeHandlerMap.get(this.column.kind).bind(this)(changes);
    }
  }

  private handleListPropertyColumnChanges = () => {
    const component = this.component.instance as TemplateTableListPropertyCellComponent;

    if (this.column._invalid) {
      component.checkForErrors();
      delete this.column._invalid;
    }
  };

  private handleListColumnChanges = (changes: SimpleChanges) => {
    if (changes.index) {
      this.component.setInput('index', changes.index.currentValue);
    }
  };

  ngAfterViewInit() {
    this.renderCellComponent();
  }

  onColumnKindChange(newKind: TableCellKinds) {
    const oldKind = this.column.kind;
    this.column.kind = newKind;
    this.emptyColumnData();
    this.updated.emit(this.column);

    if (oldKind === TableCellKinds.List) {
      this.templateListService.onListReferenceChange(this.item._id, this.index, null);
    }

    if (newKind === TableCellKinds.ListProperty) {
      this.eventsService.trackEvent(EventTypes.ListPropertyAdded, { Context: 'Table Cell' });
      this.templateListPropertyService.incrementListPropertyTableCellCounter();
      this.columnTypes = [...this.columnTypes];
    }

    if (oldKind === TableCellKinds.ListProperty && newKind !== TableCellKinds.ListProperty) {
      this.templateListPropertyService.decrementListPropertyTableCellCounter();
      this.columnTypes = [...this.columnTypes];
    }

    if (newKind === TableCellKinds.Location) {
      this.eventsService.trackEvent(EventTypes.LocationAdded, { Context: 'Table Cell' });
    }

    this.cdr.detectChanges();
    this.renderCellComponent();
  }

  private emptyColumnData() {
    this.column.reference = '';
    this.column.content = '';
    this.column.description = '';

    if (this.column.kind === TableCellKinds.Formula) {
      this.column.formula = '';
      this.column.formulaError = '';
    }

    if (this.column.kind === TableCellKinds.List) {
      delete this.column.manualListItems;
      delete this.column.listSource;
      this.column.defaultColor = StyleVariants.Sky;
    }

    if (this.column.kind === TableCellKinds.ListProperty) {
      delete this.column.metadata;
      delete this.column.data;
      delete this.column.model;
    }

    if (this.column.kind === TableCellKinds.Location) {
      this.column.item = null;
    }
  }

  private get CellComponent(): Type<ITemplateTableCell> {
    const cellComponentCreators = new Map<TableCellKinds, Type<ITemplateTableCell>>([
      [TableCellKinds.List, TemplateTableListCellComponent],
      [TableCellKinds.Formula, TemplateTableFormulaCellComponent],
      [TableCellKinds.ListProperty, TemplateTableListPropertyCellComponent],
    ]);

    return cellComponentCreators.get(this.column.kind);
  }

  private renderCellComponent(): void {
    if (!this.dynamicCell) {
      return;
    }
    this.component = this.dynamicCell.createComponent(this.CellComponent);
    this.component.instance.cellData = this.column;

    if (this.component.instance instanceof TemplateTableFormulaCellComponent) {
      this.component.instance.tableId = this.item._id;
      this.component.instance.change = this.formulaChange;
    }

    if (this.component.instance instanceof TemplateTableListCellComponent) {
      this.component.instance.table = this.item;
      this.component.instance.index = this.index;
      this.component.instance.isOrganisationTemplate = this.template.isOrganisationTemplate;
    }

    if (this.component.instance instanceof TemplateTableListPropertyCellComponent) {
      this.component.instance.tableColumns = this.item.columns;
      this.component.instance.tableId = this.item._id;
      this.component.instance.onChange
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.formulaChange.emit());
    }

    this.cdr.detectChanges();
  }

  setEditingPrefillText(value: boolean) {
    this.editingPrefillText = value;
  }

  handleTypeDropdownOpen() {
    this.injectDropdownPanelDynamicStyle();
  }

  private injectDropdownPanelDynamicStyle() {
    /* We need this to dynamically style the dropdown panel
     * This is a workaround to an open issue with ng-select
     * https://github.com/ng-select/ng-select/issues/1756
     */
    if (this.width === TableColumnWidths.Small) {
      setTimeout(() => {
        const panel = document.querySelector('.ng-dropdown-panel');
        panel.classList.add(this.smallCellDropdownPanelClassName);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
