<button
  placement="bottom bottom-start"
  class="sm-grid sm-h-4 sm-w-4 sm-place-items-center sm-rounded hover:sm-bg-grey-3"
  [class.sm-bg-default-light-blue]="active"
  [class.hover:sm-bg-secondary-light-blue]="active || filter.isOpen()"
  [class.sm-text-default-blue]="active || filter.isOpen()"
  [class.sm-bg-secondary-light-blue]="filter.isOpen()"
  type="button"
  triggers="manual"
  container="body"
  [ngbPopover]="filterTemplate"
  #filterPopover="ngbPopover"
  (document:mouseup)="filter.close()"
  data-test="toggle-filter-button"
  (click)="filter.toggle()"
>
  <i class="cc-icon cc-icon-filter" aria-hidden="true"></i>
</button>
