import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies -- command-line scripting
import { upperFirst } from 'lodash-es';

import { EventTypes, ListItem, StyleVariants } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';

@Component({
  selector: 'cc-app-category-item',
  templateUrl: 'app-category-item.component.html',
  styleUrls: [
    'app-category-item.component.scss',
    '../../common/template/form-control.component.scss',
    './category-template.component.scss',
  ],
})
export class AppCategoryItemComponent implements OnChanges {
  isEdit: boolean;
  editedRowName: string;
  @Input() showControl: boolean;
  @Input() displayColorPicker: boolean;
  @Input() row: ListItem;
  @Input() editingRow: { id: string; value: string };
  @Output() remove = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  constructor(private readonly eventsService: EventsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.editingItem && changes.editingItem.currentValue) {
      const { id } = changes.editingItem.currentValue;
      if (this.row.id && id !== this.row.id && this.isEdit) {
        this.cancelEdit();
      }
    }
  }

  edit() {
    this.onEdit.emit(this.row);
    this.editedRowName = this.row.value;
    this.isEdit = true;
  }

  cancelEdit() {
    this.editedRowName = '';
    this.isEdit = false;
  }

  saveEdit() {
    if (!this.editedRowName) {
      return;
    }
    this.row.value = this.editedRowName;
    this.editedRowName = '';
    this.isEdit = false;
  }

  styleUpdated(style: StyleVariants) {
    this.eventsService.trackEvent(EventTypes.ListItemColourEdited, {
      Context: upperFirst(style),
    });
  }
}
