import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { PhotoThumbnailSizes, EventTypes, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { AppType } from 'app/apps/app-control-type.model';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { IFieldSettingsAction, FieldSettingsNames } from 'app/form-fields/common/types/field-settings.type';
import { EventsService } from 'app/shared/service/events/events.service';

import { PhotoThumbnailDropdownOptions } from './photo-thumbnail-dropdown/photo-thumbnail-dropdown-options.model';

@Component({
  selector: 'cc-app-photo',
  templateUrl: 'photo-template.component.html',
  styleUrls: ['../../common/template/form-control.component.scss'],
})
export class PhotoTemplateComponent extends TemplateFieldBaseComponent implements OnInit {
  appType = AppType;
  readonly thumbnailSizes = PhotoThumbnailSizes;
  readonly photoThumbnailOptions = PhotoThumbnailDropdownOptions;
  thumbnailSrc = '';
  readonly photoSizesWithTagsAndDescription = [PhotoThumbnailSizes.Large, PhotoThumbnailSizes.ExtraLarge];
  readonly photoSizesWithLandscapeSize = [PhotoThumbnailSizes.ExtraLarge];

  @Output() togglePhotoDescription = new EventEmitter<void>();
  @Output() togglePhotoTags = new EventEmitter<void>();

  constructor(private readonly eventsService: EventsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.addToFieldSettingsActions();
    this.setThumbnailSrc();
    this.togglePhotoDescription.subscribe(() => {
      this.eventsService.trackEvent(EventTypes.PhotoFieldSettingsToggled, {
        Context: 'Photo Description',
      });
    });

    this.togglePhotoTags.subscribe(() => {
      this.eventsService.trackEvent(EventTypes.PhotoFieldSettingsToggled, { Context: 'Photo Tags' });
    });
  }

  setThumbnailSrc() {
    this.thumbnailSrc = this.getThumbnailSrc();
  }

  setPhotoThumbnailChange() {
    this.setThumbnailSrc();
    this.updateFieldSettingsActions();
    this.resetPhotoDescriptionVisibility();
    this.resetPhotoTagsVisibility();
  }

  getThumbnailSrc() {
    const thumbnailSize = this.model.photoThumbnailSize || PhotoThumbnailSizes.Small;
    const shouldAddLandscapeOrientation =
      this.photoSizesWithLandscapeSize.includes(thumbnailSize) &&
      this.templateOrientation === TemplateOrientations.Landscape;
    const orientation = shouldAddLandscapeOrientation ? '-landscape' : '';
    return `assets/images/template-editor/photo-thumbnail-sizes/thumbnail-${thumbnailSize}${orientation}.svg`;
  }

  addToFieldSettingsActions() {
    const makePhotoDescriptionVisible: IFieldSettingsAction = {
      isVisible: this.photoSizesWithTagsAndDescription.includes(this.model.photoThumbnailSize),
      onUpdate: this.togglePhotoDescription,
      icon: 'text',
      label: 'Include Photo Description',
      fieldName: FieldSettingsNames.IsPhotoDescriptionVisible,
    };

    const makePhotoTagsVisible: IFieldSettingsAction = {
      isVisible: this.photoSizesWithTagsAndDescription.includes(this.model.photoThumbnailSize),
      onUpdate: this.togglePhotoTags,
      icon: 'tag',
      label: 'Include Photo Tags',
      fieldName: FieldSettingsNames.IsPhotoTagsVisible,
    };

    this.fieldSettingsActions.push(makePhotoDescriptionVisible, makePhotoTagsVisible);
  }

  updateFieldSettingsActions() {
    this.fieldSettingsActions.forEach((field) => {
      if (
        field.fieldName === FieldSettingsNames.IsPhotoDescriptionVisible ||
        field.fieldName === FieldSettingsNames.IsPhotoTagsVisible
      ) {
        field.isVisible = this.photoSizesWithTagsAndDescription.includes(this.model.photoThumbnailSize);
      }
    });
  }

  resetPhotoDescriptionVisibility() {
    if (
      !this.model.isPhotoDescriptionVisible ||
      !this.photoSizesWithTagsAndDescription.includes(this.model.photoThumbnailSize)
    ) {
      this.model.isPhotoDescriptionVisible = false;
    }
  }

  resetPhotoTagsVisibility() {
    if (
      !this.model.isPhotoTagsVisible ||
      !this.photoSizesWithTagsAndDescription.includes(this.model.photoThumbnailSize)
    ) {
      this.model.isPhotoTagsVisible = false;
    }
  }
}
