import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  templateUrl: './generic-column-template-preview.component.html',
  styleUrls: ['./generic-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
