import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { CategorySourceTypes, TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  selector: 'cc-list-column-template-preview',
  templateUrl: './list-column-template-preview.component.html',
  styleUrls: ['./list-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListColumnTemplatePreviewComponent {
  @Input() column: TableColumn;

  readonly CategorySourceTypes = CategorySourceTypes;
}
