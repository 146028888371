import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

import { ListCellTypes } from 'app/form-fields/table/template/table-cell-items/list-property/list-cell-types';

@Component({
  selector: 'cc-list-property-column-template-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-property-column-template-preview.component.html',
  styleUrls: ['./list-property-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPropertyColumnTemplatePreviewComponent implements OnInit {
  @Input() column: TableColumn;
  listPropertyType: string;

  ngOnInit() {
    this.listPropertyType = ListCellTypes[this.column.model];
  }
}
