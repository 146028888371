<div class="d-inline-block">
  <span data-test="form-date-diff" *ngIf="field.isDateAndTime">{{
    field.startDate | amDateFormat: 'LT'
  }}</span>
  <span>{{ field.startDate | amDateFormat: 'll' }}</span>
</div>

<div *ngIf="!field.isSingleDate" class="d-inline-block">
  <span> - </span>
  <span *ngIf="field.isDateAndTime">{{ field.endDate | amDateFormat: 'LT' }}</span>
  <span>{{ field.endDate | amDateFormat: 'll' }}</span>
</div>
