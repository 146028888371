<div class="form-group row app-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline">
    <div class="d-inline-block mr-2">
      <button type="button" class="btn btn-secondary app-control-dropdown">
        <i class="cc-icon cc-icon-sketch"></i>
      </button>
    </div>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>
  <div class="app-control-control">
    <div
      class="sketch-canvas"
      [class.custom-background]="model.background"
      [style.background-image]="getBackgroundUrl()"
    >
      <div class="mb-3" i18n>You can overlay text boxes and draw shapes here after you save the template</div>
    </div>
  </div>
</div>
