<button
  type="button"
  gw-button
  class="counter"
  [ngClass]="counterClass"
  [gwTooltip]="tooltipContent"
  gwTooltipIndicatorPosition="left"
  [gwTooltipHideDelay]="800"
  data-test="counter-button"
>
  <p class="counter-block sm-inline-block sm-font-bold" data-test="counter">
    {{ counter }}
  </p>
</button>

<ng-template #tooltipContent>
  <div i18n class="thumbnail placeholder tooltip-container sm-space-y-1 sm-text-sm">
    <p class="sm-font-medium">Approval Signature Logic Limits</p>
    <span class="sm-flex sm-flex-grow">
      {{ rule }} Conditional Logic:&nbsp;
      <p class="sm-font-medium" [ngClass]="counterClass">{{ counter }}</p>
    </span>
    <p *ngIf="counterClass === 'full'">
      {{ limitReachedMessage }}
      <span *ngIf="!isUpgradeAvailable; else maxRulesPlan">{{ limitReachedMessageSecondPart }}</span>
    </p>
    <a
      class="button-learn-more"
      href="https://intercom.help/dashpivot/en/articles/8329198-how-to-set-up-approval-signature-logic"
      target="_blank"
      rel="noopener"
    >
      <i class="cc-icon cc-icon-question"></i>
      <p class="sm-font-medium">Learn more</p>
    </a>

    <ng-template #maxRulesPlan>
      <button
        type="button"
        class="sm-text-default-blue sm-underline"
        (click)="openUpgradeModal()"
        data-test="upgrade-button"
      >
        Upgrade your plan
      </button>
      to unlock more.
    </ng-template>
  </div>
</ng-template>
