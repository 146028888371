import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ObjectId } from 'bson';
import { BehaviorSubject } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

import {
  CategorySourceTypes,
  CategoryTypes,
  EventTypes,
  ILegacyList,
  ItemStates,
  ListService as SharedListService,
  StyleVariants,
} from '@site-mate/dashpivot-shared-library';

import { ListIdentifierOnly, TemplateListService } from 'app/apps/template-list.service';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { LogicRuleWeb } from 'app/shared/model/item.model';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { FetchedQuery, Query } from 'app/shared/model/query/query.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-app-category',
  templateUrl: './category-template.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', './category-template.component.scss'],
})
export class CategoryTemplateComponent extends TemplateFieldBaseComponent implements OnInit {
  private readonly templateListsService = inject(TemplateListService);
  private readonly destroyRef = inject(DestroyRef);
  readonly CategorySourceTypes = CategorySourceTypes;
  readonly options = [
    { value: CategoryTypes.Inline, label: 'Multiple choice' },
    { value: CategoryTypes.Dropdown, label: 'Dropdown' },
  ];

  allItems = { enabled: false, resumedAmount: 10 };
  editingRow: { id: string; value: string };
  previousValue: CategorySourceTypes;

  protected readonly listIdentifiers$ = this.templateListsService.listIdentifiers$;
  protected readonly fullList = new BehaviorSubject<Query<ILegacyList>>({ state: QueryStates.Loading });

  protected availableIdentifierForInvalidList: { name: string; _id: string };

  protected readonly queryStates = QueryStates;

  private readonly filterListRows = new SharedListService().filterDeployedListItems;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly eventsService: EventsService,
    private readonly logicRuleService: LogicRuleService,
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
    private readonly toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.previousValue = this.model.categorySource;
    this.getInitialItems();
  }

  private getInitialItems() {
    if (this.model.categorySource === CategorySourceTypes.List) {
      this.getFullList();
    }
  }

  private getFullList() {
    this.templateListsService
      .getFullLegacyList(this.model.reference)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        takeWhile(
          (listQuery) =>
            listQuery.state !== QueryStates.Fetched || listQuery.data._id === this.model.reference,
        ),
      )
      .subscribe((listQuery) => {
        if (listQuery.state === QueryStates.Loading || listQuery.state === QueryStates.Empty) {
          this.fullList.next(listQuery);
          return;
        }

        if (listQuery.state === QueryStates.Error) {
          this.availableIdentifierForInvalidList = { name: '', _id: '' };
          this.handleUnavailableList();
          return;
        }

        if (listQuery.state === QueryStates.Fetched) {
          this.handleFetchedList(listQuery);
        }
      });
  }

  private handleUnavailableList() {
    this.fullList.next({
      state: QueryStates.Error,
      error: new Error('The linked list does not exist within this folder. Please remove this list.'),
    });
  }

  private handleFetchedList(listQuery: FetchedQuery<ILegacyList>) {
    if (listQuery.data.state === ItemStates.Deleted) {
      this.handleDeletedList(listQuery);
      return;
    }

    this.listIdentifiers$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((listIdentifiersQuery) => listIdentifiersQuery.state === QueryStates.Fetched),
      )
      .subscribe((listIdentifiers: FetchedQuery<ListIdentifierOnly[]>) => {
        if (!listIdentifiers.data.find(({ _id }) => _id === this.model.reference)) {
          this.availableIdentifierForInvalidList = { name: listQuery.data.name, _id: listQuery.data._id };
          this.handleUnavailableList();
          return;
        }

        this.handleAvailableFullList(listQuery);
      });
  }

  private handleAvailableFullList(listQuery: FetchedQuery<ILegacyList>) {
    this.fullList.next({
      state: QueryStates.Fetched,
      data: {
        ...listQuery.data,
        items: this.filterListRows(listQuery.data, this.parentId).items,
      },
    });
  }

  private handleDeletedList(listQuery: FetchedQuery<ILegacyList>) {
    this.availableIdentifierForInvalidList = { name: listQuery.data.name, _id: listQuery.data._id };
    this.fullList.next({
      state: QueryStates.Error,
      error: new Error('The linked list has been deleted from this workspace. Please link a new list.'),
    });
    this.model.reference = null;
    this.toastr.errorByKey('listInTemplateIsDeleted', {
      toastClass: 'ngx-toastr toast-long-message',
    });
  }

  onChangeListSource(listSource: CategorySourceTypes) {
    if (this.logicRuleService.hasLogicRules(this.model)) {
      this.confirmService
        .confirm('templateListSourceChange', {
          confirmButtonText: this.i18nService.getMessage('confirmConfirm'),
        })
        .then(() => {
          this.updateListSource(listSource);
          return true;
        })
        .catch(() => {
          this.model.categorySource = this.previousValue;
        });
    } else {
      this.updateListSource(listSource);
    }
  }

  onAddLogic() {
    if (this.isManualList && this.listValuesHaveNumberId()) {
      void this.confirmService.confirm('templateLogicRuleUnavailable', {
        confirmButtonText: this.i18nService.getMessage('confirmOk'),
        showCancelButton: false,
      });
    } else {
      this.addLogicRuleCategory();
    }
  }

  private addLogicRuleCategory() {
    this.eventsService.trackEvent(EventTypes.TemplateAddLogicClicked, { Context: this.model });
    this.model.logicRules = this.logicRuleService.addLogicRule(this.model) as LogicRuleWeb[];
    this.updated.emit(this.model);
  }

  private listValuesHaveNumberId() {
    const listItems = this.model.items || [];
    return listItems.some(({ id }) => typeof id === 'number');
  }

  private get isManualList() {
    return this.model.categorySource === CategorySourceTypes.Manual;
  }

  get displayableCount() {
    return !this.allItems.enabled ? this.allItems.resumedAmount : undefined;
  }

  updateListSource(listSource: CategorySourceTypes) {
    this.model.categorySource = listSource;
    this.previousValue = this.model.categorySource;

    if (this.isManualList && this.listValuesHaveNumberId()) {
      this.model.logicRules = [];
    }

    this.getInitialItems();
    this.updated.emit(this.model);
  }

  toggleAllItems() {
    this.allItems.enabled = !this.allItems.enabled;
  }

  addRow(categoryInput: HTMLInputElement) {
    if (categoryInput.value) {
      const newRowId = new ObjectId().toHexString();
      this.model.items.push({
        id: newRowId,
        value: categoryInput.value,
        style: this.model.defaultColor || StyleVariants.Sky,
      });
    } else {
      this.toastrService.warnByKey('inputTextBeforeAdd');
    }
    categoryInput.value = '';
    categoryInput.focus();

    this.updated.emit(this.model);
  }

  removeItem(id: string) {
    this.model.items = this.model.items.filter((i) => i.id !== id);

    this.updated.emit(this.model);
  }

  onListSelected() {
    this.getFullList();
    this.updated.emit(this.model);
  }

  get isList() {
    return this.model.categoryType === CategoryTypes.Dropdown;
  }

  get isInline() {
    return this.model.categoryType === CategoryTypes.Inline;
  }

  onRowEdit(row: { id: string; value: string }) {
    this.editingRow = row;
  }

  onSelectOption(option: CategoryTypes) {
    this.model.categoryType = option;
  }
}
