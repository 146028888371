import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-generic-form-field-version-diff',
  templateUrl: './generic-form-field-version-diff.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFormFieldVersionDiffComponent {
  @Input() field: FieldWeb;
}
