import { Component, Input } from '@angular/core';

import { UserPermissionTypes } from '@site-mate/dashpivot-shared-library';

import { DropdownOptionData } from '../signature-logic-rule-helpers';

@Component({
  selector: 'cc-permission-type-values-dropdown-item',
  templateUrl: './permission-type-values-dropdown-item.component.html',
})
export class PermissionTypeValuesDropdownItemComponent {
  @Input() option: DropdownOptionData;
  readonly userPermissionTypes = UserPermissionTypes;
}
