import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TableCellData } from '@site-mate/dashpivot-shared-library';

import { FormulasErrors } from 'app/shared/service/formula-v2-validation/formula-error-messages';

export type IFormulaBar = TableCellData & {
  tableId?: string;
  clearSuggestions?: boolean;
  formulaBarClosed?: boolean;
  focusOnOpen?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FormulaBarService {
  private formulaBarValueSubject = new BehaviorSubject<IFormulaBar>({});
  public formulaBarValue$ = this.formulaBarValueSubject.asObservable();

  private formulaCellValueSubject = new BehaviorSubject<IFormulaBar>({});
  public formulaCellValue$ = this.formulaCellValueSubject.asObservable();

  private formulaOnFocusSubject = new BehaviorSubject<IFormulaBar>(null);
  public formulaOnFocus$ = this.formulaOnFocusSubject.asObservable();
  private errorMessageSeverityMap: Record<string, string>;

  constructor() {
    this.setFormulasSeverityMessageMap();
  }

  setFormulaBarValue(formulaValue: IFormulaBar) {
    this.formulaBarValueSubject.next(formulaValue);
  }

  setFormulaCellValue(formulaValue: IFormulaBar) {
    this.formulaCellValueSubject.next(formulaValue);
  }

  openFormulaBar(formulaValue: IFormulaBar) {
    this.formulaOnFocusSubject.next({
      id: formulaValue.id,
      tableId: formulaValue.tableId,
    });
    this.formulaCellValueSubject.next({ ...formulaValue, clearSuggestions: true });
  }

  closeFormulaBar(formulaValue: IFormulaBar) {
    this.formulaOnFocusSubject.next(null);
    this.formulaBarValueSubject.next({ ...formulaValue, formulaBarClosed: true });
    this.formulaBarValueSubject.next(null);
  }

  getFormulaSeverityMessageMap(): Record<string, string> {
    return this.errorMessageSeverityMap;
  }

  private setFormulasSeverityMessageMap() {
    this.errorMessageSeverityMap = {};

    [...FormulasErrors.values()].forEach(({ message, severity }) => {
      this.errorMessageSeverityMap[message] = severity;
    });
  }
}
