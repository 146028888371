import { Component, Input, OnInit } from '@angular/core';

import { CategorySourceTypes, StyleVariants } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-thumbnail',
  templateUrl: './category-thumbnail.component.html',
  styleUrls: ['../edit/form-category.component.scss', './category-thumbnail.component.scss'],
})
export class CategoryThumbnailComponent implements OnInit {
  @Input() field: FieldWeb;

  isLinkedToList: boolean;

  readonly styleVariants = StyleVariants;

  ngOnInit() {
    this.isLinkedToList = this.field.categorySource === CategorySourceTypes.List;
  }
}
