import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormPhotoDiffComponent } from './edit/diff/form-photo-diff.component';
import { FormPhotoListComponent } from './edit/form-photo-list.component';
import { FormPhotoComponent } from './edit/form-photo.component';
import { PhotoColumnComponent } from './register/photo-column.component';
import { PhotoTemplateComponent } from './template/photo-template.component';
import { PhotoThumbnailDropdownComponent } from './template/photo-thumbnail-dropdown/photo-thumbnail-dropdown.component';
import { PhotoTemplatePreviewComponent } from './template/preview/photo-template-preview.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SegmentModule,
    FormFieldsCommonModule,
    SharedModule,
    NgbTooltipModule,
    UIComponentsModule,
  ],
  declarations: [
    FormPhotoListComponent,
    FormPhotoComponent,
    PhotoColumnComponent,
    PhotoTemplatePreviewComponent,
    PhotoTemplateComponent,
    FormPhotoDiffComponent,
    PhotoThumbnailDropdownComponent,
  ],
  exports: [
    FormPhotoListComponent,
    FormPhotoComponent,
    PhotoTemplatePreviewComponent,
    PhotoTemplateComponent,
  ],
})
export class PhotoModule {}
