<div class="form-card-container" *ngIf="field.selectedItems?.length">
  <span
    *ngFor="let categoryItem of field.selectedItems"
    [ngClass]="categoryItem.style ? categoryItem.style : styleVariants.None"
    class="category-item-selected category-item color-choice-item"
  >
    {{ categoryItem.value }}
  </span>
</div>
<small class="text-muted" *ngIf="!field.selectedItems?.length" i18n>No item selected </small>
