<div [formGroup]="listCellForm" class="sm-flex sm-flex-col sm-gap-y-2" data-test="list-cell">
  <div class="sm-flex sm-flex-col sm-gap-y-2">
    <span i18n>List Item Source:</span>
    <label>
      <input
        data-test="list-source-list"
        type="radio"
        formControlName="listSource"
        [value]="CategorySourceTypes.List"
      />
      Link to a List
    </label>
    <label>
      <input
        data-test="list-source-manual"
        type="radio"
        formControlName="listSource"
        [value]="CategorySourceTypes.Manual"
      />
      Manually typed
    </label>
  </div>

  <div
    class="sm-flex sm-flex-col sm-gap-y-2"
    *ngIf="cellData.listSource === CategorySourceTypes.List && (fullList | async) as fullListQuery"
  >
    <ng-select
      class="lists"
      formControlName="reference"
      i18n-placeholder="Select a list"
      placeholder="Select..."
      (change)="onListSelected()"
      appendTo="body"
      [loading]="fullListQuery.state === QueryStates.Loading"
      [class.ng-select-error]="fullListQuery.state === QueryStates.Error"
      [clearable]="false"
    >
      <ng-template ng-loadingspinner-tmp>
        <div class="w-100 text-left">Loading...</div>
      </ng-template>

      <ng-option
        *ngIf="fullListQuery.state === QueryStates.Error"
        [value]="availableIdentifierForInvalidList._id"
      >
        <span data-test-id="list-option">{{ availableIdentifierForInvalidList.name }}</span>
      </ng-option>

      <ng-option *ngFor="let list of (listIdentifiers$ | async).data" [value]="list._id">
        <span data-test-id="list-option">{{ list.name }}</span>
      </ng-option>
    </ng-select>

    <div class="list-deployment-status" *ngIf="isOrganisationTemplate">
      <label class="deploy-option">
        <span>Auto-deploy a child list</span>
        <input type="checkbox" formControlName="autoDeployChildList" />
      </label>

      <a
        href="https://intercom.help/dashpivot/en/articles/4499919-list-deployment"
        target="_blank"
        class="button-learn-more"
      >
        <i class="cc-icon cc-icon-question"></i>
        <span i18n class="">Learn more</span>
      </a>
    </div>

    <p class="sm-text-default-red" *ngIf="fullListQuery.state === QueryStates.Fetched && !cellData.reference">
      Please select a list from the dropdown above.
    </p>
    <p class="sm-text-default-red" *ngIf="fullListQuery.state === QueryStates.Error">
      {{ fullListQuery.error.message }}
    </p>
  </div>

  <div *ngIf="cellData.listSource === CategorySourceTypes.Manual" class="sm-flex sm-flex-col sm-gap-2">
    <div class="input-group input-group-sm">
      <input
        type="text"
        class="form-control border-right-0 input-add-item"
        placeholder="Input list item"
        i18n-placeholder="Input list item"
        #itemInput
        (keyup.enter)="addListItem(itemInput)"
      />
      <div class="input-group-append">
        <button
          class="btn btn-secondary btn-add-item btn-sm"
          type="button"
          i18n
          (click)="addListItem(itemInput)"
        >
          Add
        </button>
      </div>
    </div>
    <div class="sm-flex sm-flex-wrap sm-items-center sm-gap-1 sm-py-1">
      <span i18n>Default colour: </span>
      <cc-style-color-picker [(style)]="cellData.defaultColor"></cc-style-color-picker>
    </div>
    <span data-test="list-items-header" i18n>List items: </span>
    <cc-app-table-column-list-item
      *ngFor="let item of cellData.manualListItems"
      [editingItem]="editingListItem"
      [item]="item"
      (remove)="removeListItem($event)"
      (onEdit)="onEditListItem($event)"
    >
    </cc-app-table-column-list-item>
    <div
      *ngIf="!cellData.manualListItems || cellData.manualListItems.length === 0"
      class="sm-text-default-red"
      i18n
    >
      Please add list items
    </div>
  </div>
</div>
