<div>
  <div class="input-group">
    <input
      type="text"
      class="form-control sm-cursor-grab"
      placeholder="Date"
      readonly
      i18n-placeholder="Date"
    />
    <span class="input-group-btn">
      <button class="btn btn-secondary disabled sm-cursor-grab" type="button">
        <i class="cc-icon cc-icon-calendar-clock sm-align-middle"></i>
      </button>
    </span>
  </div>
</div>
