import { Directive, ViewContainerRef, ElementRef } from '@angular/core';

@Directive({
  selector: '[ccFormFieldTemplatePreviewHost]',
})
export class FormFieldTemplatePreviewHostDirective {
  constructor(
    public readonly viewContainerRef: ViewContainerRef,
    public readonly element: ElementRef,
  ) {}
}
