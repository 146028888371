import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Template, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { IField } from 'app/apps/template-default-fields/field.model';

import { PrefilledTextFieldCharacterLimit } from './prefilled-text-field-character-limit';

@Component({
  selector: 'cc-header-footer-prefilled-text-field',
  templateUrl: './header-footer-prefilled-text-field.component.html',
  styleUrls: [
    '../header-footer-field-item.component.scss',
    '../../../../apps/template-edit/template-field-group/template-field-group.component.scss',
  ],
})
export class HeaderFooterPrefilledTextFieldComponent {
  @Input() template: Template;
  @Input() headerFooterField: IField;
  @Input() hasPrefilledTextError: boolean;

  @Output() updated = new EventEmitter();
  @Output() setPrefilledTextError = new EventEmitter();

  getPrefilledTextError(prefilledTextContent: { value: string }) {
    const prefilledTextLength = prefilledTextContent.value.length;

    if (this.template.orientation === TemplateOrientations.Portrait) {
      return prefilledTextLength > PrefilledTextFieldCharacterLimit[TemplateOrientations.Portrait];
    }
    return prefilledTextLength > PrefilledTextFieldCharacterLimit[TemplateOrientations.Landscape];
  }

  onKeyUp(prefilledContent: { value: string }) {
    this.hasPrefilledTextError = this.getPrefilledTextError(prefilledContent);
    this.setPrefilledTextError.emit(this.hasPrefilledTextError);
  }

  onUpdate(prefilledContent: { value: string }) {
    this.headerFooterField.content = prefilledContent.value;
    this.updated.emit(this.headerFooterField);
  }
}
