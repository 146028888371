<div class="font-weight-bold mb-3">{{ field.description }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required: </span><input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>
<div>
  <span i18n>Category type: </span>
  <span *ngIf="field.categoryType === categoryTypes.Inline" i18n>Multiple choice</span>
  <span *ngIf="field.categoryType === categoryTypes.Dropdown" i18n>Dropdown</span>
</div>
<div>
  <span i18n>Category item source: </span>
  <span *ngIf="field.categorySource === categorySourceTypes.Manual" i18n>Manual typed</span>
  <span *ngIf="field.categorySource === categorySourceTypes.List" i18n>Link to a List</span>
</div>
<div *ngIf="field.categorySource === categorySourceTypes.List">
  <span i18n>List reference: </span><span>{{ field.reference }}</span>
</div>
<div class="app-category-item-container">
  <cc-app-category-item
    *ngFor="let row of field.items"
    [row]="row"
    [showControl]="false"
    [editingRow]="false"
    [displayColorPicker]="false"
  ></cc-app-category-item>
</div>
