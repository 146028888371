/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectId } from 'bson';

import {
  ComplementaryLogicOperators,
  ComplementarySignatureRuleLogic,
  FormSignatureService,
  IField,
  LogicOperators,
  SignatureLogicActions,
  SignatureLogicTypes,
  UserPermissionTypes,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { TemplatesService } from 'app/apps/templates.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SignatureRuleLogicWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { environment } from 'environments/environment';

import {
  ActionsDropdown,
  DefaultPlanMaxComplementaryLogicRules,
  DropdownNames,
  DropdownOptionData,
  LimitUpgradablePlans,
  MaximumPlanMaxComplementaryLogicRules,
  PlanMaxComplementaryLogicRules,
  TypesDropdowns,
  getTypesToOperatorAndValuesMap,
  updateSignatureValues,
} from './signature-logic-rule-helpers';
import { SignatureLogicRuleService } from './signature-logic-rule.service';

@Component({
  selector: 'cc-signature-logic-rule',
  templateUrl: './signature-logic-rule.component.html',
  styleUrls: [
    '../../../../common/template/form-control.component.scss',
    './signature-logic-rule.component.scss',
  ],
})
export class SignatureLogicRuleComponent implements OnInit {
  readonly enableComplementaryLogicFeature = environment.featureToggles.complementarySignatureLogic;
  readonly maxValueEnterpriseOrFreeTrial = MaximumPlanMaxComplementaryLogicRules;
  readonly maxValueRemainingPlans = DefaultPlanMaxComplementaryLogicRules;

  @Input() rule: SignatureRuleLogicWeb;
  @Input() canUpgradePlan: boolean;
  @Input() companyPlanType: CompanyPlanTypes;
  @Input() fields: IField[];

  @Output() validate = new EventEmitter<void>();
  @Output() delete = new EventEmitter<string>();

  readonly signatureTypes = SignatureLogicTypes;
  readonly signatureActions = SignatureLogicActions;
  readonly logicOperators = LogicOperators;
  readonly userPermissionTypes = UserPermissionTypes;

  readonly dropdownNames = DropdownNames;
  readonly typesDropdowns = TypesDropdowns;
  readonly actionsDropdown = ActionsDropdown;

  public sharedSignatureService = new FormSignatureService();

  selectedType: DropdownOptionData;
  selectedOperator: DropdownOptionData;
  selectedAction: DropdownOptionData;
  selectedValues: DropdownOptionData[] | DropdownOptionData = [];

  inputRequiredMessage: string;
  bannerMessage: string;
  betaWarningBannerMessage: string;
  popoverMessage: string;
  isFreeTrialAccount: boolean;
  isLimitUpgradable: boolean;
  enableAndButton: boolean;
  maxComplementaryRules: number;
  showUpgradeButton: boolean;
  maxRulesExceeded: boolean;
  showProPlanWarningBanner: boolean;
  proPanWarningBannerMessage: string;
  typesToOperatorAndValuesMap: ReturnType<typeof getTypesToOperatorAndValuesMap>;
  exceedLimitWarningBannerMessage: string;

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly confirmService: ConfirmService,
    private readonly signatureLogicRuleService: SignatureLogicRuleService,
    private readonly templatesService: TemplatesService,
  ) {}

  ngOnInit(): void {
    this.typesToOperatorAndValuesMap = getTypesToOperatorAndValuesMap(this.fields);
    this.templatesService.templateUpdatedEvent.subscribe(() => {
      this.typesToOperatorAndValuesMap = getTypesToOperatorAndValuesMap(this.fields);
      this.selectedValues = updateSignatureValues(this.selectedValues, this.valuesDropdown, this.fields);
      if (Array.isArray(this.selectedValues)) {
        this.rule.values = this.selectedValues.map((value) => value.value);
      } else {
        this.rule.values = [this.selectedValues.value];
      }
    });
    this.setSelectedDropdownValues();
    this.setPopoverMessage();

    this.inputRequiredMessage = this.i18nService.getMessage('inputRequiredError');
    this.bannerMessage = this.i18nService.getMessage('planDoesNotIncludeSignatureLogicField');
    this.betaWarningBannerMessage = this.i18nService.getMessage('betaWarningBannerApprovalSignatureLogic');
    this.isFreeTrialAccount = this.companyPlanType === CompanyPlanTypes.FreeTrial;
    this.maxComplementaryRules = PlanMaxComplementaryLogicRules[this.companyPlanType];
    this.isLimitUpgradable = LimitUpgradablePlans.includes(this.companyPlanType);

    this.updateAndButtonState();
    this.selectedValues = updateSignatureValues(this.selectedValues, this.valuesDropdown, this.fields);
  }

  private setLimitUpgradablePlanTypeState() {
    this.maxRulesExceeded =
      this.sharedSignatureService.rulesExceedMax([this.rule], this.maxComplementaryRules) &&
      LimitUpgradablePlans.includes(this.companyPlanType);

    if (this.maxRulesExceeded) {
      const planKey = this.companyPlanType === CompanyPlanTypes.Pro ? 'pro' : 'premium';
      this.exceedLimitWarningBannerMessage = this.i18nService.getMessage(
        `${planKey}PlanExceedANDMaxRulesBannerWarning`,
      );
    }
  }

  private setSelectedDropdownValues() {
    this.selectedType = this.typesDropdowns.find(
      (item) => (item.value as SignatureLogicTypes) === this.rule.type,
    );
    this.selectedOperator = this.operatorsDropdown.find(
      (item) => (item.value as LogicOperators) === this.rule.operator,
    );

    const isEqualsOperator = this.rule.operator === this.logicOperators.Equals;
    const selectedValues =
      this.rule.values?.map((value) => {
        return {
          label: this.valuesDropdown.find((item) => item.value === value)?.label,
          value,
        };
      }) || [];
    /*
      When the dropdown is a multi-select, `selectedValues` will be an array containing DropdownOptionData objects,
      otherwise, it will be a single DropdownOptionData object.
    */
    this.selectedValues = isEqualsOperator ? selectedValues : selectedValues[0] || [];
    this.selectedAction = this.actionsDropdown.find(
      (item) => (item.value as SignatureLogicActions) === this.rule.action,
    );
  }

  validateLogicRules(): void {
    this.validate.emit();
  }

  onInputChange(selected: any, input: string, revalidate: boolean): void {
    const changedInputType: DropdownNames = input as DropdownNames;
    if (changedInputType === this.dropdownNames.Values) {
      const isEqualsOperator = this.rule.operator === this.logicOperators.Equals;
      this.rule[input] = isEqualsOperator ? selected?.map((item) => item.value) : [selected];
    }

    if (changedInputType === this.dropdownNames.Operator && selected !== this.rule[input]) {
      this.rule.values = [];
      this.selectedValues = [];
      this.rule[input] = selected;
    }

    if (changedInputType === this.dropdownNames.Type && selected !== this.rule[input]) {
      this.rule.values = [];
      this.rule.operator = null;
      this.selectedOperator = null;
      this.selectedValues = [];
      this.rule[input] = selected;
    }

    if (changedInputType === this.dropdownNames.Action) {
      this.rule[input] = selected;
    }

    if (revalidate) {
      this.validateLogicRules();
    }

    this.updateAndButtonState();
  }

  onSelectedValuesChange(selected: DropdownOptionData[] | DropdownOptionData): void {
    this.selectedValues = selected;
    this.updateAndButtonState();
  }

  onSelectedActionChange(selected: DropdownOptionData): void {
    this.selectedAction = selected;
    this.updateAndButtonState();
  }

  get operatorsDropdown(): ReadonlyArray<Readonly<DropdownOptionData>> {
    return this.typesToOperatorAndValuesMap[this.rule.type]?.operators || [];
  }

  get valuesDropdown(): ReadonlyArray<Readonly<DropdownOptionData>> {
    return this.typesToOperatorAndValuesMap[this.rule.type]?.values || [];
  }

  get isSelectedValuesMultiple(): boolean {
    return (
      this.rule.type === this.signatureTypes.Permission &&
      Array.isArray(this.rule.values) &&
      this.rule.values.length > 1
    );
  }

  onDeleteRule(): void {
    this.delete.emit(this.rule._id);
    this.updateAndButtonState();
  }

  isInputInvalid(input: DropdownNames): boolean {
    return this.rule._invalid && this.rule._invalidInputs?.includes(`${input}-${this.rule._id}`);
  }

  private setEnableAndButton(): void {
    const hasType = !!this.selectedType?.value;
    const hasOperator = !!this.selectedOperator?.value;
    const hasValues =
      'length' in this.selectedValues ? !!this.selectedValues.length : !!this.selectedValues?.value;
    const hasAction = !!this.selectedAction?.value;
    const hasLessThanMaxRules =
      !this.rule.complementaryRules || this.rule.complementaryRules?.length < this.maxComplementaryRules;

    this.enableAndButton = hasType && hasOperator && hasValues && hasAction && hasLessThanMaxRules;
  }

  setPopoverMessage() {
    const showMaxLimitMessage =
      this.rule.complementaryRules?.length &&
      this.rule.complementaryRules?.length >= this.maxComplementaryRules;

    if (showMaxLimitMessage) {
      this.popoverMessage = `Maximum of ${this.maxComplementaryRules} AND level rules can be created per approval signature logic.`;
    } else {
      this.popoverMessage = this.i18nService.getMessage('populateApprovalSignatureInLogicRule');
    }
  }

  private updateAndButtonState(): void {
    this.setPopoverMessage();
    this.setEnableAndButton();
    this.setLimitUpgradablePlanTypeState();
  }

  onAddComplementaryLogic() {
    this.rule.complementaryOperator = ComplementaryLogicOperators.AND;
    this.rule.complementaryRules = this.rule.complementaryRules || [];

    this.rule.complementaryRules.push({
      _id: new ObjectId().toHexString(),
    } as ComplementarySignatureRuleLogic);

    this.updateAndButtonState();
  }

  async onDeleteComplementaryLogic(id: string) {
    try {
      await this.confirmService.confirmDelete('deleteCondition', { confirmButtonText: 'Delete' });

      const ruleToDeleteIndex = this.rule.complementaryRules.findIndex((rule) => rule._id === id);
      const hasNestedRules = this.rule.complementaryRules[ruleToDeleteIndex].complementaryRules?.length;
      const ruleToDelete = this.rule.complementaryRules[ruleToDeleteIndex];

      if (hasNestedRules) {
        this.rule.complementaryRules[ruleToDeleteIndex] = this.promoteFirstChildRuleToParent(ruleToDelete);
      } else {
        this.rule.complementaryRules = this.rule.complementaryRules.filter((rule) => rule._id !== id);
        if (this.rule.complementaryRules.length === 0) {
          delete this.rule.complementaryOperator;
          delete this.rule.complementaryRules;
        }
      }

      this.updateAndButtonState();
    } catch {
      // no-op
    }
  }

  private promoteFirstChildRuleToParent(
    rule: ComplementarySignatureRuleLogic,
  ): ComplementarySignatureRuleLogic {
    const [firstChildRule, ...rest] = rule.complementaryRules;
    const { id, _id, type, operator, values, _invalid, _invalidInputs, _invalidMessage } = firstChildRule;
    const { complementaryOperator } = rule;

    return {
      id,
      _id,
      type,
      operator,
      values,
      _invalid,
      _invalidInputs,
      _invalidMessage,
      complementaryOperator,
      complementaryRules: rest,
    };
  }

  toggleShowAllConditions(): void {
    this.rule.showAllConditions = !this.rule.showAllConditions;
  }

  onAddLogicMouseEnter() {
    const hasReachedMaxConditions = this.rule.complementaryRules?.length >= this.maxComplementaryRules;
    this.showUpgradeButton = hasReachedMaxConditions && LimitUpgradablePlans.includes(this.companyPlanType);
  }

  onAddLogicMouseLeave() {
    this.showUpgradeButton = false;
  }

  onOpenUpgradeModal() {
    this.signatureLogicRuleService.openUpgradeModal();
  }
}
