import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

import { Company, ITemplateWithHierarchy, PathService, Project } from '@site-mate/dashpivot-shared-library';

import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TeamService } from 'app/shared/service/team.service';

@Component({
  selector: 'cc-photo-container',
  templateUrl: 'photo-container.component.html',
  styleUrls: ['photo-container.component.scss'],
})
export class PhotoContainerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly pathService = new PathService();

  @Input() teamId: string;
  template: ITemplateWithHierarchy;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly teamService: TeamService,
    private readonly router: Router,
    private readonly errorHandler: ErrorHandler,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        filter(() => this.teamService.isTeamRoute(this.router.url)),
        switchMap((params) => {
          this.teamId = params.teamId;
          return this.teamService.getTeam(this.teamId).pipe(takeUntil(this.destroy$));
        }),
      )
      .subscribe((team) => {
        const [companyId, projectId] = this.pathService.extractFolderIds(team.path);
        this.template = {} as ITemplateWithHierarchy;
        this.template.company = { _id: companyId } as Company;
        this.template.project = { _id: projectId } as Project;
        this.template.team = team;
      }, this.errorHandler.handle.bind(this.errorHandler));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
