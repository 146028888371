import { inject, Injectable } from '@angular/core';
import { ObjectId } from 'bson';
import { filter, map } from 'rxjs';

import {
  FieldKinds,
  TableCellKinds,
  TableColumn,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { TemplateListPropertyService } from 'app/apps/template-list-property.service';
import { TemplatesService } from 'app/apps/templates.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { Positions } from 'app/shared/model/positions.enum';
import { FormulasVersion } from 'app/shared/service/formula-engine.service';
import { FormulaV2TemplateEngineService } from 'app/shared/service/formula-v2-template-engine/formula-v2-template-engine.service';
import { HotFormulaParserEngineService } from 'app/shared/service/hot-formula-parser-engine.service';

@Injectable({ providedIn: 'root' })
export class AppTableColumnService {
  private readonly templateListPropertyService = inject(TemplateListPropertyService);

  private isFormulaV2: boolean;
  constructor(
    private readonly formulaV2TemplateEngineService: FormulaV2TemplateEngineService,
    private readonly hotFormulaParserEngineService: HotFormulaParserEngineService,
    private readonly templatesService: TemplatesService,
  ) {
    this.templatesService.currentOpenTemplateFormulasVersion
      .pipe(
        filter(Boolean),
        map((version) => (version as FormulasVersion) === FormulasVersion.v2),
      )
      .subscribe((isFormulaV2) => {
        this.isFormulaV2 = isFormulaV2;
      });
  }

  addColumn(model: FieldWeb, columnIndex?: number) {
    const index = columnIndex ?? model.columns.length;
    const currentColumn = model.columns[index - 1];
    let currentColumnKind = currentColumn?.kind ?? TableCellKinds.Text;

    if (currentColumnKind === TableCellKinds.ListProperty) {
      if (this.templateListPropertyService.isLPTCCountExceeded) {
        currentColumnKind = TableCellKinds.Text;
      }
    }

    const newColumn = () => this.generateNewColumn(index, currentColumnKind);
    model.columns.splice(index, 0, newColumn());

    if (model.rows && model.rows.length) {
      model.rows.forEach((row) => {
        currentColumnKind = row.columns[index - 1]?.kind ?? TableCellKinds.Text;
        const column = newColumn();
        delete column.heading;
        row.columns.splice(index, 0, column);
      });
    }

    if (this.isFormulaV2) {
      this.formulaV2TemplateEngineService.addColumn(model, columnIndex);
    }

    if (currentColumnKind === TableCellKinds.ListProperty) {
      this.templateListPropertyService.incrementListPropertyTableCellCounter();
    }
  }

  removeColumn(columnIndex: number, model: FieldWeb) {
    const columnToBeRemoved = model.columns[columnIndex];
    model.columns.splice(columnIndex, 1);
    if (model.rows && model.rows.length) {
      model.rows.forEach((row) => {
        row.columns.splice(columnIndex, 1);
      });
    }

    if (this.isFormulaV2) {
      this.formulaV2TemplateEngineService.removeColumn(model, columnIndex);
    }

    if (columnToBeRemoved.kind === TableCellKinds.ListProperty) {
      this.templateListPropertyService.decrementListPropertyTableCellCounter();
    }
  }

  moveColumnLeft(index: number, model: FieldWeb) {
    const col = model.columns.splice(index, 1)[0];
    model.columns.splice(index - 1, 0, col);

    if (model.kind === FieldKinds.PrefilledTable) {
      model.rows.forEach((row) => {
        const prefilledCol = row.columns.splice(index, 1)[0];
        row.columns.splice(index - 1, 0, prefilledCol);
      });
    }

    this.updateFormulasColumnMove(Positions.Left, index, model);
  }

  moveColumnRight(index: number, model: FieldWeb) {
    const col = model.columns.splice(index, 1)[0];
    model.columns.splice(index + 1, 0, col);

    if (model.kind === FieldKinds.PrefilledTable) {
      model.rows.forEach((row) => {
        const prefilledCol = row.columns.splice(index, 1)[0];
        row.columns.splice(index + 1, 0, prefilledCol);
      });
    }

    this.updateFormulasColumnMove(Positions.Right, index, model);
  }

  updateFormulasColumnMove(position: Positions.Left | Positions.Right, index: number, model: FieldWeb) {
    if (this.isFormulaV2) {
      this.formulaV2TemplateEngineService.updateFormulasOnColumnMove(model, position, index);
    } else {
      this.hotFormulaParserEngineService.updateFormulasOnColumnMove(model, position, index);
    }
  }

  private generateNewColumn(index: number, kind: TableCellKinds): TableColumn {
    const column = {
      heading: `Column${index + 1}`,
      kind,
      width: TableColumnWidths.Medium,
      _id: new ObjectId().toHexString(),
    } as Partial<TableColumn>;

    column.id = column._id;

    return column as TableColumn;
  }
}
