<cc-text-input
  data-test="formula-cell"
  [(value)]="cellData.formula"
  placeholder="= enter formula"
  infoLink="https://intercom.help/dashpivot/en/articles/3695027-how-formulas-and-calculations-work-in-dashpivot"
  [selected]="isFocused"
  [hasError]="!!this.cellData.formulaError"
  (onValueChanged)="onFormulaChanged($event)"
>
  <cc-tool-tip
    class="sm-flex sm-h-full"
    containerClass="sm-flex sm-items-center"
    [contentText]="legacyFormulaEngineWarningText"
    placement="bottom-left"
    *ngIf="usingLegacyFormulaEngine"
    rhsAddon
  >
    <i
      class="cc-icon cc-icon-warning sm-cursor-pointer sm-text-default-orange"
      data-test-id="legacy-formula-engine-warning-icon"
    >
    </i>
  </cc-tool-tip>
</cc-text-input>

<p *ngIf="cellData.formulaError === emptyFormulaError" class="sm-text-default-red">
  {{ emptyFormulaError }}
</p>
