import {
  Component,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';

import { FormFieldThumbnailFactory } from 'app/form-fields/form-field-thumbnail-factory';
import { FieldWeb } from 'app/shared/model/item.model';

import { FormFieldThumbnail } from './form-field-thumbnail';
import { FormFieldThumbnailHostDirective } from './form-field-thumbnail-host.directive';

@Component({
  selector: 'cc-form-field-thumbnail',
  templateUrl: './form-field-thumbnail.component.html',
  styleUrls: ['./form-field-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldThumbnailComponent implements AfterViewInit {
  @Input()
  field: FieldWeb;

  @ViewChild(FormFieldThumbnailHostDirective, { static: true })
  thumbHost: FormFieldThumbnailHostDirective;

  thumbnail: FormFieldThumbnail;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (!this.field) {
      return;
    }
    this.thumbnail = FormFieldThumbnailFactory.getThumbnail(this.field);

    if (this.thumbHost && this.thumbnail) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.thumbnail.component,
      );
      const { viewContainerRef } = this.thumbHost;
      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance.field = this.thumbnail.field;
      this.ref.detectChanges();
    }
  }
}
