import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Field } from '@site-mate/dashpivot-shared-library';

import { MultiSelectFilter } from 'app/form-fields/common/register/filters/multi-select/multi-select-filter';

@Component({
  selector: 'cc-category-analytics-filter',
  templateUrl: './category-analytics-filter.component.html',
  styleUrls: ['./category-analytics-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoryAnalyticsFilterComponent implements OnInit, OnDestroy {
  @Input() field: Field;
  @Input() state: any;
  @Input() options: Observable<string[]>;
  @Output() onChange = new EventEmitter();

  filter: MultiSelectFilter;
  unSubscribe = new Subject<void>();

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filter = new MultiSelectFilter(this, this.field._id);
    this.filter.selectedValues = this.state;

    this.options.pipe(takeUntil(this.unSubscribe)).subscribe((options) => {
      this.filter.options = options;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  onSelectionChange() {
    this.onChange.emit(this.filter.queryParams());
  }
}
