<div class="font-weight-bold mb-3">{{ field.description }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required:</span> <input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>

<div class="app-control-control d-flex">
  <div>
    <div class="input-group">
      <input
        type="text"
        class="form-control sm-cursor-grab"
        placeholder="Start Date"
        readonly
        i18n-placeholder="Start Date"
      />
      <span class="input-group-btn">
        <button class="btn btn-secondary disabled sm-cursor-grab" type="button">
          <i class="cc-icon cc-icon-calendar-clock sm-align-middle"></i>
        </button>
      </span>
    </div>
  </div>
  <div *ngIf="field.isDateAndTime">
    <select class="form-control sm-cursor-grab" disabled>
      <option i18n>Start Time</option>
    </select>
  </div>
  <div class="mx-3 text-muted form-control-static" *ngIf="!field.isSingleDate">&mdash;</div>
  <div *ngIf="!field.isSingleDate">
    <div class="input-group">
      <input
        type="text"
        class="form-control sm-cursor-grab"
        placeholder="End Date"
        readonly
        i18n-placeholder="End Date"
      />
      <span class="input-group-btn">
        <button class="btn btn-secondary disabled sm-cursor-grab" type="button">
          <i class="cc-icon cc-icon-calendar-clock sm-align-middle"></i>
        </button>
      </span>
    </div>
  </div>
  <div *ngIf="field.isDateAndTime && !field.isSingleDate">
    <select class="form-control sm-cursor-grab" disabled>
      <option i18n>End Time</option>
    </select>
  </div>
  <div class="expiry-date sm-ml-auto" *ngIf="field.isExpiryDate">
    <i class="cc-icon cc-icon-warning sm-text-default-orange"></i><span i18n>Expires in # days</span>
  </div>
</div>
