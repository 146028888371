const ABSOLUTE_OPERATOR = '$';
const RANGE_OPERATOR = ':';
const UNICODE_LETTER_PATTERN = 'A-Za-z\u00C0-\u02AF';
const UNQUOTED_SHEET_NAME_PATTERN = `[${UNICODE_LETTER_PATTERN}0-9_]+`;
const QUOTED_SHEET_NAME_PATTERN = "'(((?!').|'')*)'";
const SHEET_NAME_PATTERN = `(${UNQUOTED_SHEET_NAME_PATTERN}|${QUOTED_SHEET_NAME_PATTERN})!`;
const CELL_REFERENCE_PATTERN = `(?:${SHEET_NAME_PATTERN})?\\${ABSOLUTE_OPERATOR}?[A-Za-z]+\\${ABSOLUTE_OPERATOR}?[0-9]+`;

// This regex is used to match cell references with range operator, it won't match cell references without numeric parts.
// Example: "=Table1!C2:C2 + Sheet1!C:C + A1:B1 + A:C + Table1!G:G" will match ['Table1!C2:C2', 'Sheet1', 'A1:B1'],
export const FULL_CELL_REFERENCE = `${CELL_REFERENCE_PATTERN}\\${RANGE_OPERATOR}?(?:${CELL_REFERENCE_PATTERN})?`;

// This regex is used to match only the sheet name;
// Example: "=Table1!C2:C2 + Sheet1!C:C + A1:B1 + A:C + Table1!G:G"  will match ['Table1', 'Sheet1', 'Table1']
export const SHEET_NAME_WITHOUT_EXCLAMATION = `${SHEET_NAME_PATTERN}?(?=!)`;

// This regex is used to match cell references with left side range operator
// Example: "Table1!C2:C2 + A1:B1" will match ["Table1!C2", "A1"],
export const CELL_REFEFERENCE_WITH_LEFT_SIDE_RANGE_OPERATOR = `${CELL_REFERENCE_PATTERN}?(?=:)`;
