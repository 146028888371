import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { FormFieldHeaderColumn } from '../form-field-header/form-field-header-column';

export abstract class Filter {
  columns: FormFieldHeaderColumn[];
  renderer;
  options: any;
  uniqueId: string;

  constructor(renderer) {
    this.renderer = renderer;
    this.uniqueId = uuid();
  }

  abstract queryParams();
  abstract get status(): number;
  abstract reset();
  abstract set(params: any);
}
