import { TableDisplayMode } from '@site-mate/dashpivot-shared-library';

type TableDisplayModeProperties = {
  key: TableDisplayMode;
  description: string;
};

export class TableDisplayModes {
  static Horizontal: TableDisplayModeProperties = {
    key: 'horizontal',
    description: 'Horizontally (default)',
  };

  static Vertical: TableDisplayModeProperties = {
    key: 'vertical',
    description: 'Vertically',
  };

  static list() {
    return [this.Horizontal, this.Vertical];
  }

  static defaultMode() {
    return this.Horizontal;
  }
}
