import { MultiSelectFilterOption } from '../multi-select/multi-select-filter-option';

export const yesNoFilterOptions: MultiSelectFilterOption[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const yesNoNaFilterOptions: MultiSelectFilterOption[] = [
  ...yesNoFilterOptions,
  { label: 'N/A', value: 'na' },
];
