<div class="template-field">
  <div class="sm-mb-4">
    <div class="row app-control-row logic-rule" [class.logic-rule-invalid]="rule._invalid">
      <div class="col">
        <div class="row justify-content-between align-items-center">
          <div class="form-inline">
            <a
              title="{{ rule.isOpen ? 'Close' : 'Open' }}"
              (click)="toggleOpenFields()"
              class="field-toggle pr-4 no-drag sm-text-default-text"
            >
              <i class="fa" [class.fa-chevron-down]="rule.isOpen" [class.fa-chevron-right]="!rule.isOpen"></i>
            </a>
            <span i18n>If value</span>
            <ng-select
              class="mx-2 logic-select hide-arrow"
              [disabled]="true"
              data-test="logic-operator"
              [searchable]="false"
              [clearable]="false"
              [items]="logicOperators"
              placeholder="Select..."
              i18n-placeholder="Select..."
              [(ngModel)]="rule.logicOperator"
              name="{{ 'operator-' + rule.id }}"
              (change)="validateLogicRules()"
            >
            </ng-select>
            <ng-select
              class="logic-select"
              data-test="logic-values"
              [items]="logicValues"
              [multiple]="true"
              [closeOnSelect]="!isCategory"
              bindLabel="label"
              bindValue="id"
              placeholder="Select..."
              i18n-placeholder="Select..."
              [(ngModel)]="rule.values"
              name="{{ 'value-' + rule.id }}"
              (change)="validateLogicRules()"
            >
            </ng-select>
            <span class="mx-2">then</span>
            <ng-select
              class="logic-select hide-arrow"
              data-test="logic-actions"
              [disabled]="true"
              [items]="logicActions"
              [searchable]="false"
              [clearable]="false"
              placeholder="Select..."
              i18n-placeholder="Select..."
              [(ngModel)]="rule.logicAction"
              name="{{ 'value-' + rule.id }}"
              (change)="validateLogicRules()"
            >
            </ng-select>
          </div>
          <span *ngIf="rule._invalid" class="sm-text-default-red">{{ rule._invalidMessage }}</span>
          <a
            href="https://intercom.help/dashpivot/en/articles/5105484-conditional-logic"
            target="_blank"
            class="learn-more-helper"
            *ngIf="showLearnMore && !rule._invalid"
          >
            <cc-banner bannerText="Learn more" icon="question" size="sm" type="helper"></cc-banner>
          </a>
        </div>
        <div *ngIf="showFields" class="row drop-zone p-3 mt-2">
          <div *ngIf="emptyFields" class="empty-fields text-center text-muted">
            <span>Drop fields here</span>
          </div>
          <form
            class="target container drop-zone-form"
            [attr.data-logic-rule-id]="rule.id"
            [attr.data-field-id]="field.id"
            [dragula]="dragulaId"
          >
            <ng-container *ngFor="let control of fields; index as i; trackBy: trackByFieldIdentifier">
              <cc-template-field
                *ngIf="control.dependsOn?.logicRuleId === rule.id"
                [attr.data-field-id]="control.id"
                [controls]="fields"
                [field]="control"
                [template]="template"
                [metadataList]="metadataList"
                [parentId]="parentId"
                [companyId]="companyId"
                [templateOrientation]="templateOrientation"
                (remove)="onRemoveField(i)"
                (clone)="onCloneField(i)"
                (updated)="onFieldUpdated()"
                [hasReachedPlanLimit]="hasReachedPlanLimit"
              ></cc-template-field>
            </ng-container>
          </form>
        </div>
      </div>
      <button type="button" class="remove-control btn" (click)="onRemoveRule()">
        <i class="cc-icon cc-icon-close mr-1 sm-text-default-red" aria-hidden="true"></i>
        <span class="control-label" i18n>Delete</span>
      </button>
      <cc-field-action
        type="clone"
        [disabled]="hasReachedPlanLimit"
        (onClick)="onCloneRule()"
      ></cc-field-action>
    </div>
  </div>
</div>
