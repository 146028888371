<div [formGroup]="form" class="sm-flex sm-flex-col sm-gap-y-2">
  <div data-test="column-name" class="sm-flex sm-flex-col sm-gap-y-2">
    <span>Referenced List:</span>
    <ng-select
      formControlName="selectedList"
      appendTo="body"
      placeholder="Select linked list..."
      i18n-placeholder="Select linked list..."
      class="select-list"
      [clearable]="false"
      bindLabel="name"
      [loading]="(listsStatus | async).state === QueryStates.Loading"
      [class.ng-select-error]="
        (selectedList.errors?.required || selectedList.errors?.deleted || selectedList.errors?.empty) &&
        selectedList.touched
      "
    >
      <ng-template ng-loadingspinner-tmp>
        <div class="w-100 text-left">Loading...</div>
      </ng-template>
      <ng-option *ngFor="let item of selectableLists | keyvalue" [value]="item.value">
        <div class="sm-flex sm-flex-row sm-justify-between">
          <span>{{ item.value.name }}</span>
        </div>
      </ng-option>
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.name }}</span>
      </ng-template>
    </ng-select>
    <span
      data-test="removed-list-error"
      class="sm-text-default-red"
      *ngIf="selectedList.errors?.deleted"
      i18n
    >
      Referenced list has been deleted. Please select a new linked list.
    </span>

    <span
      data-test="no-lists-available-error"
      class="sm-text-default-red"
      *ngIf="selectedList.errors?.empty && !selectedList.errors?.deleted"
      i18n
    >
      Unable to detect linked list column in table.
    </span>

    <span
      data-test="input-required-error-list"
      class="sm-text-default-red"
      *ngIf="
        selectedList.touched &&
        selectedList.errors?.required &&
        !selectedList.errors?.empty &&
        !selectedList.errors?.deleted
      "
      i18n
    >
      Input required.
    </span>
  </div>
  <div class="sm-flex sm-flex-col sm-gap-y-2">
    <span>List Property:</span>
    <ng-select
      formControlName="selectedProperty"
      appendTo="body"
      placeholder="Select list property..."
      i18n-placeholder="Select list property..."
      class="select-list-properties"
      [clearable]="false"
      bindLabel="name"
      [items]="listProperties"
      [loading]="(propertiesStatus | async).state === QueryStates.Loading"
      [class.ng-select-error]="selectedProperty.errors && selectedProperty.touched"
    >
      <ng-template ng-loadingspinner-tmp>
        <div class="w-100 text-left">Loading...</div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <ng-container *ngIf="item.model !== GlobalItemModels.AttachmentSimple; else attachmentOption">
          <span>{{ item.name }}</span>
        </ng-container>
        <ng-template #attachmentOption>
          <cc-popover placement="right" container="body">
            <span i18n content>
              Attachment properties are not yet supported in List property table cells.
            </span>
            <div trigger class="sm-flex sm-cursor-not-allowed sm-flex-row sm-items-center sm-gap-x-1">
              <span>{{ item.name }}</span>
              <i class="cc-icon cc-icon-question" aria-hidden="true"></i>
            </div>
          </cc-popover>
        </ng-template>
      </ng-template>
    </ng-select>
    <span
      *ngIf="selectedProperty.errors?.deleted"
      data-test="removed-list-property-error"
      class="sm-text-default-red"
      i18n
    >
      The property you are referencing is now deleted.
    </span>

    <span
      *ngIf="selectedProperty.errors?.empty"
      data-test="no-properties-available-error"
      class="sm-text-xs sm-text-default-red"
      i18n
    >
      No properties found in linked list.
    </span>

    <span
      data-test="input-required-error-property"
      class="sm-text-default-red"
      *ngIf="
        selectedProperty.touched &&
        selectedProperty.errors?.required &&
        !selectedProperty.errors?.empty &&
        !selectedProperty.errors?.deleted
      "
      i18n
    >
      Input required.
    </span>

    <span
      data-test="selected-property-type"
      *ngIf="form.get('selectedPropertyType').value as propertyType"
      class="sm-text-xs sm-text-secondary-text"
    >
      List property type: {{ propertyType }}
    </span>

    <a [href]="articleLink" target="_blank" class="button-learn-more">
      <em class="cc-icon cc-icon-question" aria-hidden="true"></em>
      <span i18n>Learn more </span>
    </a>
  </div>
</div>
