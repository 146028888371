import { Component, ViewChild, Input } from '@angular/core';

import { SegmentService } from 'app/segment/segment.service';

import { YesNoFilter } from './yes-no-filter';
import { FormRegisterViewFilterBaseComponent } from '../form-register-view-filter-base.component';

@Component({
  selector: 'cc-yes-no-filter',
  templateUrl: './yes-no-filter.component.html',
  styleUrls: ['../form-register-view-filter.component.scss'],
})
export class YesNoFilterComponent extends FormRegisterViewFilterBaseComponent {
  get filterContextType(): string {
    return 'Yes/No';
  }

  @ViewChild('filterPopover')
  filter;

  @Input()
  model: YesNoFilter;

  constructor(protected readonly segmentService: SegmentService) {
    super(segmentService);
  }

  get hasFilterData() {
    return !!this.model.selectedValues.length;
  }

  getFilter() {
    return this.filter;
  }
}
