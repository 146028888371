<div class="comp-logic-container">
  <div class="divider-container">
    <span class="divider-line"></span>
    <div class="divider-text sm-flex sm-items-center sm-gap-1" i18n>
      AND
      <cc-rule-counter
        [total]="maxComplementaryRules"
        [count]="complementaryRule.complementaryRules?.length + 1"
        rule="OR"
        [isUpgradeAvailable]="isLimitUpgradable"
        data-test="or-counter"
      ></cc-rule-counter>
    </div>
    <span class="divider-line"></span>
  </div>
  <div class="dropdown-form-container">
    <cc-signature-logic-inputs
      class="sm-m-0"
      [complementaryRule]="complementaryRule"
      [canUpgradePlan]="canUpgradePlan"
      [inputRequiredMessage]="inputRequiredMessage"
      [disabled]="canUpgradePlan || maxRulesExceeded"
      [fields]="fields"
      (delete)="onDelete()"
      (validateLogicRules)="validateComplementaryLogicRules()"
    />

    <!-- OR NESTED RULES -->
    <div
      class="nested-complementary-logic-container"
      data-test="nested-complementary-logic-container"
      *ngIf="enableNestedComplementaryLogicFeature"
    >
      <div
        class="sm-flex sm-w-full sm-flex-col sm-gap-2"
        *ngFor="let nestedComplementaryRule of complementaryRule.complementaryRules || []"
      >
        <div class="divider-container">
          <span class="divider-line"></span>
          <span class="divider-text" i18n>OR</span>
          <span class="divider-line"></span>
        </div>

        <cc-signature-logic-inputs
          class="sm-m-0"
          [complementaryRule]="nestedComplementaryRule"
          [canUpgradePlan]="canUpgradePlan"
          [inputRequiredMessage]="inputRequiredMessage"
          [disabled]="canUpgradePlan || maxRulesExceeded"
          [fields]="fields"
          (delete)="onDeleteNestedRule(nestedComplementaryRule._id)"
          (validateLogicRules)="validateComplementaryLogicRules()"
        />
      </div>

      <div
        data-test="add-or-condition-container"
        (mouseenter)="onAddComplementaryLogicMouseEnter()"
        (mouseleave)="onAddComplementaryLogicMouseLeave()"
      >
        <div class="upgrade-banner" *ngIf="showUpgradeButton">
          <i class="cc-icon cc-icon-warning sm-text-default-dark-orange"></i>
          <span class="sm-font-normal sm-text-default-dark-orange" i18n>
            {{ companyPlanType | titlecase }} Plan limit reached.
            <span
              data-test="upgrade-plan-link"
              class="sm-cursor-pointer sm-text-default-blue sm-underline"
              (click)="onOpenUpgradeModal()"
              >Upgrade</span
            >
            to unlock up to 12 Conditional rules in a Conditional Approval Signature logic.
          </span>
        </div>
        <cc-popover
          *ngIf="!canUpgradePlan && !showUpgradeButton && !showLimitUpgradablePlanTypeWarning"
          placement="right"
          container="body"
          [disabled]="enableOrButton"
        >
          <span content i18n>
            Maximum of {{ maxComplementaryRules }} conditional rules can be created within an AND logic block.
          </span>

          <button
            trigger
            type="button"
            class="or-button"
            [disabled]="!enableOrButton"
            (click)="onAddComplementaryLogic()"
          >
            <i class="cc-icon cc-icon-plus"></i> OR
          </button>
        </cc-popover>
      </div>
    </div>

    <cc-banner
      *ngIf="showLimitUpgradablePlanTypeWarning"
      type="red-warning"
      icon="warning"
      alignment="center"
      width="fit-content"
      [bannerText]="limitUpgradableWarningBannerMessage"
      data-test="limit-upgradable-warning-banner"
    >
      <button
        class="sm-text-default-blue sm-underline"
        data-test="open-upgrade-modal-signature"
        type="button"
        (click)="onOpenUpgradeModal()"
      >
        <span i18n> Upgrade to unlock up to 12 conditional rules. </span>
      </button>
    </cc-banner>
  </div>
</div>
