import {
  Component,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';

import { FormFieldColumn } from './form-field-column/form-field-column';
import { FormFieldColumnHostDirective } from './form-field-column/form-field-column-host.directive';
import { FormFieldColumnComponent } from './form-field-column/form-field-column.component';

@Component({
  selector: 'cc-form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements AfterViewInit {
  @ViewChild(FormFieldColumnHostDirective)
  columnHost: FormFieldColumnHostDirective;

  @Input()
  column: FormFieldColumn;

  private readonly showMoreStep = 10;

  visibleRowsCount = this.showMoreStep;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.renderColumn();
  }

  renderColumn() {
    if (!this.columnHost) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.columnHost.column.renderer,
    );
    const { viewContainerRef } = this.columnHost;
    viewContainerRef.remove(); // need to remove all previous instances for proper re-rendering
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as FormFieldColumnComponent).column = this.columnHost.column;
    this.ref.detectChanges();
  }
}
