import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ObjectId } from 'bson';

import { FieldKinds, TableCellKinds } from '@site-mate/dashpivot-shared-library';

import { TemplateListPropertyService } from 'app/apps/template-list-property.service';
import { TableDisplayModes } from 'app/form-fields/table/template/table-display-modes';
import { FieldWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';

import { TableCellService } from './service/table-cell.service';
import { FormulaBarService } from '../formula-bar/formula-bar.service';

@Component({
  selector: 'cc-table-type-switch',
  templateUrl: './table-type-switch.component.html',
  styleUrls: ['./table-type-switch.component.scss'],
})
export class TableTypeSwitchComponent {
  private readonly fb = inject(FormBuilder);
  private readonly confirmService = inject(ConfirmService);
  private readonly templateListPropertyService = inject(TemplateListPropertyService);
  private readonly destroyRef = inject(DestroyRef);

  @Input() model: FieldWeb;
  @Input() hierarchy: string;
  @Output() changed = new EventEmitter();
  public form: FormGroup;
  tableTypes = [
    { type: FieldKinds.Table, title: 'Default' },
    { type: FieldKinds.PrefilledTable, title: 'Prefilled' },
  ];

  constructor(
    private readonly formulaBarService: FormulaBarService,
    private readonly tableCellService: TableCellService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({ tableType: [this.model.kind] });
    this.form
      .get('tableType')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((kind: FieldKinds) => this.onChange(kind));
  }

  openWarningModal(): Promise<boolean> {
    const options = { confirmButtonText: 'Update', confirmButtonColor: '#DC3545' };
    return this.confirmService.confirmDelete('prefilledTableNotSupportedOnLPTC', options);
  }

  private convertToTable(model: FieldWeb) {
    model.id = new ObjectId().toHexString();
    model.kind = FieldKinds.Table;
    model.displayRowsMode = TableDisplayModes.defaultMode().key;

    model.columns = this.cloneRowColumnsToColumns(model);
    delete model.rows;
  }

  private cloneRowColumnsToColumns(model: FieldWeb) {
    return model.rows[0].columns.map((rowColumn, index) => {
      const currentColumn = model.columns[index];
      return this.tableCellService.clonePrefilledToDefaultCell(currentColumn, rowColumn);
    });
  }

  private async convertToPrefilledTable(model: FieldWeb) {
    const LPTCCount = this.templateListPropertyService.countListPropertyTableColumns([model]);

    if (LPTCCount > 0) {
      try {
        await this.openWarningModal();
        model.columns = model.columns.filter((column) => column.kind !== TableCellKinds.ListProperty);
        this.templateListPropertyService.decrementListPropertyTableCellCounter(LPTCCount);
      } catch {
        this.form.get('tableType').setValue(FieldKinds.Table, { emitEvent: false });
        return;
      }
    }

    model.id = new ObjectId().toHexString();
    model.kind = FieldKinds.PrefilledTable;
    model.displayRowsMode = TableDisplayModes.defaultMode().key;
    model.rows = [{ columns: this.cloneColumnsToRowColumns(model) }];
  }

  private cloneColumnsToRowColumns(model: FieldWeb) {
    return model.columns.map((columnCell) => {
      return this.tableCellService.cloneDefaultToPrefilledCell(columnCell);
    });
  }

  onChange(kind: FieldKinds) {
    const fieldKindsFunctions = {
      [FieldKinds.Table]: () => this.convertToTable(this.model),
      [FieldKinds.PrefilledTable]: () => this.convertToPrefilledTable(this.model),
    };

    fieldKindsFunctions[kind]();

    this.formulaBarService.closeFormulaBar({ tableId: this.model.id });
    this.changed.emit();
  }
}
