import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';

import { FieldKinds, TableColumn } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

import { TableColumnTemplatePreviewHostDirective as HostDirective } from './table-column-template-preview-host.directive';
import { TableColumnTemplatePreviewFactory } from './table-column-template-preview.factory';

@Component({
  selector: 'cc-table-template-preview',
  templateUrl: './table-template-preview.component.html',
  styleUrls: ['./table-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTemplatePreviewComponent implements AfterViewInit {
  @ViewChildren(HostDirective) columnHosts: QueryList<HostDirective>;
  @Input() field: FieldWeb;
  readonly FieldKinds = FieldKinds;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.render();
  }

  private render() {
    if (!this.columnHosts) {
      return;
    }

    this.columnHosts.forEach((columnHost) => {
      const { viewContainerRef } = columnHost;
      const componentRef = viewContainerRef.createComponent(this.getComponentType(columnHost.column));
      componentRef.instance.column = columnHost.column;
    });

    this.cdr.detectChanges();
  }

  private getComponentType(column: TableColumn) {
    return TableColumnTemplatePreviewFactory.getRenderComponent(column);
  }
}
