import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from 'ng2-dragula';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormFieldAnalyticsFilterHostDirective } from './analytics/form-field-analytics-filter-host.directive';
import { FormFieldAnalyticsFilterComponent } from './analytics/form-field-analytics-filter.component';
import { FormFieldVersionDiffHostDirective } from './edit/diff/form-field-version-diff-host';
import { FormFieldVersionDiffComponent } from './edit/diff/form-field-version-diff.component';
import { GenericFormFieldVersionDiffComponent } from './edit/diff/generic-form-field-version-diff/generic-form-field-version-diff.component';
import { EditFormFieldHostDirective } from './edit/edit-form-field-host.directive';
import { FormFieldLabelComponent } from './edit/form-field-label/form-field-label.component';
import { FormFieldSettingsIndicatorsComponent } from './edit/form-field-label/form-field-settings-indicators/form-field-settings-indicators.component';
import { FormRegisterViewFilterHostDirective } from './register/filters/form-register-view-filter-host.directive';
import { FormRegisterViewMultiSelectFilterComponent } from './register/filters/multi-select/form-register-view-multi-select-filter.component';
import { FormRegisterViewMultiSelectListFilterComponent } from './register/filters/multi-select/form-register-view-multi-select-list-filter.component';
import { FormRegisterViewMultiSelectTableListFilterComponent } from './register/filters/multi-select/form-register-view-multi-select-table-list-filter.component';
import { TextFilterComponent } from './register/filters/text/text-filter.component';
import { FilterTriggerComponent } from './register/filters/trigger/filter-trigger.component';
import { YesNoFilterComponent } from './register/filters/yes-no/yes-no-filter.component';
import { FormFieldColumnHostDirective } from './register/form-field-column/form-field-column-host.directive';
import { FormFieldColumnComponent } from './register/form-field-column/form-field-column.component';
import { FormFieldHeaderComponent } from './register/form-field-header/form-field-header.component';
import { FormFieldComponent } from './register/form-field.component';
import { FormFieldTemplatePreviewHostDirective } from './template/preview/form-field-template-preview-host.directive';
import { FormFieldTemplatePreviewComponent } from './template/preview/form-field-template-preview.component';
import { GenericFormFieldTemplatePreviewComponent } from './template/preview/generic-form-field-template-preview/generic-form-field-template-preview.component';
import { TemplateFieldHostDirective } from './template/template-field/template-field-host.directive';
import { TemplateFieldComponent } from './template/template-field/template-field.component';
import { TemplateFieldActionsComponent } from './template/template-field-actions/template-field-actions.component';
import { TemplateFieldSettingsComponent } from './template/template-field-actions/template-field-settings/template-field-settings.component';
import { LogicRuleListComponent } from './template/template-logic/logic-rule-list.component';
import { LogicRuleComponent } from './template/template-logic/logic-rule.component';
import { FormFieldThumbnailHostDirective } from './thumbnail/form-field-thumbnail-host.directive';
import { FormFieldThumbnailComponent } from './thumbnail/form-field-thumbnail.component';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    FormsModule,
    NgbPopoverModule,
    NgSelectModule,
    SharedModule,
    MomentModule,
    UIComponentsModule,
  ],
  declarations: [
    EditFormFieldHostDirective,
    FormFieldComponent,
    FormFieldColumnComponent,
    FormFieldColumnHostDirective,
    FormFieldHeaderComponent,
    FormRegisterViewMultiSelectFilterComponent,
    FormRegisterViewMultiSelectListFilterComponent,
    FormRegisterViewMultiSelectTableListFilterComponent,
    FormRegisterViewFilterHostDirective,
    TextFilterComponent,
    FormFieldThumbnailComponent,
    FormFieldThumbnailHostDirective,
    FormFieldAnalyticsFilterComponent,
    FormFieldAnalyticsFilterHostDirective,
    FormFieldTemplatePreviewComponent,
    FormFieldTemplatePreviewHostDirective,
    GenericFormFieldTemplatePreviewComponent,
    LogicRuleComponent,
    LogicRuleListComponent,
    TemplateFieldComponent,
    TemplateFieldActionsComponent,
    TemplateFieldSettingsComponent,
    TemplateFieldHostDirective,
    GenericFormFieldVersionDiffComponent,
    FormFieldVersionDiffComponent,
    FormFieldVersionDiffHostDirective,
    FormFieldSettingsIndicatorsComponent,
    FormFieldLabelComponent,
    YesNoFilterComponent,
    FilterTriggerComponent,
  ],
  exports: [
    EditFormFieldHostDirective,
    FormFieldComponent,
    FormFieldHeaderComponent,
    FormFieldThumbnailComponent,
    FormFieldAnalyticsFilterComponent,
    FormFieldTemplatePreviewComponent,
    GenericFormFieldTemplatePreviewComponent,
    LogicRuleComponent,
    LogicRuleListComponent,
    TemplateFieldComponent,
    TemplateFieldActionsComponent,
    TemplateFieldSettingsComponent,
    FormFieldVersionDiffComponent,
    FormFieldSettingsIndicatorsComponent,
    FormFieldLabelComponent,
    YesNoFilterComponent,
    FilterTriggerComponent,
  ],
})
export class FormFieldsCommonModule {}
