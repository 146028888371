import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

import { CategoryTemplatePreviewComponent } from './category/template/preview/category-template-preview.component';
import { GenericFormFieldTemplatePreviewComponent } from './common/template/preview/generic-form-field-template-preview/generic-form-field-template-preview.component';
import { DateTemplatePreviewComponent } from './date/template/preview/date-template-preview.component';
import { MultiLineInputTemplatePreviewComponent } from './multi-line-input/template/preview/multi-line-input-template-preview.component';
import { PageBreakTemplatePreviewComponent } from './page-break/template/page-break-template-preview/page-break-template-preview.component';
import { PersonTemplatePreviewComponent } from './person/template/preview/person-template-preview.component';
import { PhotoTemplatePreviewComponent } from './photo/template/preview/photo-template-preview.component';
import { PrefilledTextTemplatePreviewComponent } from './prefilled-text/template/preview/prefilled-text-template-preview.component';
import { SignatureTemplatePreviewComponent } from './signature/template/preview/signature-template-preview.component';
import { SignatureArrayTemplatePreviewComponent } from './signature-array/template/preview/signature-array-template-preview.component';
import { SignonTableTemplatePreviewComponent } from './signon-table/template/preview/signon-table-template-preview.component';
import { SingleLineInputTemplatePreviewComponent } from './single-line-input/template/preview/single-line-input-template-preview.component';
import { SketchTemplatePreviewComponent } from './sketch/template/preview/sketch-template-preview.component';
import { TableTemplatePreviewComponent } from './table/template/preview/table-template-preview.component';
import { YesNoTemplatePreviewComponent } from './yes-no/template/preview/yes-no-template-preview.component';

export class FormFieldTemplatePreviewFactory {
  private static resolver = {
    [FieldKinds.SingleLineInput]: SingleLineInputTemplatePreviewComponent,
    [FieldKinds.MultiLineInput]: MultiLineInputTemplatePreviewComponent,
    [FieldKinds.PreFilledText]: PrefilledTextTemplatePreviewComponent,
    [FieldKinds.Date]: DateTemplatePreviewComponent,
    [FieldKinds.Category]: CategoryTemplatePreviewComponent,
    [FieldKinds.Photo]: PhotoTemplatePreviewComponent,
    [FieldKinds.Signature]: SignatureTemplatePreviewComponent,
    [FieldKinds.SignatureArray]: SignatureArrayTemplatePreviewComponent,
    [FieldKinds.Sketch]: SketchTemplatePreviewComponent,
    [FieldKinds.Table]: TableTemplatePreviewComponent,
    [FieldKinds.PrefilledTable]: TableTemplatePreviewComponent,
    [FieldKinds.SignonTable]: SignonTableTemplatePreviewComponent,
    [FieldKinds.YesNoCheckbox]: YesNoTemplatePreviewComponent,
    [FieldKinds.PageBreak]: PageBreakTemplatePreviewComponent,
    [FieldKinds.Person]: PersonTemplatePreviewComponent,
  };

  private static defaultRenderer = GenericFormFieldTemplatePreviewComponent;

  static getRenderComponent(field: FieldWeb) {
    const Field = this.resolver[field.kind] || this.defaultRenderer;
    return Field;
  }
}
