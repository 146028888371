import { Component, Input } from '@angular/core';

import { SegmentService } from 'app/segment/segment.service';

import { TextFilter } from './text-filter';
import { FormRegisterViewFilterBaseComponent } from '../form-register-view-filter-base.component';

@Component({
  selector: 'cc-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['../form-register-view-filter.component.scss'],
})
export class TextFilterComponent extends FormRegisterViewFilterBaseComponent {
  get filterContextType(): string {
    return 'Text';
  }
  @Input() model: TextFilter;

  constructor(protected readonly segmentService: SegmentService) {
    super(segmentService);
  }

  get hasFilterData() {
    return !!this.model.value;
  }
}
