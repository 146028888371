import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectId } from 'bson';

import {
  ComplementaryLogicOperators,
  ComplementarySignatureRuleLogic,
  IField,
  LogicOperators,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { environment } from 'environments/environment';

import { LimitUpgradablePlans } from '../signature-logic-rule-helpers';
import { SignatureLogicRuleService } from '../signature-logic-rule.service';

@Component({
  selector: 'cc-complementary-logic',
  templateUrl: './complementary-logic.component.html',
  styleUrls: ['./complementary-logic.component.scss', '../signature-logic-rule.component.scss'],
})
export class ComplementaryLogicComponent {
  readonly enableNestedComplementaryLogicFeature =
    environment.featureToggles.nestedComplementarySignatureLogic;

  @Input() complementaryRule: ComplementarySignatureRuleLogic;
  @Input() canUpgradePlan: boolean;
  @Input() inputRequiredMessage: string;
  @Input() maxComplementaryRules: number;
  @Input() companyPlanType: CompanyPlanTypes;
  @Input() maxRulesExceeded?: boolean;
  @Input() fields: IField[];

  @Output() delete = new EventEmitter<string>();
  @Output() validateLogicRules = new EventEmitter<void>();
  @Output() onNestedRuleDelete = new EventEmitter<void>();

  enableOrButton: boolean;
  popoverMessage: string;
  showUpgradeButton: boolean;
  showLimitUpgradablePlanTypeWarning: boolean;
  limitUpgradableWarningBannerMessage: string;
  maxComplementaryRulesORLogic: number;
  isLimitUpgradable: boolean;

  readonly complementaryLogicOperators = LogicOperators;

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
    private readonly signatureLogicRuleService: SignatureLogicRuleService,
  ) {}

  ngOnInit() {
    this.popoverMessage = this.i18nService.getMessage('nestedRuleMaxSize');
    this.maxComplementaryRulesORLogic = this.maxComplementaryRules - 1;

    this.isLimitUpgradable = LimitUpgradablePlans.includes(this.companyPlanType);

    if (this.isLimitUpgradable) {
      const planKey = this.companyPlanType.toLowerCase();
      const key = `${planKey}PlanExceedORMaxRulesBannerWarning`;
      this.limitUpgradableWarningBannerMessage = this.i18nService.getMessage(key);
    }

    this.updateOrButtonState();
  }

  validateComplementaryLogicRules() {
    this.validateLogicRules.emit();
  }

  onDelete(): void {
    this.delete.emit(this.complementaryRule._id);
    this.updateOrButtonState();
  }

  async onDeleteNestedRule(id: string) {
    try {
      await this.confirmService.confirmDelete('deleteCondition', { confirmButtonText: 'Delete' });
      this.complementaryRule.complementaryRules = this.complementaryRule.complementaryRules.filter(
        (rule) => rule._id !== id,
      );
      if (this.complementaryRule.complementaryRules.length === 0) {
        delete this.complementaryRule.complementaryOperator;
        delete this.complementaryRule.complementaryRules;
      }

      this.onNestedRuleDelete.emit();
      this.updateOrButtonState();
    } catch {
      // no-op
    }
  }

  onAddComplementaryLogic() {
    this.complementaryRule.complementaryOperator = ComplementaryLogicOperators.OR;
    this.complementaryRule.complementaryRules = this.complementaryRule.complementaryRules || [];

    this.complementaryRule.complementaryRules.push({
      _id: new ObjectId().toHexString(),
    } as ComplementarySignatureRuleLogic);

    this.updateOrButtonState();
  }

  private canAddORCondition(): boolean {
    const nestedRules = this.complementaryRule.complementaryRules;
    return !nestedRules || nestedRules.length < this.maxComplementaryRulesORLogic;
  }

  onAddComplementaryLogicMouseEnter() {
    const hasReachedMaxNestedRules =
      this.complementaryRule.complementaryRules?.length >= this.maxComplementaryRulesORLogic;
    this.showUpgradeButton = LimitUpgradablePlans.includes(this.companyPlanType) && hasReachedMaxNestedRules;
  }

  onAddComplementaryLogicMouseLeave() {
    this.showUpgradeButton = false;
  }

  onOpenUpgradeModal() {
    this.signatureLogicRuleService.openUpgradeModal();
  }

  updateOrButtonState() {
    this.enableOrButton = this.canAddORCondition();
    this.updateProPlanState();
  }

  private updateProPlanState() {
    const hasReachedMaxComplementaryRuleState =
      this.complementaryRule.complementaryRules?.length > this.maxComplementaryRulesORLogic;

    this.showLimitUpgradablePlanTypeWarning =
      hasReachedMaxComplementaryRuleState && LimitUpgradablePlans.includes(this.companyPlanType);
  }
}
