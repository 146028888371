<span class="font-weight-bold">List: </span>
<div class="sm-whitespace-nowrap">
  <span i18n>List item source: </span>
  <span *ngIf="column.listSource === CategorySourceTypes.Manual" i18n>Manual typed</span>
  <span *ngIf="column.listSource === CategorySourceTypes.List" i18n>Link to a List</span>
</div>

<span class="sm-whitespace-nowrap" *ngIf="column.listSource === CategorySourceTypes.List" i18n>
  List reference: {{ column.reference }}
</span>

<div class="app-category-item-container">
  <cc-app-category-item
    *ngFor="let row of column.manualListItems"
    [row]="row"
    [showControl]="false"
    [editingRow]="false"
    [displayColorPicker]="false"
  ></cc-app-category-item>
</div>
