import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  templateUrl: './signature-column-template-preview.component.html',
  styleUrls: ['./signature-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
