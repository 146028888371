<ng-container *ngFor="let rule of field?.signatureRules; let i = index">
  <cc-signature-logic-rule
    [rule]="rule"
    [canUpgradePlan]="canUpgradePlan"
    [companyPlanType]="companyPlanType"
    [fields]="fields"
    (validate)="validateLogicRules()"
    (delete)="onDeleteRule($event)"
  ></cc-signature-logic-rule>
</ng-container>
