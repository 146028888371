import { Injectable, Type } from '@angular/core';

import { Field, FieldKinds } from '@site-mate/dashpivot-shared-library';

import { CategoryTemplateComponent } from './category/template/category-template.component';
import { TemplateFieldBaseComponent } from './common/template/template-field-base.component';
import { DateTemplateComponent } from './date/template/date-template.component';
import { HeaderFooterFieldItemComponent } from './header-footer/header-footer-field-item/header-footer-field-item.component';
import { PageBreakTemplateComponent } from './page-break/template/page-break-template.component';
import { PersonTemplateComponent } from './person/template/person-template.component';
import { PhotoTemplateComponent } from './photo/template/photo-template.component';
import { AppSignatureComponent } from './signature/template/app-signature.component';
import { SignonTableTemplateComponent } from './signon-table/template/signon-table-template.component';
import { AppTextComponent } from './single-line-input/template/app-text.component';
import { SketchTemplateComponent } from './sketch/template/sketch-template.component';
import { TableTemplateComponent } from './table/template/app-table.component';
import { YesNoButtonTemplateComponent } from './yes-no/template/yes-no-button-template.component';

@Injectable()
export class FormFieldTemplateFactory {
  private static readonly resolver = {
    [FieldKinds.SingleLineInput]: AppTextComponent,
    [FieldKinds.MultiLineInput]: AppTextComponent,
    [FieldKinds.PreFilledText]: AppTextComponent,
    [FieldKinds.Signature]: AppSignatureComponent,
    [FieldKinds.SignatureArray]: AppSignatureComponent,
    [FieldKinds.Table]: TableTemplateComponent,
    [FieldKinds.PrefilledTable]: TableTemplateComponent,
    [FieldKinds.SignonTable]: SignonTableTemplateComponent,
    [FieldKinds.Date]: DateTemplateComponent,
    [FieldKinds.Photo]: PhotoTemplateComponent,
    [FieldKinds.Category]: CategoryTemplateComponent,
    [FieldKinds.Sketch]: SketchTemplateComponent,
    [FieldKinds.YesNoCheckbox]: YesNoButtonTemplateComponent,
    [FieldKinds.PageBreak]: PageBreakTemplateComponent,
    [FieldKinds.Person]: PersonTemplateComponent,
  };

  static getRenderComponent(field: Field): Type<TemplateFieldBaseComponent> {
    return this.resolver[field.kind];
  }

  static getHeaderFooterRenderComponent(): Type<TemplateFieldBaseComponent> {
    return HeaderFooterFieldItemComponent;
  }
}
