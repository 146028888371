import { Type } from '@angular/core';

import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

import { FormCategoryDiffComponent } from './category/edit/diff/form-category-diff.component';
import { GenericFormFieldVersionDiffComponent } from './common/edit/diff/generic-form-field-version-diff/generic-form-field-version-diff.component';
import { FormDateDiffComponent } from './date/edit/diff/form-date-diff.component';
import { FormMultiLineInputDiffComponent } from './multi-line-input/edit/diff/form-multi-line-input-diff.component';
import { FormPageBreakDiffComponent } from './page-break/edit/diff/form-page-break-diff.component';
import { FormPersonDiffComponent } from './person/edit/diff/form-person-diff.component';
import { FormPhotoDiffComponent } from './photo/edit/diff/form-photo-diff.component';
import { FormSignatureDiffComponent } from './signature/edit/diff/form-signature-diff.component';
import { FormSignatureListDiffComponent } from './signature-array/edit/diff/form-signature-list-diff.component';
import { FormSignonTableDiffComponent } from './signon-table/edit/diff/form-signon-table-diff.component';
import { FormSingleLineInputDiffComponent } from './single-line-input/edit/diff/form-single-line-input-diff.component';
import { FormSketchDiffComponent } from './sketch/edit/diff/form-sketch-diff.component';
import { FormTableDiffComponent } from './table/edit/diff/form-table-diff.component';
import { FormYesNoDiffComponent } from './yes-no/edit/diff/form-yes-no-diff.component';

export class FormFieldVersionDiffFactory {
  private static componentMap = new Map<FieldKinds, Type<GenericFormFieldVersionDiffComponent>>([
    [FieldKinds.Category, FormCategoryDiffComponent],
    [FieldKinds.Date, FormDateDiffComponent],
    [FieldKinds.MultiLineInput, FormMultiLineInputDiffComponent],
    [FieldKinds.PageBreak, FormPageBreakDiffComponent],
    [FieldKinds.PrefilledTable, FormTableDiffComponent],
    [FieldKinds.Person, FormPersonDiffComponent],
    [FieldKinds.Photo, FormPhotoDiffComponent],
    [FieldKinds.Signature, FormSignatureDiffComponent],
    [FieldKinds.SignatureArray, FormSignatureListDiffComponent],
    [FieldKinds.SignonTable, FormSignonTableDiffComponent],
    [FieldKinds.SingleLineInput, FormSingleLineInputDiffComponent],
    [FieldKinds.Sketch, FormSketchDiffComponent],
    [FieldKinds.Table, FormTableDiffComponent],
    [FieldKinds.YesNoCheckbox, FormYesNoDiffComponent],
  ]);

  static getRenderComponent(field: FieldWeb) {
    return this.componentMap.get(field.kind) ?? GenericFormFieldVersionDiffComponent;
  }
}
