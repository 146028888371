import { Component } from '@angular/core';

import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';

import { SketchUtils } from '../sketch-utils';

@Component({
  selector: 'cc-app-sketch',
  templateUrl: 'sketch-template.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', 'sketch-template.component.scss'],
})
export class SketchTemplateComponent extends TemplateFieldBaseComponent {
  getBackgroundUrl() {
    return SketchUtils.getSketchpadBackground(this.model);
  }
}
