<div class="font-weight-bold mb-3">{{ field.content }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required:</span> <input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>

<div class="app-control-control d-flex">
  <div class="">
    <div class="form-group">
      {{ field.description }}
    </div>

    <div *ngIf="field.hasTextInput" class="row">
      <div class="col">
        {{ field.textInputLabel }}
      </div>
    </div>
  </div>
  <div class="ml-3">
    <div data-toggle="buttons" class="btn-group btn-group-toggle" *ngIf="!field.hasNaButton">
      <label class="default-label disabled btn btn-secondary btn-sm mb-0">
        <input type="radio" /><span i18n>Yes</span>
      </label>
      <label class="default-label disabled btn btn-secondary btn-sm mb-0">
        <input type="radio" /><span i18n>No</span>
      </label>
    </div>
    <div data-toggle="buttons" class="btn-group btn-group-toggle" *ngIf="field.hasNaButton">
      <label class="default-label disabled btn btn-secondary btn-sm mb-0">
        <input type="radio" /><span i18n>Yes</span>
      </label>
      <label class="default-label disabled btn btn-secondary btn-sm mb-0">
        <input type="radio" /><span i18n>No</span>
      </label>
      <label class="default-label disabled btn btn-secondary btn-sm mb-0">
        <input type="radio" /><span i18n>NA</span>
      </label>
    </div>
  </div>
</div>
