<div class="form-diff-col">
  <div class="form-diff-row sm-bg-grey-1 sm-py-1 sm-text-center">
    <div class="form-diff-col">
      <strong>{{ previous.description }}</strong>
    </div>
  </div>
  <div class="items form-diff-row sm-py-1">
    <div class="column form-diff-col">
      <ng-template ccFormFieldVersionDiffHost [field]="previous"></ng-template>
    </div>
    <div class="column form-diff-col">
      <ng-template ccFormFieldVersionDiffHost [field]="next"></ng-template>
    </div>
  </div>
</div>
