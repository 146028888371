<cc-filter-trigger
  [filterTemplate]="filterTemplate"
  (click)="toggleFilter()"
  (keydown)="toggleFilter()"
  [active]="status"
></cc-filter-trigger>

<ng-template #filterTemplate>
  <form
    (mouseup)="$event.stopPropagation()"
    autocomplete="off"
    class="reg-view-filter-container"
    (ngSubmit)="applyFilter()"
    #filterForm="ngForm"
    data-test="filter-template"
  >
    <div class="form-group">
      <input
        name="filterValue"
        type="text"
        class="form-control"
        placeholder="Search..."
        [(ngModel)]="model.value"
        ccFocus
      />
    </div>

    <hr class="hr" />

    <div class="filter-button-container">
      <button
        (click)="resetFilter()"
        type="button"
        class="btn btn-link btn-sm reg-view-filter-button"
        [disabled]="!status"
        data-test="reset-filter-button"
      >
        <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
        <span i18n>Remove Filter</span>
      </button>
      <button
        type="submit"
        class="btn btn-link btn-sm reg-view-filter-button"
        [disabled]="!hasFilterData"
        data-test="apply-filter-button"
      >
        <i class="cc-icon cc-icon-filter" aria-hidden="true"></i>
        <span i18n>Apply Filter</span>
      </button>
    </div>
  </form>
</ng-template>
