<div data-test="form-signature-list-diff" class="item">
  <div class="mb-3" *ngFor="let signature of field.signatures">
    <div>
      <img [src]="signature.signatureUrl" [alt]="signature.fullName" />
    </div>
    <div>{{ signature.fullName }}</div>
    <div *ngIf="signature.isManualSignature">{{ signature.companyName }}</div>
    <div>{{ signature.signedOn | amDateFormat: 'lll' }}</div>
  </div>
</div>
