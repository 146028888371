import { Injectable } from '@angular/core';
import { ObjectId } from 'bson';
import { cloneDeep } from 'lodash-es';
import { filter, map } from 'rxjs';

import { TableCellKinds, TableColumn } from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { Positions } from 'app/shared/model/positions.enum';
import { FormulasVersion } from 'app/shared/service/formula-engine.service';
import { FormulaV2TemplateEngineService } from 'app/shared/service/formula-v2-template-engine/formula-v2-template-engine.service';
import { HotFormulaParserEngineService } from 'app/shared/service/hot-formula-parser-engine.service';

@Injectable({ providedIn: 'root' })
export class AppTableRowService {
  isFormulaV2: boolean;
  constructor(
    private readonly formulaV2TemplateEngineService: FormulaV2TemplateEngineService,
    private readonly hotFormulaParserEngineService: HotFormulaParserEngineService,
    private readonly templatesService: TemplatesService,
  ) {
    this.templatesService.currentOpenTemplateFormulasVersion
      .pipe(
        filter(Boolean),
        map((version) => (version as FormulasVersion) === FormulasVersion.v2),
      )
      .subscribe((isFormulaV2) => {
        this.isFormulaV2 = isFormulaV2;
      });
  }

  addRow(rowIndex: number, model: FieldWeb) {
    this.addNewRowToTable(rowIndex, model);

    if (this.isFormulaV2) {
      this.formulaV2TemplateEngineService.addRow(model, rowIndex);
    }
  }

  private addNewRowToTable(rowIndex: number, model: FieldWeb) {
    const cloneIndex = rowIndex - 1;
    const newRow = rowIndex === 0 ? this.generateNewRow(model.columns) : cloneDeep(model.rows[cloneIndex]);

    this.generateId(newRow);
    newRow.columns.forEach(this.generateId);

    model.rows.splice(rowIndex, 0, newRow);
    return newRow;
  }

  private generateId(element: any) {
    element.id = new ObjectId().toHexString();
    element._id = element.id;
  }

  private generateNewRow(tableColumns: TableColumn[]) {
    const columns = tableColumns.map(() => {
      return { kind: TableCellKinds.Text };
    });
    return { columns };
  }

  removeRow(rowIndex: number, model: FieldWeb, isFormulaV2: boolean) {
    model.rows.splice(rowIndex, 1);

    if (isFormulaV2) {
      this.formulaV2TemplateEngineService.removeRow(model, rowIndex);
    }
  }

  moveRowUp(index: number, model: FieldWeb, isFormulaV2: boolean) {
    const row = model.rows.splice(index, 1)[0];
    model.rows.splice(index - 1, 0, row);

    this.updateFormulasOnRowMove(Positions.Up, index, model, isFormulaV2);
  }

  moveRowDown(index: number, model: FieldWeb, isFormulaV2: boolean) {
    const row = model.rows.splice(index, 1)[0];
    model.rows.splice(index + 1, 0, row);

    this.updateFormulasOnRowMove(Positions.Down, index, model, isFormulaV2);
  }

  private updateFormulasOnRowMove(
    position: Positions.Up | Positions.Down,
    index: number,
    model: FieldWeb,
    isFormulaV2: boolean,
  ) {
    if (isFormulaV2) {
      this.formulaV2TemplateEngineService.updateFormulasOnRowMove(model, position, index);
    } else {
      this.hotFormulaParserEngineService.updateFormulasOnRowMove(model, position, index);
    }
  }

  extendFirstCellTypeToAllRows(columnIndex: number, model: FieldWeb) {
    const [firstRow, ...otherRows] = model.rows;
    const column = firstRow?.columns[columnIndex];

    otherRows.forEach((row) => {
      const newColumn = cloneDeep(column);

      row.columns[columnIndex] = {
        ...newColumn,
        id: row.columns[columnIndex].id,
        _id: row.columns[columnIndex]._id,
      };
    });
  }
}
