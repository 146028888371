<div class="font-weight-bold">{{ field.description }}</div>

<table>
  <thead>
    <tr>
      <th *ngFor="let column of field.columns">{{ column.heading }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="field.kind === FieldKinds.Table">
      <td *ngFor="let column of field.columns">
        <ng-template ccTableColumnTemplatePreviewHost [column]="column"></ng-template>
      </td>
    </tr>
    <tr *ngFor="let row of field.rows" class="table-row-prefilled">
      <td *ngFor="let column of row.columns">
        <ng-template ccTableColumnTemplatePreviewHost [column]="column"></ng-template>
      </td>
    </tr>
  </tbody>
</table>
