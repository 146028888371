<div data-test="form-photo-diff">
  <cc-form-photo-list
    [isLandscapeMode]="isLandscapeMode"
    displayThumbnailSize="true"
    [item]="field"
    [readOnly]="true"
    [photos]="field.photos"
  >
  </cc-form-photo-list>
</div>
