import { Filter } from '../filter';

export class MultiSelectFilter extends Filter {
  selectedValues = [];
  options = [];
  fieldId: string;
  templateId: string;
  columnId: string;

  constructor(renderer, fieldId?: string, templateId?: string, columnId?: string) {
    super(renderer);
    this.fieldId = fieldId;
    this.templateId = templateId;
    this.columnId = columnId;
  }

  queryParams() {
    if (this.selectedValues.length) {
      return this.selectedValues;
    }

    return null;
  }

  get status() {
    return this.selectedValues.length;
  }

  set(params) {
    if (!params) {
      this.reset();
      return;
    }

    this.selectedValues = params;
  }

  reset() {
    this.selectedValues = [];
  }
}
