/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { flatMap } from 'lodash-es';

import {
  CellValue,
  DetailedCellError,
  FieldKinds,
  SupportedFormulas,
  TableCellKinds,
  TableColumn,
  SimpleCellAddress,
  SimpleCellRange,
} from '@site-mate/dashpivot-shared-library';

import {
  CELL_REFEFERENCE_WITH_LEFT_SIDE_RANGE_OPERATOR,
  FULL_CELL_REFERENCE,
  SHEET_NAME_WITHOUT_EXCLAMATION,
} from './cell-references-pattern';
import { FormulaErrorTypes, FormulasErrors } from './formula-error-messages';
import { FieldWeb } from '../../model/item.model';
import { FormulaV2TemplateEngineService } from '../formula-v2-template-engine/formula-v2-template-engine.service';

@Injectable({ providedIn: 'root' })
export class FormulaV2ValidationService {
  private readonly allFormulas: Set<string>;
  private ignoredErrors: Map<string, { address: SimpleCellAddress; formula: string }> = new Map();

  private readonly byPassCases = [
    { value: FormulaErrorTypes.NA, message: 'Value not found.', formula: 'HLOOKUP' },
    { value: FormulaErrorTypes.NA, message: 'Value not found.', formula: 'VLOOKUP' },
    { value: FormulaErrorTypes.NUM, message: 'Value too large.', formula: 'INDEX' },
    { value: FormulaErrorTypes.NA, message: 'Value not found.', formula: 'MATCH' },
  ];

  constructor(private readonly formulaV2TemplateEngine: FormulaV2TemplateEngineService) {
    this.allFormulas = new Set(SupportedFormulas);
  }

  private getCellAddressKey = (ca: SimpleCellAddress) => `${ca.sheet}|${ca.row}|${ca.col}`;

  verifyTables(tables: FieldWeb[]) {
    tables.forEach((item) => {
      this.calculateAndVerifyTable(item);
    });
  }

  calculateAndVerifyTable(table: FieldWeb) {
    this.createSheetIfDoesNotExist(table.tableReference);
    const rawTable = this.formulaV2TemplateEngine.calculateSheetContent(table);
    const parsedTable = this.formulaV2TemplateEngine.getParsedValues(table.tableReference);

    this.verifySingleTable(parsedTable, table, rawTable);
  }

  verifySingleTable(parsedTable: CellValue[][], tableField: FieldWeb, rawTable: string[][]) {
    if (tableField.kind === FieldKinds.PrefilledTable) {
      this.verifyPrefilledTable(parsedTable, tableField, rawTable);
    }

    if (tableField.kind === FieldKinds.Table) {
      this.verifyDefaultTable(parsedTable, tableField, rawTable);
    }
  }

  createSheetIfDoesNotExist(tableReference: string) {
    if (!this.formulaV2TemplateEngine.doesSheetExist(tableReference)) {
      this.formulaV2TemplateEngine.createSheet(tableReference);
    }
  }

  private verifyPrefilledTable(calculatedTable: CellValue[][], tableField: FieldWeb, rawTable: string[][]) {
    rawTable.forEach((row, rowIndex: number) => {
      row.forEach((_cell, colIndex: number) => {
        const field = tableField.rows[rowIndex]?.columns[colIndex] as TableColumn;
        const parsedCell = calculatedTable[rowIndex]?.[colIndex];
        this.checkCellError(field, parsedCell, tableField.tableReference, {
          sheet: this.formulaV2TemplateEngine.getSheetId(tableField.tableReference),
          row: rowIndex,
          col: colIndex,
        });
      });
    });
  }

  private verifyDefaultTable(calculatedTable: CellValue[][], tableField: FieldWeb, rawTable: string[][]) {
    const uniqueRow = rawTable[0];
    uniqueRow.forEach((_cell, colIndex: number) => {
      const field = tableField.columns[colIndex];
      const parsedCell = calculatedTable[0]?.[colIndex];
      this.checkCellError(field, parsedCell, tableField.tableReference, {
        sheet: this.formulaV2TemplateEngine.getSheetId(tableField.tableReference),
        row: 0,
        col: colIndex,
      });
    });
  }

  private checkCellError(
    field: TableColumn,
    cell: CellValue,
    tableReference: string,
    cellAddress?: SimpleCellAddress,
  ) {
    if (!this.isFormulaKind(field)) {
      return;
    }

    this.checkIfFormulaHasBeenCalculated(field, cell);

    if (!this.hasFormula(field)) {
      return;
    }

    if (!this.formulaStartsWithEqualSign(field)) {
      return;
    }

    if (this.hasFormulaWithNestedParenthesis(field)) {
      return;
    }

    if (this.hasFormulaError(field, cell as DetailedCellError, cellAddress)) {
      return;
    }

    this.checkInvalidReferenceCells(field, tableReference);
  }

  private checkIfFormulaHasBeenCalculated(field: TableColumn, cell: CellValue): void {
    if (typeof cell === 'number') {
      field.formulaError = '';
    }
  }

  private isFormulaKind(field: TableColumn): boolean {
    return field.kind === TableCellKinds.Formula;
  }

  private hasFormula(field: TableColumn): boolean {
    const formula = field?.formula || '';
    if (!formula) {
      field.formulaError = FormulasErrors.get(FormulaErrorTypes.EMPTY).message;
    }
    return !!formula;
  }

  private formulaStartsWithEqualSign(field: TableColumn): boolean {
    let hasError = true;
    const { formula } = field;
    if (formula && !formula.startsWith('=')) {
      field.formulaError = FormulasErrors.get(FormulaErrorTypes.NOTEQUALSIGN).message;
      hasError = false;
    }

    return hasError;
  }

  private hasFormulaWithNestedParenthesis(field: TableColumn): boolean {
    const hasError = this.parenthesisDepth(field.formula) > 6;

    if (hasError) {
      field.formulaError = FormulasErrors.get(FormulaErrorTypes.NESTED).message;
    }
    return hasError;
  }

  private hasFormulaError(
    field: TableColumn,
    cell: DetailedCellError,
    cellAddress?: SimpleCellAddress,
  ): boolean {
    let skipped = false;

    if (!this.isCellError(cell)) {
      return false;
    }

    const previouslyIgnored = this.ignoredErrors.get(this.getCellAddressKey(cellAddress));
    if (previouslyIgnored && field.formula === previouslyIgnored.formula) {
      return false;
    }

    if (previouslyIgnored && field.formula !== previouslyIgnored.formula) {
      this.ignoredErrors.clear();
    }

    skipped = this.checkCellPrecedents(cellAddress, field);

    if (skipped) {
      return false;
    }

    if (this.hasByPassErrors(field, cell)) {
      this.ignoredErrors.set(this.getCellAddressKey(cellAddress), {
        address: cellAddress,
        formula: field.formula,
      });
      (cell as any).value = this.formulaV2TemplateEngine.getMockedFieldValue(field);
      field.formulaError = '';
      return false;
    }

    const { value } = cell;
    field.formulaError = FormulasErrors.get(value as FormulaErrorTypes).message || value;

    return !!cell?.value;
  }

  private isCellError = (cell: DetailedCellError) => {
    return cell?.value && cell?.type;
  };

  private addAddressToIgnoredErrors(cellAddress: SimpleCellAddress, field: TableColumn) {
    this.ignoredErrors.set(this.getCellAddressKey(cellAddress), {
      address: cellAddress,
      formula: field.formula,
    });
    field.formulaError = '';
  }

  private hasByPassErrors(field: TableColumn, cell: DetailedCellError): boolean {
    const isDivisionByZero = (cell.value as FormulaErrorTypes) === FormulaErrorTypes.DIV0;

    if (isDivisionByZero) {
      return true;
    }

    return this.byPassCases.some(({ value, message, formula }) => {
      const isSameMessage = cell.message === message;
      const isSameValue = (cell.value as FormulaErrorTypes) === value;
      const hasByPassFormula = field.formula?.toUpperCase().includes(`${formula}(`);

      return isSameMessage && isSameValue && hasByPassFormula;
    });
  }

  private checkCellPrecedents(cellAddress: SimpleCellAddress, field: TableColumn) {
    let skipped = false;
    const precedents = this.formulaV2TemplateEngine.getCellPrecedents(cellAddress);

    precedents.forEach((precedent) => {
      if (skipped) return;

      const checkAndSkip = ({ address }) => {
        if (skipped) return;
        const isEqual =
          'col' in precedent
            ? this.areCellAddressesEqual(address, precedent)
            : this.isCellWithinRange(address, precedent);

        if (isEqual) {
          this.addAddressToIgnoredErrors(address, field);
          skipped = true;
        }
      };

      this.ignoredErrors.forEach(checkAndSkip);
    });

    return skipped;
  }

  checkInvalidReferenceCells(field: TableColumn, tableReference: string): void {
    const cleanedFormula = this.removeStringsFromFormula(field?.formula);
    if (this.hasInvalidSheetNames(field, cleanedFormula)) {
      return;
    }

    const allMatches = Array.from(
      new Set([
        ...this.getDefaultCellReference(cleanedFormula),
        ...this.getRangeStartCellReferences(cleanedFormula),
        ...this.getCellReferenceWithoutTables(cleanedFormula),
      ]),
    ).filter(this.removeFormulaFunctions.bind(this));

    const hasError = allMatches.some((cellAddress) =>
      this.formulaV2TemplateEngine.isBlankCell(cellAddress, tableReference),
    );

    field.formulaError = hasError ? FormulasErrors.get(FormulaErrorTypes.REF).message : '';
  }

  removeFormulaFunctions(match: string): boolean {
    return !this.allFormulas.has(match.toUpperCase());
  }

  private hasInvalidSheetNames(field: TableColumn, sanitizedFormula: string): boolean {
    let hasInvalidSheetNames = null;

    const sheetNames = this.getSheetNames(sanitizedFormula);

    if (sheetNames.length) {
      hasInvalidSheetNames = sheetNames.some(
        (sheetName) => !this.formulaV2TemplateEngine.doesSheetExist(sheetName),
      );
    }

    field.formulaError = hasInvalidSheetNames ? FormulasErrors.get(FormulaErrorTypes.REF).message : '';
    return hasInvalidSheetNames;
  }

  getSheetNames(formula: string): string[] {
    const pattern = new RegExp(SHEET_NAME_WITHOUT_EXCLAMATION, 'ig');
    return Array.from(new Set(formula.match(pattern)));
  }

  getRangeStartCellReferences(formula: string): string[] {
    const pattern = new RegExp(CELL_REFEFERENCE_WITH_LEFT_SIDE_RANGE_OPERATOR, 'ig');
    return formula.match(pattern) || [];
  }

  getCellReferenceWithoutTables(formula: string): string[] {
    const matches = flatMap(
      this.getDefaultCellReference(formula)
        .filter((value: string) => !value.includes('Table'))
        .map((match: string) => match.split(':')),
    );

    return Array.from(new Set(matches));
  }

  areCellAddressesEqual(address: SimpleCellAddress, cell: SimpleCellAddress): boolean {
    return address.col === cell.col && address.row === cell.row && address.sheet === cell.sheet;
  }

  isCellWithinRange(address: SimpleCellAddress, range: SimpleCellRange): boolean {
    return (
      address.sheet === range.start.sheet &&
      address.col >= range.start.col &&
      address.col <= range.end.col &&
      address.row >= range.start.row &&
      address.row <= range.end.row
    );
  }

  getDefaultCellReference(formula: string): string[] {
    const matches = formula.match(new RegExp(FULL_CELL_REFERENCE, 'ig'));

    return Array.from(new Set(matches));
  }

  private removeStringsFromFormula(formula = ''): string {
    return formula
      .replace(/"([^"]*)"/g, '')
      .replace(/'([^']*)'/g, '')
      .replace(/“([^”]*)”/g, '')
      .replace(/\$/g, '');
  }

  private parenthesisDepth(formula: string): number {
    let maxHistoric = 0;
    let current = 0;

    Array.from(formula).forEach((char) => {
      const isOpenParenthesis = char === '(';
      const isCloseParenthesis = char === ')';

      if (isOpenParenthesis) {
        current += 1;
        maxHistoric = Math.max(maxHistoric, current);
      } else if (isCloseParenthesis) {
        current -= 1;
      }
    });

    return maxHistoric;
  }
}
