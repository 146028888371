import {
  Component,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Field } from '@site-mate/dashpivot-shared-library';

import { FormFieldAnalyticsFilterFactory } from 'app/form-fields/form-field-analytics-filter-factory';

import { FormFieldAnalyticsFilter } from './form-field-analytics-filter';
import { FormFieldAnalyticsFilterHostDirective } from './form-field-analytics-filter-host.directive';

@Component({
  selector: 'cc-form-field-analytics-filter',
  templateUrl: './form-field-analytics-filter.component.html',
  styleUrls: ['./form-field-analytics-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldAnalyticsFilterComponent implements AfterViewInit {
  @Input() field: Field;
  @Input() state: any;
  @Input() options: Observable<any[]>;
  @Output() onChange = new EventEmitter();

  @ViewChild(FormFieldAnalyticsFilterHostDirective, { static: true })
  filterHost: FormFieldAnalyticsFilterHostDirective;

  filter: FormFieldAnalyticsFilter;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (!this.field) {
      return;
    }
    this.filter = FormFieldAnalyticsFilterFactory.getFilter(this.field);

    if (this.filterHost && this.filter) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.filter.component);
      const { viewContainerRef } = this.filterHost;
      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance.field = this.field;
      componentRef.instance.state = this.state;
      componentRef.instance.options = this.options;

      componentRef.instance.onChange.subscribe((params) => this.onChange.emit(params));

      this.ref.detectChanges();
    }
  }
}
