<div class="form-group row app-control-row sm-cursor-grab" [class.field-error]="!isValid">
  <button type="button" class="remove-control button-regular sm-py-1" (click)="remove.emit()">
    <i class="cc-icon cc-icon-close sm-mr-1 sm-text-default-red" aria-hidden="true"></i>
    <span class="control-label" i18n>Delete</span>
  </button>
  <div class="app-control-label no-input icon-container">
    <button type="button" class="btn btn-secondary app-control-dropdown">
      <i class="cc-icon cc-icon-page-break"></i>
    </button>
  </div>
  <div class="sm-flex sm-w-full">
    <div class="field-container">
      <div class="dash-container" aria-hidden="true">
        <div class="dashed-line"></div>
      </div>
      <div class="page-break-label">
        <span class="sm-bg-white sm-px-4" i18n>Page break</span>
      </div>
    </div>
    <div class="error-message" *ngIf="!isValid">
      <span i18n>{{ errorMessage }}</span>
    </div>
  </div>

  <cc-field-action type="clone" [disabled]="hasReachedPlanLimit" (onClick)="clone.emit()"></cc-field-action>
</div>
