import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { FormService } from 'app/form/form.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterViewFilterService {
  private registerViewFiltersCache = new Map();

  constructor(protected readonly formService: FormService) {}

  getFilterListOptions(
    templateId: string,
    fieldId: string,
  ): Observable<string[] | Record<string, unknown>[]> {
    const cacheKey = `${templateId}-${fieldId}`;

    if (!this.registerViewFiltersCache.has(cacheKey)) {
      this.registerViewFiltersCache.set(
        cacheKey,
        this.formService.getFormItemValues(templateId, fieldId).pipe(shareReplay(1)),
      );
    }

    return this.registerViewFiltersCache.get(cacheKey);
  }

  getFilterTableListOptions(
    templateId: string,
    fieldId: string,
    columnId: string,
  ): Observable<string[] | Record<string, unknown>[]> {
    const cacheKey = `${templateId}-${fieldId}-${columnId}`;

    if (!this.registerViewFiltersCache.has(cacheKey)) {
      this.registerViewFiltersCache.set(
        cacheKey,
        this.formService.getFormItemTableColumnValues(templateId, fieldId, columnId).pipe(shareReplay(1)),
      );
    }

    return this.registerViewFiltersCache.get(cacheKey);
  }

  clearRegisterViewFiltersCache() {
    this.registerViewFiltersCache.clear();
  }
}
