import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';

import { EventTypes, List, Template, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { IField } from 'app/apps/template-default-fields/field.model';
import { FormFieldTemplateFactory } from 'app/form-fields/form-field-template-factory';
import { FieldWeb } from 'app/shared/model/item.model';
import { EventsService } from 'app/shared/service/events/events.service';

import { TemplateFieldHostDirective } from './template-field-host.directive';
import { TemplateFieldBaseComponent } from '../template-field-base.component';

@Component({
  selector: 'cc-template-field',
  templateUrl: './template-field.component.html',
  styleUrls: ['./template-field.component.scss'],
})
export class TemplateFieldComponent implements AfterViewInit, OnChanges {
  @Input() controls: FieldWeb[];
  @Input() field: FieldWeb;
  @Input() headerFooterField: IField;
  @Input() template: Template;
  @Input() metadataList: Observable<List[]>;
  @Input() companyId: string;
  @Input() parentId: string;
  @Input() templateOrientation: TemplateOrientations;
  @Input() hasReachedPlanLimit: boolean;
  @Input() workspaceItemsLimit: number;

  @Output() updated = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Output() required = new EventEmitter();
  @Output() hideInFormPDF = new EventEmitter();
  @Output() excludeInFormCloning = new EventEmitter();

  @ViewChild(TemplateFieldHostDirective, { static: true }) fieldHost: TemplateFieldHostDirective;

  isHeaderFooterField: boolean;

  private componentInstance: TemplateFieldBaseComponent;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly eventsService: EventsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setComponentFields();

    if (this.componentInstance?.onChange) {
      this.componentInstance.onChange(changes);
    }
  }

  ngAfterViewInit(): void {
    this.renderComponent();
    this.ref.detectChanges();
  }

  private renderComponent() {
    const componentType = this.headerFooterField
      ? FormFieldTemplateFactory.getHeaderFooterRenderComponent()
      : FormFieldTemplateFactory.getRenderComponent(this.field);

    if (!componentType) {
      return;
    }

    const componentRef = this.fieldHost.viewContainerRef.createComponent(componentType);

    this.componentInstance = componentRef.instance;

    this.setComponentFields();

    this.componentInstance.updated.subscribe(() => {
      this.ref.detectChanges();
      this.updated.emit();
    });

    this.componentInstance.remove.subscribe(() => {
      this.ref.detectChanges();
      this.remove.emit();
    });

    this.componentInstance.clone.subscribe(() => {
      this.ref.detectChanges();
      this.clone.emit();
    });

    this.componentInstance.required.subscribe(() => {
      this.eventsService.trackEvent(EventTypes.RequiredFieldToggled);
    });

    this.componentInstance.hideInFormPDF.subscribe(() => {
      this.eventsService.trackEvent(EventTypes.HideFieldInPDFToggled);
    });

    this.componentInstance.excludeInFormCloning.subscribe(() => {
      this.eventsService.trackEvent(EventTypes.ExcludeInFormCloningToggled);
    });
  }

  private setComponentFields() {
    if (!this.componentInstance) {
      return;
    }

    this.componentInstance.headerFooterField = this.headerFooterField;
    this.componentInstance.model = this.field;
    this.componentInstance.app = this.template;
    this.componentInstance.controls = this.controls;
    this.componentInstance.metadataList = this.metadataList;
    this.componentInstance.companyId = this.companyId;
    this.componentInstance.parentId = this.parentId;
    this.componentInstance.templateOrientation = this.templateOrientation;
    this.componentInstance.hasReachedPlanLimit = this.hasReachedPlanLimit;
    this.componentInstance.workspaceItemsLimit = this.workspaceItemsLimit;
  }
}
