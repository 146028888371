import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { intersection } from 'lodash-es';
import { DragulaService } from 'ng2-dragula';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
  CategorySourceTypes,
  EventTypes,
  FieldKinds,
  ILegacyList,
  LogicActions,
  LogicOperators,
  Template,
  TemplateOrientations,
  YesNoValues,
  ListService as SharedListService,
} from '@site-mate/dashpivot-shared-library';

import { TemplateListService } from 'app/apps/template-list.service';
import { TemplatesService } from 'app/apps/templates.service';
import { FieldWeb, LogicRuleWeb } from 'app/shared/model/item.model';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { FetchedQuery } from 'app/shared/model/query/query.model';
import { ConfirmService } from 'app/shared/service/confirm.service';

type LogicValue = {
  id: string;
  label: string;
};

@Component({
  selector: 'cc-logic-rule',
  templateUrl: './logic-rule.component.html',
  styleUrls: ['./logic-rule.component.scss'],
})
export class LogicRuleComponent implements OnInit, OnDestroy {
  private categorySub: Subscription;
  private unSubscribe = new Subject<void>();

  @Input() rule: LogicRuleWeb;
  @Input() field: FieldWeb;
  @Input() fields: FieldWeb[];
  @Input() template: Template;
  @Input() companyId: string;
  @Input() parentId: string;
  @Input() templateOrientation: TemplateOrientations;
  @Input() fieldUpdated: EventEmitter<FieldWeb>;
  @Input() hasReachedPlanLimit: boolean;

  @Output() updated = new EventEmitter<FieldWeb>();
  @Output() remove = new EventEmitter<number>();
  @Output() clone = new EventEmitter<number>();
  @Output() validate = new EventEmitter<FieldWeb>();
  @Output() removeField = new EventEmitter<FieldWeb[]>();

  readonly dragulaId = 'template-fields-drag';
  private readonly filterListItems = new SharedListService().filterDeployedListItems;

  logicOperators: string[] = [];
  logicActions = Object.values(LogicActions).filter((action) => action === LogicActions.ShowField);
  logicValues: LogicValue[];
  isCategory: boolean;
  showLearnMore: boolean;

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly templatesService: TemplatesService,
    private readonly dragulaService: DragulaService,
    private readonly templateListsService: TemplateListService,
  ) {}

  get showFields() {
    return this.rule.logicAction === LogicActions.ShowField && this.rule.isOpen;
  }

  get emptyFields() {
    return !this.fields.some((field) => field.dependsOn?.logicRuleId === this.rule.id);
  }

  ngOnInit() {
    if (!this.rule.values) {
      this.rule.values = [];
    }
    this.isCategory = this.field?.kind === FieldKinds.Category;
    this.showLearnMore = this.field.logicRules.indexOf(this.rule) === 0;

    this.loadValues(this.field);

    this.logicOperators = [LogicOperators.Equals];

    this.fieldUpdated.pipe(takeUntil(this.unSubscribe)).subscribe((field) => {
      this.loadValues(field);
    });

    this.dragulaService
      .drop(this.dragulaId)
      .pipe(
        takeUntil(this.unSubscribe),
        filter(({ target }) => target && target.getAttribute('data-field-id') === this.field._id),
      )
      .subscribe(() => this.onFieldUpdated());
  }

  trackByFieldIdentifier(_index: number, field: FieldWeb) {
    return `${field.id}-${field.kind}`;
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  toggleOpenFields() {
    this.rule.isOpen = !this.rule.isOpen;
  }

  updateSelectedValues() {
    this.rule.values = intersection(
      this.rule.values,
      this.logicValues.map(({ id }) => id),
    );
  }

  async onRemoveField(index: number) {
    try {
      await this.confirmService.confirmDelete('deleteField');

      this.templatesService.trackFieldEvent(this.fields[index], EventTypes.TemplateFieldDeleted);

      const removedFields = this.templatesService.removeControl(index, this.fields);
      this.removeField.emit(removedFields);
      this.onFieldUpdated();
    } catch {
      // no-op
    }
  }

  async onCloneField(index: number) {
    const clonedFields = await this.templatesService.cloneFieldWithValidations(
      this.fields[index],
      this.fields,
    );
    if (!clonedFields) {
      return;
    }

    this.fields.splice(index + 1, 0, ...clonedFields);
    this.onFieldUpdated();

    this.templatesService.trackFieldEvent(this.fields[index], EventTypes.TemplateFieldCloned);
  }

  private loadValues(field: FieldWeb) {
    if (this.isCategory) {
      this.loadCategoryValues();
    } else {
      this.loadYesNoValues(field);
    }
  }

  private loadYesNoValues(field: FieldWeb) {
    this.logicValues = Object.keys(YesNoValues)
      .filter((key) => field.hasNaButton || key !== 'NA')
      .map((key) => ({ id: YesNoValues[key], label: key }));
    this.updateSelectedValues();
  }

  private loadCategoryValues() {
    if (this.categorySub) {
      this.categorySub.unsubscribe();
    }

    if (this.field.categorySource === CategorySourceTypes.Manual) {
      this.loadManualListItems();
    }

    if (this.field.categorySource === CategorySourceTypes.List) {
      this.loadListItems();
    }
  }

  private loadManualListItems() {
    this.logicValues = this.field.items.map((item) => ({
      id: item.id,
      label: item.value,
    }));

    this.updateSelectedValues();
  }

  private loadListItems() {
    this.categorySub = this.templateListsService
      .getFullLegacyList(this.field.reference)
      .pipe(
        takeUntil(this.unSubscribe),
        filter((listQuery) => listQuery.state === QueryStates.Fetched),
      )
      .subscribe((fetchedListQuery: FetchedQuery<ILegacyList>) => {
        this.updateListOptions(fetchedListQuery.data);
      });
  }

  private updateListOptions(list: ILegacyList) {
    this.logicValues = this.filterListItems(list, this.parentId).items.map((row) => ({
      id: row._id,
      label: row.value,
    }));
    this.updateSelectedValues();
  }

  onRemoveRule() {
    this.remove.emit();
    this.validateLogicRules();
  }

  onCloneRule() {
    this.clone.emit();
    this.validateLogicRules();
  }

  onFieldUpdated() {
    this.updated.emit(this.field);
    this.validateLogicRules();
  }

  validateLogicRules() {
    this.validate.emit();
  }
}
