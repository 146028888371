import {
  AfterViewInit,
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';

import { FormFieldHeaderColumn } from './form-field-header-column';
import { FormRegisterViewFilterBaseComponent } from '../filters/form-register-view-filter-base.component';
import { FormRegisterViewFilterHostDirective } from '../filters/form-register-view-filter-host.directive';

@Component({
  selector: 'cc-form-field-header',
  templateUrl: 'form-field-header.component.html',
  styleUrls: [
    '../form-field.component.scss',
    '../form-field-column/form-field-column.component.scss',
    'form-field-header.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldHeaderComponent implements AfterViewInit {
  @Input()
  static = false;

  @Input()
  column: FormFieldHeaderColumn;

  @Output()
  filterChanged = new EventEmitter();

  @ViewChild(FormRegisterViewFilterHostDirective)
  filterHost: FormRegisterViewFilterHostDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (!this.filterHost) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.filterHost.filter.renderer,
    );
    const { viewContainerRef } = this.filterHost;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const component = componentRef.instance as FormRegisterViewFilterBaseComponent;
    component.model = this.filterHost.filter;
    component.search.subscribe(() => {
      this.filterChanged.emit();
    });
    this.ref.detectChanges();
  }
}
