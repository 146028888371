import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SegmentService } from 'app/segment/segment.service';

import { MultiSelectFilter } from './multi-select-filter';
import { ErrorHandler } from '../../../../../shared/service/error-handler.service';
import { FormRegisterViewFilterBaseComponent } from '../form-register-view-filter-base.component';
import { RegisterViewFilterService } from '../register-view-filter.service';

@Component({
  selector: 'cc-reg-view-multi-select-table-list-filter',
  templateUrl: './form-register-view-multi-select-filter.component.html',
  styleUrls: ['../form-register-view-filter.component.scss'],
})
export class FormRegisterViewMultiSelectTableListFilterComponent
  extends FormRegisterViewFilterBaseComponent
  implements OnDestroy
{
  get filterContextType(): string {
    return 'TableList';
  }
  @Input() model: MultiSelectFilter;
  private unSubscribe = new Subject<void>();
  loading = true;

  constructor(
    protected readonly segmentService: SegmentService,
    protected readonly registerViewFilterService: RegisterViewFilterService,
    protected readonly errorHandler: ErrorHandler,
  ) {
    super(segmentService);
  }

  toggleFilter() {
    this.loadOptions();
  }

  private loadOptions() {
    this.registerViewFilterService
      .getFilterTableListOptions(this.model.templateId, this.model.fieldId, this.model.columnId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(
        (options) => {
          this.model.options = options.map((option) => ({ value: option, label: option }));
          this.loading = false;
        },
        (error) => {
          this.errorHandler.handle(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
