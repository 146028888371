// eslint-disable-next-line import/no-cycle
import { Filter } from '../filters/filter';

export class FormFieldHeaderColumn {
  id: string;
  templateId: string;
  kind: string;
  title: string;
  filter: Filter;

  constructor(item) {
    this.id = item.id;
    this.templateId = item.id;
    this.kind = item.kind;
    this.title = item.description;
  }
}
