<div class="form-group row app-control-row sm-cursor-grab sm-flex-col">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline photo-text-label">
    <cc-photo-thumbnail-dropdown
      [model]="model"
      [hierarchy]="hierarchy"
      (photoThumbnailChange)="setPhotoThumbnailChange()"
    ></cc-photo-thumbnail-dropdown>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>
  <div class="app-control-control sm-flex sm-flex-col sm-items-start sm-gap-2">
    <img [src]="thumbnailSrc" alt="placeholder thumbnail for photos" />
    <button type="button" class="btn btn-primary disabled sm-cursor-grab" i18n>
      Add photos<i class="cc-icon cc-icon-photo sm-pl-1"></i>
    </button>
  </div>
</div>
