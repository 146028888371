import { Component, Input } from '@angular/core';

import { FormFieldColumn } from './form-field-column';

@Component({
  templateUrl: 'form-field-column.component.html',
  styleUrls: ['form-field-column.component.scss'],
})
export class FormFieldColumnComponent {
  @Input()
  column: FormFieldColumn;

  @Input()
  metadata: Record<string, string>;
}
