import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

import { SketchUtils } from '../../sketch-utils';

@Component({
  templateUrl: './sketch-template-preview.component.html',
  styleUrls: ['./sketch-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SketchTemplatePreviewComponent {
  @Input() field: FieldWeb;

  constructor() {}

  getBackgroundUrl() {
    return SketchUtils.getSketchpadBackground(this.field);
  }
}
