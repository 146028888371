import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChildren,
  ChangeDetectorRef,
  QueryList,
  inject,
} from '@angular/core';

import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { FormFieldVersionDiffFactory } from 'app/form-fields/form-field-version-diff-factory';
import { FormPhotoDiffComponent } from 'app/form-fields/photo/edit/diff/form-photo-diff.component';
import { FieldWeb } from 'app/shared/model/item.model';

import { FormFieldVersionDiffHostDirective as HostDirective } from './form-field-version-diff-host';

@Component({
  selector: 'cc-form-field-version-diff',
  templateUrl: './form-field-version-diff.component.html',
  styleUrls: ['../../../../form/form-version-diff/form-version-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldVersionDiffComponent {
  private cdr = inject(ChangeDetectorRef);
  @Input() previous: FieldWeb;
  @Input() next: FieldWeb;
  @Input() isLandscapeMode: boolean = false;
  @ViewChildren(HostDirective) fieldHosts: QueryList<HostDirective>;

  ngAfterViewInit(): void {
    this.render();
  }

  private render() {
    this.fieldHosts.forEach((fieldHost) => {
      const { field } = fieldHost;
      const componentType = this.getComponentType(field);
      const componentRef = fieldHost.viewContainerRef.createComponent(componentType);
      componentRef.instance.field = field;
      if (field.kind === FieldKinds.Photo) {
        (componentRef.instance as FormPhotoDiffComponent).isLandscapeMode = this.isLandscapeMode;
      }
    });

    this.cdr.detectChanges();
  }

  private getComponentType(column: FieldWeb) {
    return FormFieldVersionDiffFactory.getRenderComponent(column);
  }
}
