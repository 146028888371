<div class="form-group row app-control-row app-table-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label app-table-control-label form-inline align-items-center">
    <cc-table-type-switch
      [model]="model"
      [hierarchy]="hierarchy"
      (changed)="updated.emit()"
    ></cc-table-type-switch>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
    <div
      *ngIf="isFormulaV2 && tableInFocus"
      class="formula-cell-name sm-ml-2 sm-flex sm-items-center"
      [ngClass]="model.id === tableInFocus ? 'sm-bg-default-blue' : 'sm-bg-grey-5'"
    >
      {{ model.tableReference }}
    </div>
    <div class="app-control-row-nowrap app-control-spacer large" *ngIf="horizontalTablesSupport.enabled">
      <label class="default-label" [for]="model.id + 'displayRowsMode' + tableDisplayModes[0].key" i18n>
        Display on mobile:
      </label>
      <div *ngFor="let tableDisplayMode of tableDisplayModes" class="app-control-row-nowrap">
        <input
          type="radio"
          [name]="model.id + 'displayRowsMode'"
          [id]="model.id + 'displayRowsMode' + tableDisplayMode.key"
          [(ngModel)]="model.displayRowsMode"
          [value]="tableDisplayMode.key"
          class="app-control-spacer app-control-large-radio"
        />
        <label class="default-label" [for]="model.id + 'displayRowsMode' + tableDisplayMode.key" i18n>{{
          tableDisplayMode.description
        }}</label>
      </div>
    </div>
    <div class="width-warning text-muted d-flex align-items-center mr-2" *ngIf="displayErrorMessage" i18n>
      <i class="cc-icon cc-icon-warning mr-1"></i>This table exceeds printable width
    </div>
  </div>

  <div class="app-table-control app-control-control table-wrapper sm-cursor-grab">
    <cc-banner
      data-test="extend-cell-type-banner"
      *ngIf="isPrefilledTable"
      ccBannerLogic
      [dismissible]="true"
      [bannerKey]="bannerKeys.ExtendCellTypeToRows"
      [localStorageKey]="bannerMetadata[bannerKeys.ExtendCellTypeToRows].localStorageKey"
      [showBadge]="true"
      badgeText="New"
      badgePosition="left"
      width="fit-content"
      customClasses="sm-mb-2"
    >
      <div class="sm-flex sm-w-fit sm-gap-1">
        <span i18n>You can now extend the first cell type to all rows in pre-filled tables via the ‘</span>
        <i class="cc-icon cc-icon-ellipsis-horizontal prefilled-banner-icon"></i>
        <span i18n>’ button in the table column header!</span>
      </div>
    </cc-banner>
    <cc-banner
      *ngIf="listPropertyTableColumnIds.size !== 0"
      data-test="list-property-table-cell-beta-banner"
      showBadge="true"
      badgeText="Beta"
      type="warning"
      width="fit-content"
      [bannerText]="listPropertyTableCellBetaBannerText"
      customClasses="sm-mb-2"
    ></cc-banner>
    <div class="form-table">
      <table class="table-bordered table-responsive table" #tableCore>
        <thead>
          <tr>
            <th class="table-control-td"></th>
            <th
              *ngFor="
                let column of model.columns;
                trackBy: trackByColumnIds;
                index as headerIndex;
                first as isFirst;
                last as isLast
              "
              class="sm-relative"
              [class.column-blue-line]="hoverColumn === headerIndex"
              [class.last-column-blue-line]="isLast && hoverColumn === headerIndex + 1"
            >
              <button
                type="button"
                class="btn add-column-plus btn-add-midway"
                attr.data-test="{{ 'add-column-' + column.id }}"
                (click)="addColumn(headerIndex)"
                (mouseenter)="setHoverOnColumn(headerIndex)"
                (mouseleave)="setHoverOnColumn(null)"
              >
                <i class="cc-icon cc-icon-plus plus-button"></i>
              </button>
              <div class="add-column-line"></div>
              <ng-container
                *ngIf="tableInFocus === model.id || (isFormulaV2 && tableInFocus); else columnProperties"
              >
                <div class="formula-cell header-holder">
                  <span
                    data-test="formula-column-coordinates"
                    class="formula-cell-name sm-flex sm-items-center"
                    [ngClass]="model.id === tableInFocus ? 'sm-bg-default-blue' : 'sm-bg-grey-5'"
                  >
                    {{ formulaColumnLabel(column, headerIndex) }}
                  </span>
                </div>
              </ng-container>
              <ng-template #columnProperties>
                <div class="d-flex justify-content-between align-items-center header-holder flex-row">
                  <!-- Column Width Select-->
                  <ng-select
                    [(ngModel)]="model.columns[headerIndex].width"
                    [items]="columnWidthOptions"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="value"
                    data-test="column-width-select"
                    class="no-drag column-width-selector"
                    (ngModelChange)="changeColumnSize($event)"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="d-flex" *ngIf="item.value === tableColumnWidths.Legacy">
                        <i class="cc-icon cc-icon-warning legacy-icon align-middle"></i>
                      </div>
                      {{ item.name }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                  <div
                    class="column-options-dropdown"
                    ngbDropdown
                    (openChange)="columnToggle.blur()"
                    container="body"
                  >
                    <button
                      #columnToggle
                      type="button"
                      class="d-flex align-items-center column-dropdown border-0 p-1"
                      ngbDropdownToggle
                      attr.data-test="{{ 'ngb-toggle-column-' + column.id }}"
                    >
                      <i class="cc-icon cc-icon-ellipsis-horizontal"></i>
                    </button>
                    <div
                      ngbDropdownMenu
                      class="column-dropdown-menu"
                      [class.column-dropdown-menu-prefilled]="isPrefilledTable"
                    >
                      <div class="sm-grid">
                        <label
                          *ngIf="showRequiredOptionByIndex[headerIndex]"
                          i18n
                          class="default-label required-label d-flex action-icons sm-mt-0 sm-select-none"
                        >
                          <input
                            type="checkbox"
                            [(ngModel)]="model.columns[headerIndex].isRequired"
                            name="{{ 'is-required-' + column.id }}"
                            id="{{ 'is-required-' + column.id }}"
                            class="checkbox"
                          />
                          Required?
                        </label>

                        <button
                          *ngIf="isPrefilledTable"
                          (click)="extendFirstCellTypeToAllRows(headerIndex)"
                          type="button"
                          title="Extend first cell type to all rows"
                          i18n-title="Extend first cell type to all rows"
                          attr.data-test="{{ 'extend-cell-type-' + column.id }}"
                          class="action-icons btn btn-sm"
                        >
                          <cc-tool-tip
                            placement="right"
                            contentText="Click ‘Extend first cell type to all rows’ to update all of the rows and values in this specific column to match the first row cell type."
                            containerClass="tooltip-dropdown-item"
                          >
                            <i class="cc-icon cc-icon-deploy-down"></i>
                            <span class="sm-flex-grow" i18n>Extend first cell type to all rows</span>
                            <i class="cc-icon cc-icon-question"></i>
                          </cc-tool-tip>
                        </button>
                        <button
                          type="button"
                          [disabled]="isFirst"
                          (click)="moveColumnLeft(headerIndex)"
                          title="Move left"
                          i18n-title="Move left"
                          attr.data-test="{{ 'move-left-' + column.id }}"
                          class="action-icons btn btn-sm"
                        >
                          <i class="cc-icon cc-icon-arrow-long-left"></i> <span i18n>Move column left</span>
                        </button>
                        <button
                          type="button"
                          [disabled]="isLast"
                          (click)="moveColumnRight(headerIndex)"
                          title="Move right"
                          i18n-title="Move right"
                          attr.data-test="{{ 'move-right-' + column.id }}"
                          class="action-icons btn btn-sm"
                        >
                          <i class="cc-icon cc-icon-arrow-long-right"></i>
                          <span i18n>Move column right</span>
                        </button>
                        <button
                          type="button"
                          [disabled]="model.columns.length === 1"
                          i18n-title="Remove Column"
                          title="Remove Column"
                          class="action-icons btn btn-sm sm-mb-0"
                          (click)="removeColumn(headerIndex)"
                          [attr.data-test]="'remove-column-' + headerIndex"
                        >
                          <i class="cc-icon cc-icon-delete sm-text-default-red"></i
                          ><span i18n class="delete-text">Delete column</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <button
                *ngIf="isLast"
                type="button"
                class="btn add-last-column-plus btn-add-midway"
                data-test="add-last-column"
                (click)="addColumn()"
                (mouseenter)="setHoverOnColumn(headerIndex + 1)"
                (mouseleave)="setHoverOnColumn(null)"
              >
                <em class="cc-icon cc-icon-plus plus-button"></em>
              </button>
              <div *ngIf="isLast" class="add-last-column-line"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table-control-td first-td sm-relative"></td>
            <td
              *ngFor="let column of model.columns; index as headerIndex; last as isLast"
              class="sm-relative"
              [ngClass]="['column-' + column.width]"
              [class.column-blue-line]="hoverColumn === headerIndex"
              [class.last-column-blue-line]="isLast && hoverColumn === headerIndex + 1"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control table-header-input"
                  name="{{ 'th' + headerIndex }}"
                  [(ngModel)]="column.heading"
                />
              </div>
              <div class="add-column-line"></div>
              <div *ngIf="isLast" class="add-last-column-line"></div>
            </td>
          </tr>
          <tr *ngIf="!isPrefilledTable">
            <td class="table-control-td first-td sm-relative">
              <div class="formula-cell header-holder sm-absolute sm-h-full sm-w-full sm-items-center">
                <span
                  *ngIf="tableInFocus === model.id || (isFormulaV2 && tableInFocus)"
                  data-test="formula-column-coordinates"
                  class="formula-cell-name sm-flex sm-items-center"
                  [ngClass]="model.id === tableInFocus ? 'sm-bg-default-blue' : 'sm-bg-grey-5'"
                >
                  {{ isFormulaV2 ? 1 : model.formulaRowNumber }}
                </span>
              </div>
            </td>

            <td
              *ngFor="let column of model.columns; index as headerIndex; last as isLast"
              class="sm-relative"
              [ngClass]="['column-' + column.width]"
              [class.column-blue-line]="hoverColumn === headerIndex"
              [class.last-column-blue-line]="isLast && hoverColumn === headerIndex + 1"
            >
              <cc-app-table-column
                [index]="headerIndex"
                [item]="model"
                [column]="column"
                [companyId]="companyId"
                [template]="app"
                (updated)="onCellKindChange($event)"
                (formulaChange)="onFormulaChange($event)"
                [companyPlanType]="companyPlanType"
                [width]="column.width"
              ></cc-app-table-column>

              <div class="add-column-line"></div>
              <div *ngIf="isLast" class="add-last-column-line"></div>
            </td>
          </tr>
          <tr
            *ngFor="let row of model.rows; index as rowIndex; first as isFirst; last as isLast"
            class="table-row-prefilled sm-relative"
            #currentRow
          >
            <td *ngIf="isPrefilledTable" class="table-control-td first-td sm-relative">
              <div class="sm-absolute sm-flex sm-h-full sm-w-full sm-flex-col sm-items-center sm-py-2">
                <div class="row-options-dropdown" ngbDropdown (openChange)="rowToggle.blur()">
                  <button
                    #rowToggle
                    type="button"
                    class="d-flex align-items-center row-dropdown border-0 p-1"
                    ngbDropdownToggle
                    attr.data-test="{{ 'ngb-toggle-row-' + row.id }}"
                  >
                    <i class="cc-icon cc-icon-ellipsis-horizontal"></i>
                  </button>
                  <div ngbDropdownMenu class="row-dropdown-menu sm-w-12.5">
                    <div class="sm-grid">
                      <button
                        type="button"
                        [disabled]="isFirst"
                        (click)="moveUp(rowIndex)"
                        title="Move up"
                        i18n-title="Move up"
                        attr.data-test="{{ 'move-up-' + row.id }}"
                        class="action-icons btn btn-sm"
                      >
                        <i class="cc-icon cc-icon-arrow-up"></i> <span i18n>Move row up</span>
                      </button>
                      <button
                        type="button"
                        [disabled]="isLast"
                        (click)="moveDown(rowIndex)"
                        title="Move down"
                        i18n-title="Move down"
                        attr.data-test="{{ 'move-down-' + row.id }}"
                        class="action-icons btn btn-sm"
                      >
                        <i class="cc-icon cc-icon-arrow-down"></i> <span i18n>Move row down</span>
                      </button>
                      <button
                        type="button"
                        [disabled]="model.rows.length === 1"
                        (click)="removeRow(rowIndex)"
                        title="Remove row"
                        i18n-title="Remove row"
                        class="action-icons btn btn-sm"
                        attr.data-test="{{ 'remove-' + row.id }}"
                      >
                        <i class="cc-icon cc-icon-delete sm-text-default-red"></i
                        ><span i18n class="delete-text">Delete row</span>
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn add-row-plus btn-add-midway"
                  attr.data-test="{{ 'add-row-' + row.id }}"
                  (click)="addRow(rowIndex)"
                  (mouseenter)="currentRow.setAttribute('data-hover-row', 'true')"
                  (mouseleave)="currentRow.setAttribute('data-hover-row', '')"
                >
                  <i class="cc-icon cc-icon-plus plus-button"></i>
                </button>
                <div class="add-row-line btn-line"></div>
                <ng-container
                  *ngTemplateOutlet="prefilledRowCoordinates; context: { $implicit: row, index: rowIndex }"
                ></ng-container>
                <button
                  type="button"
                  *ngIf="isLast"
                  class="btn add-last-row-plus btn-add-midway"
                  data-test="add-last-row"
                  (click)="addRow(model.rows.length)"
                  (mouseenter)="currentRow.setAttribute('data-hover-last-row', 'true')"
                  (mouseleave)="currentRow.setAttribute('data-hover-last-row', '')"
                >
                  <em class="cc-icon cc-icon-plus plus-button"></em>
                </button>
                <div class="add-row-line"></div>
              </div>
            </td>
            <td
              *ngFor="
                let column of row.columns;
                index as columnIndex;
                trackBy: trackByColumns;
                last as isLast
              "
              class="sm-relative"
              [ngClass]="isPrefilledTable ? 'column-' + model.columns[columnIndex].width : ''"
              [class.column-blue-line]="hoverColumn === columnIndex"
              [class.last-column-blue-line]="isLast && hoverColumn === columnIndex + 1"
            >
              <cc-app-table-column
                [item]="model"
                [index]="columnIndex"
                [column]="column"
                [companyId]="companyId"
                [template]="app"
                (updated)="onCellKindChange($event)"
                (formulaChange)="onFormulaChange($event)"
                [companyPlanType]="companyPlanType"
                [width]="model.columns[columnIndex].width"
              >
              </cc-app-table-column>
              <div class="add-row-line"></div>
              <div class="add-column-line"></div>
              <div *ngIf="isLast" class="add-last-column-line"></div>
            </td>
            <div *ngIf="isLast" class="add-last-row-line"></div>
            <div *ngIf="isLast" class="add-last-column-line"></div>
          </tr>
        </tbody>
      </table>
    </div>

    <button
      type="button"
      *ngIf="isPrefilledTable"
      i18n-title="Add row"
      title="Add row"
      class="btn btn-info"
      (click)="addRow(model.rows.length)"
    >
      <i class="cc-icon cc-icon-plus"></i>
      <span class="sm-mr-1" i18n>Add row</span>
    </button>

    <button
      type="button"
      i18n-title="Add column"
      title="Add column"
      class="btn btn-success"
      (click)="addColumn()"
    >
      <i class="cc-icon cc-icon-plus"></i>
      <span class="sm-mr-1" i18n>Add column</span>
    </button>
  </div>
</div>

<ng-template #prefilledRowCoordinates let-row let-rowIndex="index">
  <div
    *ngIf="tableInFocus === model.id || (isFormulaV2 && tableInFocus)"
    class="sm-flex sm-w-full sm-flex-1 sm-flex-col sm-items-center sm-justify-center sm-text-center"
  >
    <span
      data-test="formula-row-coordinates"
      class="formula-cell-name"
      [ngClass]="model.id === tableInFocus ? 'sm-bg-default-blue' : 'sm-bg-grey-5'"
      [class.sm-items-center]="tableInFocus"
    >
      {{ isFormulaV2 ? rowIndex + 1 : row.formulaRowNumber }}
    </span>
  </div>
</ng-template>
