import { FieldWeb } from 'app/shared/model/item.model';

import { CategoryThumbnailComponent } from './category/thumbnail/category-thumbnail.component';
import { FormFieldThumbnail } from './common/thumbnail/form-field-thumbnail';
import { CounterThumbnailComponent } from './counter/thumbnail/counter-thumbnail.component';
import { CreatedByThumbnailComponent } from './created-by/thumbnail/created-by-thumbnail.component';
import { CreatedDateThumbnailComponent } from './created-date/thumbnail/created-date-thumbnail.component';
import { DateThumbnailComponent } from './date/thumbnail/date-thumbnail.component';
import { PersonThumbnailComponent } from './person/thumbnail/person-thumbnail.component';
import { SingleLineInputThumbnailComponent } from './single-line-input/thumbnail/single-line-input-thumbnail.component';

export class FormFieldThumbnailFactory {
  private static resolver = {
    category: CategoryThumbnailComponent,
    date: DateThumbnailComponent,
    singleLineInput: SingleLineInputThumbnailComponent,
    counter: CounterThumbnailComponent,
    createdBy: CreatedByThumbnailComponent,
    createdAt: CreatedDateThumbnailComponent,
    person: PersonThumbnailComponent,
  };

  static getThumbnail(field: FieldWeb): FormFieldThumbnail {
    const component = this.resolver[field.kind];

    if (!component) {
      return null;
    }

    return new FormFieldThumbnail(component, field);
  }
}
