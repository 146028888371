import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { upperFirst } from 'lodash-es';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  StyleVariants,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';

@Component({
  selector: 'cc-app-table-column-list-item',
  templateUrl: 'app-table-column-list-item.component.html',
  styleUrls: ['app-table-column-list-item.component.scss'],
})
export class TableColumnListItemTemplateComponent implements OnChanges {
  isEdit;
  editedItemValue;

  @Input()
  item;

  @Input()
  companyId: string;

  @Output()
  remove = new EventEmitter();

  @Output()
  onEdit = new EventEmitter();

  @Input()
  editingItem;

  readonly styleVariants = StyleVariants;
  readonly tableColumnWidths = TableColumnWidths;

  constructor(private readonly segmentService: SegmentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.editingItem && changes.editingItem.currentValue) {
      const { id } = changes.editingItem.currentValue;
      if (this.item.id && id !== this.item.id && this.isEdit) {
        this.cancelEdit();
      }
    }
  }

  edit() {
    this.onEdit.emit(this.item);
    this.editedItemValue = this.item.value;
    this.isEdit = true;
  }

  styleUpdated(style: StyleVariants) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.TableListItemColourEdited, {
        Context: upperFirst(style),
      }),
      this.segmentService,
    );
  }

  cancelEdit() {
    this.editedItemValue = '';
    this.isEdit = false;
  }

  saveEdit() {
    if (!this.editedItemValue) {
      return;
    }
    this.item.value = this.editedItemValue;
    this.editedItemValue = '';
    this.isEdit = false;
  }
}
