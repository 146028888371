import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Component({
  selector: 'cc-app-person',
  templateUrl: './person-template.component.html',
  styleUrls: ['./person-template.component.scss', '../../common/template/form-control.component.scss'],
})
export class PersonTemplateComponent extends TemplateFieldBaseComponent {
  private unSubscribe = new Subject<void>();
  personFieldNameError: string;

  constructor(private i18nService: TmpI18NService) {
    super();
    this.personFieldNameError = this.i18nService.getMessage('personFieldNameError');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  personFieldDescriptionUpdated() {
    this.updated.emit();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
