import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectId } from 'bson';

import {
  AccountSubscriptionService,
  EventTypes,
  FieldKinds,
  TemplateOrientations,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { AppType } from 'app/apps/app-control-type.model';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SignatureRuleLogicWeb } from 'app/shared/model/item.model';
import { AccountService } from 'app/shared/service/account.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { AccountUpgradeLimitComponent } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit.component';

import { SignatureType } from './signature-type.model';

@Component({
  selector: 'cc-app-signature',
  templateUrl: 'app-signature.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', './app-signature.component.scss'],
})
export class AppSignatureComponent extends TemplateFieldBaseComponent implements OnInit {
  appType = AppType;
  signatureTypeEnum = SignatureType;
  fieldKinds = FieldKinds;

  isAddLogicAvailable = false;
  canUpgradePlan = false;
  isUpgradeButtonVisible = false;

  companyPlanType: CompanyPlanTypes;
  readonly templateOrientations = TemplateOrientations;

  constructor(
    private eventsService: EventsService,
    private readonly ngbModal: NgbModal,
    private readonly i18nService: TmpI18NService,
    private readonly accountSubscriptionService: AccountSubscriptionService,
    private readonly accountService: AccountService,
  ) {
    super();
  }

  get signatureType() {
    if (this.model.kind === FieldKinds.Signature && this.app.appType === AppType.workflow) {
      // NOTE: we were incorrectly setting the field kind = 'signature' for timeline templates
      // this extra check to confirm the it's a workflow template before allowing approval signature fields
      return SignatureType.ApprovalSignature;
    }
    return this.model.isManualSignature ? SignatureType.SignatureArrayManual : SignatureType.SignatureArray;
  }

  set signatureType(value: SignatureType) {
    if (value === SignatureType.ApprovalSignature) {
      this.model.kind = FieldKinds.Signature;
      this.model.isApprovalSignature = true;
    } else {
      this.model.kind = FieldKinds.SignatureArray;
      this.model.isManualSignature = value === SignatureType.SignatureArrayManual;
      this.model.isApprovalSignature = false;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setupLogicVisibilityRules();

    // NOTE: we were incorrectly setting the field kind = 'signature' for timeline templates, so need
    // this extra check to fix up the field kind. All other checks should come after it.
    if (this.model.kind === FieldKinds.Signature && this.app.appType !== AppType.workflow) {
      this.model.kind = FieldKinds.SignatureArray;
    }

    if (this.signatureType === this.signatureTypeEnum.ApprovalSignature) {
      this.model.isApprovalSignature = true;
    }
  }

  private setupLogicVisibilityRules() {
    this.isAddLogicAvailable = this.signatureType === this.signatureTypeEnum.ApprovalSignature;

    this.companyPlanType = this.accountService.getAccountPlanType();
    this.canUpgradePlan = !this.accountSubscriptionService.canAddApprovalSignatureLogic();
  }

  onAddLogic() {
    if (this.canUpgradePlan) {
      return;
    }

    this.model.signatureRules = this.model.signatureRules || [];
    this.model.signatureRules.push({
      _id: new ObjectId().toHexString(),
      showAllConditions: true,
    } as SignatureRuleLogicWeb);

    this.updated.emit(this.model);

    this.eventsService.trackEvent(EventTypes.ApprovalSignatureLogicAdded);
  }

  approvalSignatureNumber() {
    const currentIndex = this.controls.indexOf(this.model);
    const approvalSignatureNum = this.controls.reduce(
      (signed, item, index) =>
        item.kind === FieldKinds.Signature && index <= currentIndex ? signed + 1 : signed,
      0,
    );
    return approvalSignatureNum;
  }

  onAddLogicMouseEnter() {
    this.isUpgradeButtonVisible = this.canUpgradePlan;
  }

  onAddLogicMouseLeave() {
    this.isUpgradeButtonVisible = false;
  }

  openUpgradeModal() {
    this.sendEvent();

    const upgradeModal = this.ngbModal.open(AccountUpgradeLimitComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });
    upgradeModal.componentInstance.title = this.i18nService.getMessage('unlockApprovalSignatureLogic');
    upgradeModal.componentInstance.subtitle = this.i18nService.getMessage(
      'unlockApprovalSignatureLogicSubtitle',
    );
    upgradeModal.componentInstance.imageSrc = '/assets/images/usage-limits/visitor-users.svg';
    upgradeModal.componentInstance.imageAlt = 'three industrial workers';
  }

  private sendEvent() {
    this.eventsService.trackEvent(EventTypes.UpgradeClicked, {
      Context: 'Unlock Approval Signature Logic Banner',
    });
  }
}
