import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { AccountUpgradeLimitComponent } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit.component';

const defaultContext = 'Unlock Conditional Approval Signature Logic Banner';

@Injectable({ providedIn: 'root' })
export class SignatureLogicRuleService {
  constructor(
    private readonly modalService: NgbModal,
    private readonly i18nService: TmpI18NService,
    private readonly eventsService: EventsService,
  ) {}

  openUpgradeModal(context: string = defaultContext) {
    this.sendEvent(context);

    const upgradeModal = this.modalService.open(AccountUpgradeLimitComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });
    upgradeModal.componentInstance.title = this.i18nService.getMessage(
      'unlockConditionalApprovalSignatureLogic',
    );
    upgradeModal.componentInstance.subtitle = this.i18nService.getMessage(
      'unlockConditionalApprovalSignatureLogicSubtitle',
    );
    upgradeModal.componentInstance.imageSrc = '/assets/images/usage-limits/visitor-users.svg';
    upgradeModal.componentInstance.imageAlt = 'three industrial workers';
  }

  private sendEvent(context: string) {
    this.eventsService.trackEvent(EventTypes.UpgradeClicked, {
      Context: context,
    });
  }
}
