import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { TableColumn } from '@site-mate/dashpivot-shared-library';

@Component({
  templateUrl: './photo-column-template-preview.component.html',
  styleUrls: ['./photo-column-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoColumnTemplatePreviewComponent {
  @Input() column: TableColumn;
}
