<div class="field-settings" (mouseenter)="handleExpandableState()" (mouseleave)="handleExpandableState()">
  <div class="settings-title">
    <i class="cc-icon cc-icon-gear sm-pr-1"></i>
    <span class="sm-font-sans sm-text-default-text">Field Settings</span>
  </div>

  <div
    *ngFor="let action of actions"
    class="action-container {{ action.isVisible ? 'sm-flex' : 'sm-hidden' }}"
  >
    <input
      type="checkbox"
      class="checkbox-input {{ isExpanded ? 'sm-ml-[2px]' : '' }}"
      [(ngModel)]="field[action.fieldName]"
      (change)="action.onUpdate.emit()"
      [id]="action.label + '-' + field.id"
      [disabled]="isSignatureField && action.fieldName === isExcludedInFormCloning"
      [class.signature-disabled]="
        isExpanded && isSignatureField && action.fieldName === isExcludedInFormCloning
      "
    />
    <label
      [for]="action.label + '-' + field.id"
      class="checkbox-label-container"
      [class.disabled]="isSignatureField && action.fieldName === isExcludedInFormCloning"
    >
      <div class="checkbox-label">
        <i class="cc-icon cc-icon-{{ action.icon }}"></i>
        <span data-test="action-label" *ngIf="isExpanded">{{ action.label }}</span>
      </div>

      <span
        *ngIf="isExpanded && isSignatureField && action.fieldName === isExcludedInFormCloning"
        class="signature-cloning-explanation"
        i18n
      >
        No signature fields or cells can be cloned in Dashpivot due to compliance reasons.
      </span>
    </label>
  </div>
  <span
    *ngIf="isExpanded && hasPhotoTagsAndDescription"
    class="photo-description-tags-note sm-font-sans sm-text-xs sm-text-secondary-text"
    i18n
  >
    Note: Description and Photo tags are <br />
    only available for L and XL photo fields.
  </span>
</div>
