<div class="form-group row app-control-row sm-cursor-grab">
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline">
    <div
      ngbDropdown
      class="d-inline-block position-relative mr-2"
      #dropdown="ngbDropdown"
      (mouseenter)="dropdown.open()"
      (mouseleave)="dropdown.close()"
    >
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-secondary app-control-dropdown"
          (click)="$event.preventDefault(); dropdown.open()"
          ngbDropdownAnchor
        >
          <i class="cc-icon cc-icon-signature"></i>
        </button>
      </div>
      <div ngbDropdownMenu class="dropdown-menu px-3" (click)="$event.stopPropagation(); dropdown.close()">
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Signature"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + signatureType"
            [value]="signatureTypeEnum.SignatureArray"
            [attr.data-value]="signatureTypeEnum.SignatureArray"
            [(ngModel)]="signatureType"
            [id]="'text-signature-0-' + identifier"
            data-cy="signature"
          />
          <label i18n [for]="'text-signature-0-' + identifier" class="default-label form-check-label"
            >Signature</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Signature"
          [ctaHierarchy]="hierarchy"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + signatureType"
            [value]="signatureTypeEnum.SignatureArrayManual"
            [attr.data-value]="signatureTypeEnum.SignatureArrayManual"
            [(ngModel)]="signatureType"
            [id]="'text-signature-2-' + identifier"
            data-cy="manual-signature"
          />
          <label i18n [for]="'text-signature-2-' + identifier" class="default-label form-check-label"
            >Sign manually</label
          >
        </div>
        <div
          class="option-dropdown-item form-check form-check-inline justify-content-start ml-0"
          ccSegmentCTA="Template Field Dropdown Options"
          ctaContext="Signature"
          [ctaHierarchy]="hierarchy"
          *ngIf="app.appType === appType.workflow && !model.dependsOn?.fieldId"
        >
          <input
            class="form-check-input"
            type="radio"
            [name]="model.id + signatureType"
            [value]="signatureTypeEnum.ApprovalSignature"
            [attr.data-value]="signatureTypeEnum.ApprovalSignature"
            [(ngModel)]="signatureType"
            [id]="'text-signature-1-' + identifier"
            data-cy="approval-signature"
          />
          <label i18n [for]="'text-signature-1-' + identifier" class="default-label form-check-label"
            >Approval signature</label
          >
        </div>
      </div>
    </div>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>
  <div class="app-control-control">
    <button
      *ngIf="signatureType === signatureTypeEnum.SignatureArray"
      type="button"
      class="btn btn-success disabled sm-cursor-grab"
      i18n
    >
      Sign<i class="cc-icon cc-icon-signature sm-pl-1"></i>
    </button>
    <div *ngIf="signatureType === signatureTypeEnum.ApprovalSignature">
      <button type="button" class="btn btn-success disabled sm-cursor-grab" i18n>
        Sign to approve <i class="cc-icon cc-icon-signature sm-pl-1"></i>
      </button>
      <small i18n class="text-muted sm-pl-1">
        Approval signature is used for moving forms to the next column in a workflow.
      </small>
    </div>
    <div *ngIf="signatureType === signatureTypeEnum.SignatureArrayManual">
      <button type="button" class="btn btn-success disabled sm-cursor-grab" i18n>
        Sign manually <i class="cc-icon cc-icon-signature sm-pl-1"></i>
      </button>
      <small i18n class="text-muted sm-pl-1">
        After you have saved the template, you can add multiple manual signatures.
      </small>
    </div>
  </div>
  <button
    *ngIf="isAddLogicAvailable"
    class="default-label logic-control no-drag btn sm-space-x-1 disabled:!sm-cursor-not-allowed"
    [class.upgrade-label]="isUpgradeButtonVisible"
    [disabled]="model.signatureRules?.length >= 2 && !canUpgradePlan"
    type="button"
    (click)="canUpgradePlan ? openUpgradeModal() : onAddLogic()"
    (mouseenter)="onAddLogicMouseEnter()"
    (mouseleave)="onAddLogicMouseLeave()"
  >
    <!-- Upgrade to Add Logic Button -->
    <ng-container *ngIf="isUpgradeButtonVisible">
      <i class="cc-icon cc-icon-lock-closed sm-text-default-dark-orange"></i>
      <span class="control-label" i18n>
        <span class="sm-text-default-blue sm-underline">Upgrade</span> from the
        {{ companyPlanType | titlecase }} Plan to unlock Approval Signature Logic
      </span>
    </ng-container>
    <!-- Add logic button -->
    <ng-container *ngIf="!isUpgradeButtonVisible">
      <i class="cc-icon cc-icon-logic"></i>
      <span class="control-label" i18n>Add logic</span>
    </ng-container>
  </button>
</div>

<img
  *ngIf="isUpgradeButtonVisible"
  data-test="approval-signature-logic-placeholder"
  class="ml-3 animate-fade-in sm-mb-2"
  [src]="
    app.orientation === templateOrientations.Portrait
      ? 'assets/images/template-editor/approval-signature-logic-placeholder-portrait.svg'
      : 'assets/images/template-editor/approval-signature-logic-placeholder-landscape.svg'
  "
  alt="Approval Signature Logic Placeholder"
  (mouseenter)="onAddLogicMouseEnter()"
/>
<cc-signature-logic-rule-list
  *ngIf="model.isApprovalSignature && !isUpgradeButtonVisible"
  [canUpgradePlan]="canUpgradePlan"
  [companyPlanType]="companyPlanType"
  [field]="model"
  [fields]="controls"
></cc-signature-logic-rule-list>
