import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cc-filter-trigger',
  templateUrl: './filter-trigger.component.html',
  styleUrls: [],
})
export class FilterTriggerComponent {
  @Input() filterTemplate: TemplateRef<unknown>;
  @Input() active = false;

  @ViewChild('filterPopover', { static: true }) filter: NgbPopover;
}
