<div
  data-test="text-field-container"
  class="form-group row app-control-row sm-cursor-grab"
  [class.sm-flex-col]="isPrefilledTextType"
>
  <cc-template-field-actions
    [field]="model"
    [fieldActionsConfig]="fieldActionsConfig"
    [fieldSettingsActions]="fieldSettingsActions"
    [disabled]="hasReachedPlanLimit"
  ></cc-template-field-actions>
  <div class="app-control-label form-inline" [class.pre-filled-text-label]="isPrefilledTextType">
    <cc-template-dropdown-radio-input
      #dropdownRadioSelector
      [options]="options"
      [fieldId]="model.id"
      [value]="model.kind"
      (selectOption)="onSelectOption($event)"
      [identifier]="identifier"
      [hierarchy]="hierarchy"
      iconClass="cc-icon-text"
    ></cc-template-dropdown-radio-input>
    <input type="text" class="form-control no-drag" [(ngModel)]="model.description" />
  </div>

  <!-- Text Area -->
  <div class="app-control-control app-control-text">
    <input *ngIf="model.kind === FieldKinds.SingleLineInput" type="text" class="form-control" readonly />

    <textarea
      *ngIf="model.kind === FieldKinds.MultiLineInput"
      type="text"
      class="form-control app-text-area"
      readonly
      rows="3"
    ></textarea>

    <div *ngIf="isPrefilledTextType">
      <cc-rich-editor
        [model]="model"
        [autoFocus]="autoFocus"
        [editorToolbarOption]="editorToolbarOption"
      ></cc-rich-editor>
    </div>
  </div>
</div>
