import { TableCellKinds, TableColumn } from '@site-mate/dashpivot-shared-library';

import { AttachmentColumnTemplatePreviewComponent } from './columns/attachment/attachment-column-template-preview.component';
import { DateColumnTemplatePreviewComponent } from './columns/date/date-column-template-preview.component';
import { FormulaColumnTemplatePreviewComponent } from './columns/formula/formula-column-template-preview.component';
import { GenericColumnTemplatePreviewComponent } from './columns/generic/generic-column-template-preview.component';
import { ListColumnTemplatePreviewComponent } from './columns/list/list-column-template-preview.component';
import { ListPropertyColumnTemplatePreviewComponent } from './columns/list-property/ListPropertyTableCellColumnTemplatePreview/list-property-column-template-preview.component';
import { LocationColumnTemplatePreviewComponent } from './columns/location/location-column-template-preview.component';
import { ManualSignatureColumnTemplatePreviewComponent } from './columns/manual-signature/manual-signature-column-template-preview.component';
import { NumberColumnTemplatePreviewComponent } from './columns/number/number-column-template-preview.component';
import { PhotoColumnTemplatePreviewComponent } from './columns/photo/photo-column-template-preview.component';
import { PrefilledTextColumnTemplatePreviewComponent } from './columns/prefilled-text/prefilled-text-column-template-preview.component';
import { SignatureColumnTemplatePreviewComponent } from './columns/signature/signature-column-template-preview.component';
import { TextColumnTemplatePreviewComponent } from './columns/text/text-column-template-preview.component';
import { TimeColumnTemplatePreviewComponent } from './columns/time/time-column-template-preview.component';

export class TableColumnTemplatePreviewFactory {
  private static componentMap = new Map([
    [TableCellKinds.Attachment, AttachmentColumnTemplatePreviewComponent],
    [TableCellKinds.Date, DateColumnTemplatePreviewComponent],
    [TableCellKinds.Formula, FormulaColumnTemplatePreviewComponent],
    [TableCellKinds.List, ListColumnTemplatePreviewComponent],
    [TableCellKinds.ListProperty, ListPropertyColumnTemplatePreviewComponent],
    [TableCellKinds.Location, LocationColumnTemplatePreviewComponent],
    [TableCellKinds.ManualSignature, ManualSignatureColumnTemplatePreviewComponent],
    [TableCellKinds.Number, NumberColumnTemplatePreviewComponent],
    [TableCellKinds.Photo, PhotoColumnTemplatePreviewComponent],
    [TableCellKinds.PrefilledText, PrefilledTextColumnTemplatePreviewComponent],
    [TableCellKinds.Signature, SignatureColumnTemplatePreviewComponent],
    [TableCellKinds.Text, TextColumnTemplatePreviewComponent],
    [TableCellKinds.Time, TimeColumnTemplatePreviewComponent],
  ]);

  private static defaultRenderer = GenericColumnTemplatePreviewComponent;

  static getRenderComponent(column: TableColumn) {
    return this.componentMap.get(column.kind) || this.defaultRenderer;
  }
}
