<div
  ngbDropdown
  class="app-control-dropdown mr-2 sm-relative sm-inline-block"
  #dropdown="ngbDropdown"
  (mouseenter)="dropdown.open()"
  (mouseleave)="dropdown.close()"
>
  <button
    type="button"
    class="btn btn-secondary"
    (click)="$event.preventDefault(); dropdown.open()"
    ngbDropdownAnchor
  >
    <i class="cc-icon cc-icon-photo"></i>
  </button>
  <div class="px-3 dropdown-menu" (click)="$event.stopPropagation(); dropdown.close()" ngbDropdownMenu>
    <ng-container *ngFor="let thumbnail of photoThumbnailOptions">
      <div class="option-dropdown-item form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          [name]="model.id + 'photo-thumbnail'"
          [value]="thumbnail.value"
          [(ngModel)]="model.photoThumbnailSize"
          [id]="thumbnail.value"
          (change)="onPhotoThumbnailChange()"
          data-cy="inline"
        />
        <label i18n [for]="thumbnail.value" class="default-label form-check-label">
          {{ thumbnail.label }}
        </label>
      </div>
    </ng-container>
  </div>
</div>
