import { EventEmitter, Input, Output, OnInit, Directive, ViewChild } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';

import { Filter } from './filter';
import { FilterTriggerComponent } from './trigger/filter-trigger.component';

@Directive()
export abstract class FormRegisterViewFilterBaseComponent implements OnInit {
  @Input() model: Filter;
  @Output() search = new EventEmitter();
  @ViewChild(FilterTriggerComponent) filterTriggerComponent: FilterTriggerComponent;

  status: number;

  abstract get filterContextType(): string;

  constructor(protected readonly segmentService: SegmentService) {}

  ngOnInit() {
    this.status = this.model.status;
  }

  toggleFilter() {
    this.sendFilterIconClickedEventToSegment();
  }

  resetFilter() {
    this.model.reset();
    this.applyFilter();
  }

  applyFilter() {
    this.filterTriggerComponent.filter.close();
    this.search.emit();
    this.sendApplyFilterEvent();
  }

  sendFilterIconClickedEventToSegment() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.TemplateRegisterViewFilterIconClicked, {
        Context: this.filterContextType,
      }),
      this.segmentService,
    );
  }

  private sendApplyFilterEvent() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.TemplateRegisterViewFilterApplied, { Context: this.filterContextType }),
      this.segmentService,
    );
  }
}
