import { Component, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-created-by-thumbnail',
  templateUrl: './created-by-thumbnail.component.html',
  styleUrls: ['./created-by-thumbnail.component.scss'],
})
export class CreatedByThumbnailComponent {
  @Input() field: FieldWeb;
}
