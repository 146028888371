<div class="app-control-row row rule-container" [class.logic-rule-invalid]="rule._invalid">
  <div class="sm-space-y-2">
    <div class="sm-flex sm-items-center sm-justify-between">
      <div class="sm-flex sm-items-center sm-justify-start">
        <p i18n class="rule-title" data-test="title">Approval Signature Logic</p>
        <cc-rule-counter
          *ngIf="rule.complementaryRules?.length"
          [total]="maxComplementaryRules"
          [count]="rule.complementaryRules?.length"
          rule="AND"
          [isUpgradeAvailable]="isLimitUpgradable"
          data-test="and-counter"
        ></cc-rule-counter>
      </div>
      <a
        data-test="learn-more"
        i18n
        class="learn-more-helper"
        href="https://intercom.help/dashpivot/en/articles/8329198-approval-signature-logic"
        target="_blank"
        rel="noopener noreferrer"
      >
        <cc-banner
          bannerText="Learn more about Approval Signature Logic"
          icon="question"
          size="sm"
          type="helper"
        ></cc-banner>
      </a>
    </div>

    <div class="upper-form">
      <div class="sm-flex sm-items-start sm-gap-1">
        <div class="inline-form-text-container">
          <span>If</span>
        </div>
        <div>
          <cc-search-and-select
            customClass="has-badge height-auto"
            data-test="logic-types"
            placeholder="Select..."
            i18n-placeholder="Select..."
            name="{{ dropdownNames.Type + '-' + rule._id }}"
            minWidth="200px"
            [items]="typesDropdowns"
            [(selected)]="selectedType"
            [invalid]="isInputInvalid(dropdownNames.Type)"
            (change)="onInputChange($event, 'type', !!rule._invalid)"
            [disabled]="canUpgradePlan || maxRulesExceeded"
          ></cc-search-and-select>
          <span *ngIf="isInputInvalid(dropdownNames.Type)" class="invalid-message">
            {{ inputRequiredMessage }}
          </span>
        </div>
        <div class="inline-form-text-container">
          <span>is</span>
        </div>
        <div>
          <cc-search-and-select
            customClass="height-auto"
            data-test="logic-operators"
            placeholder="Select..."
            i18n-placeholder="Select..."
            name="{{ dropdownNames.Operator + '-' + rule._id }}"
            minWidth="200px"
            [items]="operatorsDropdown"
            [(selected)]="selectedOperator"
            [invalid]="isInputInvalid(dropdownNames.Operator)"
            [disabled]="!rule.type || canUpgradePlan || maxRulesExceeded"
            (change)="onInputChange($event, dropdownNames.Operator, !!rule._invalid)"
          ></cc-search-and-select>
          <span *ngIf="isInputInvalid(dropdownNames.Operator)" class="invalid-message">
            {{ inputRequiredMessage }}
          </span>
        </div>
      </div>

      <div class="sm-flex-grow sm-gap-1">
        <cc-search-and-select
          customClass="single-line no-clear-all-button height-auto"
          data-test="logic-values"
          name="{{ dropdownNames.Values + '-' + rule._id }}"
          placeholder="Select..."
          i18n-placeholder="Select..."
          minWidth="200px"
          [items]="valuesDropdown"
          [selected]="selectedValues"
          (selectedChange)="onSelectedValuesChange($event)"
          [clearable]="rule.operator === logicOperators.Equals"
          [multiple]="rule.operator === logicOperators.Equals"
          [disabled]="!rule.type || !rule.operator || canUpgradePlan || maxRulesExceeded"
          [invalid]="isInputInvalid(dropdownNames.Values)"
          (change)="onInputChange($event, dropdownNames.Values, !!rule._invalid)"
        >
          <ng-template #customOptionTemplate let-option>
            <cc-permission-type-values-dropdown-item [option]="option" />
          </ng-template>
        </cc-search-and-select>
        <span *ngIf="isInputInvalid(dropdownNames.Values)" class="invalid-message">
          {{ inputRequiredMessage }}
        </span>
      </div>
    </div>
    <div
      *ngIf="enableComplementaryLogicFeature"
      class="complementary-logic-container sm-flex sm-w-full sm-flex-col sm-gap-2"
    >
      <button
        *ngIf="!canUpgradePlan || rule.complementaryRules?.length"
        type="button"
        class="toggle-show-logic-button"
        (click)="toggleShowAllConditions()"
        data-test="toggle-conditions"
      >
        <ng-container *ngIf="rule.showAllConditions">
          <i class="cc-icon cc-icon-eyeball-slash"></i> <span i18n>Hide all conditions</span>
        </ng-container>
        <ng-container *ngIf="!rule.showAllConditions">
          <i class="cc-icon cc-icon-eyeball"></i> <span i18n>Show all conditions</span>
        </ng-container>
      </button>
      <ng-container *ngIf="rule.showAllConditions">
        <cc-complementary-logic
          *ngFor="let complementaryRule of rule.complementaryRules || []"
          class="complementary-logic-root"
          [complementaryRule]="complementaryRule"
          [canUpgradePlan]="canUpgradePlan"
          [companyPlanType]="companyPlanType"
          [maxRulesExceeded]="maxRulesExceeded"
          [inputRequiredMessage]="inputRequiredMessage"
          [fields]="fields"
          (delete)="onDeleteComplementaryLogic($event)"
          (onNestedRuleDelete)="updateAndButtonState()"
          (validateLogicRules)="validateLogicRules()"
          [maxComplementaryRules]="maxComplementaryRules"
        />
        <div
          data-test="add-and-condition-container"
          (mouseenter)="onAddLogicMouseEnter()"
          (mouseleave)="onAddLogicMouseLeave()"
        >
          <div class="upgrade-banner" *ngIf="showUpgradeButton">
            <i class="cc-icon cc-icon-warning sm-text-default-dark-orange"></i>
            <span class="sm-font-normal sm-text-default-dark-orange" i18n>
              {{ companyPlanType | titlecase }} Plan limit reached.
              <a
                data-test="upgrade-plan-link"
                class="sm-cursor-pointer sm-text-default-blue sm-underline"
                (click)="onOpenUpgradeModal()"
                >Upgrade</a
              >
              to unlock up to 12 Conditional Approval Signature logics.
            </span>
          </div>
          <cc-popover
            *ngIf="
              !canUpgradePlan &&
              !showUpgradeButton &&
              rule.complementaryRules?.length <= maxComplementaryRules
            "
            placement="right"
            container="body"
            [disabled]="enableAndButton"
          >
            <span content i18n>
              {{ popoverMessage }}
            </span>

            <button
              trigger
              type="button"
              class="and-button"
              [disabled]="!enableAndButton"
              (click)="onAddComplementaryLogic()"
            >
              <i class="cc-icon cc-icon-plus"></i> AND
            </button>
          </cc-popover>
        </div>
        <cc-banner
          *ngIf="rule.complementaryRules?.length > maxComplementaryRules && isLimitUpgradable"
          type="red-warning"
          icon="warning"
          alignment="center"
          width="fit-content"
          [bannerText]="exceedLimitWarningBannerMessage"
          data-test="pro-plan-warning-banner"
        >
          <button
            class="sm-text-default-blue sm-underline"
            data-test="open-upgrade-modal-signature"
            type="button"
            (click)="onOpenUpgradeModal()"
          >
            <span i18n> Upgrade to unlock up to 12 conditional logic blocks. </span>
          </button>
        </cc-banner>
      </ng-container>
    </div>
    <div class="form-inline sm-gap-1">
      <div class="sm-flex sm-items-start sm-gap-1">
        <div class="inline-form-text-container">
          <span i18n>Then allow</span>
        </div>

        <div>
          <cc-search-and-select
            customClass="has-badge height-auto"
            data-test="logic-actions"
            placeholder="Select..."
            i18n-placeholder="Select..."
            name="{{ dropdownNames.Action + '-' + rule._id }}"
            minWidth="267px"
            [items]="actionsDropdown"
            [(selected)]="selectedAction"
            (selectedChange)="onSelectedActionChange($event)"
            [invalid]="isInputInvalid(dropdownNames.Action)"
            [disabled]="canUpgradePlan || maxRulesExceeded"
            (change)="onInputChange($event, dropdownNames.Action, true)"
          ></cc-search-and-select>

          <span *ngIf="isInputInvalid(dropdownNames.Action)" class="invalid-message">
            {{ rule._invalidMessage ?? inputRequiredMessage }}
          </span>
        </div>

        <div class="inline-form-text-container">
          <span i18n>when the following conditions are met.</span>
        </div>
      </div>
    </div>
  </div>

  <cc-field-action type="delete" (onClick)="onDeleteRule()"></cc-field-action>

  <cc-banner
    *ngIf="canUpgradePlan"
    data-test="upgrade-plan-banner"
    type="warning"
    icon="warning"
    alignment="center"
    width="fit-content"
    size="sm"
    customClasses="sm-font-medium"
    [bannerText]="bannerMessage"
  >
    <a
      href="https://intercom.help/dashpivot/en/articles/8329198-approval-signature-logic"
      target="_blank"
      rel="noopener noreferrer"
      class="sm-font-normal sm-text-default-blue sm-underline"
      data-test="learn-more-visitor-users"
      i18n
    >
      Learn more.
    </a>
  </cc-banner>
</div>
