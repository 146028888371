<cc-template-field-settings
  *ngIf="fieldSettingsActions.length > 0"
  [field]="field"
  [actions]="fieldSettingsActions"
></cc-template-field-settings>
<cc-field-action type="delete" (onClick)="fieldActionsConfig.delete.onUpdate.emit()"></cc-field-action>
<cc-field-action
  type="clone"
  [disabled]="disabled"
  (onClick)="fieldActionsConfig.clone.onUpdate.emit()"
></cc-field-action>
