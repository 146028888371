import { yesNoFilterOptions, yesNoNaFilterOptions } from './yes-no-filter-options';
import { Filter } from '../filter';

export class YesNoFilter extends Filter {
  selectedValues = [];

  constructor(item, renderer) {
    super(renderer);

    this.options = item.hasNaButton ? yesNoNaFilterOptions : yesNoFilterOptions;
  }

  queryParams() {
    if (this.selectedValues.length) {
      return this.selectedValues;
    }

    return null;
  }

  get status() {
    return this.selectedValues.length;
  }

  set(params) {
    if (!params) {
      this.reset();
      return;
    }

    this.selectedValues = params;
  }

  reset() {
    this.selectedValues = [];
  }
}
