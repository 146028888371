import { Directive, ViewContainerRef, Input } from '@angular/core';

import { Filter } from './filter';

@Directive({
  selector: '[ccFormRegisterViewFilterHost]',
})
export class FormRegisterViewFilterHostDirective {
  @Input()
  filter: Filter;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
