import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, Placement } from '@ng-bootstrap/ng-bootstrap';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

import { SignatureLogicRuleService } from '../signature-logic-rule.service';

@Component({
  selector: 'cc-rule-counter',
  templateUrl: './rule-counter.component.html',
  styleUrls: ['./rule-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleCounterComponent implements OnChanges {
  @Input() total = 0;
  @Input() count = 0;
  @Input() isPopover = false;
  @Input() popoverPlacement: Placement = 'right';
  @Input() rule = 'AND';
  @Input() isUpgradeAvailable = false;

  counterClass = '';
  warningThresholdPercentage = 80;
  limitReachedMessage = this.i18nService.getMessage('signatureLogicRuleReachedLimitFirstPart');
  limitReachedMessageSecondPart = this.i18nService.getMessage('signatureLogicRuleReachedLimitSecondPart');

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly ngbModal: NgbModal,
    private readonly signatureLogicRuleService: SignatureLogicRuleService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total || changes.count) {
      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.count >= this.total) {
      this.counterClass = 'full';
      return;
    }

    const limitStep = 1 / this.total;
    const warningThreshold = this.warningThresholdPercentage / 100 / limitStep;

    if (this.count >= Math.round(warningThreshold)) {
      this.counterClass = 'warning';
    } else {
      this.counterClass = '';
    }
  }

  get percentage() {
    return this.total ? (this.count / this.total) * 100 : 0;
  }

  get counter() {
    return `${this.count ?? 0}/${this.total}`;
  }

  openUpgradeModal() {
    const context = `Unlock ${this.rule.toUpperCase()} Conditional Approval Signature Logic Counter`;
    this.signatureLogicRuleService.openUpgradeModal(context);
  }
}
