<div class="logic-rule-list" *ngFor="let rule of field?.logicRules; let i = index">
  <cc-logic-rule
    [rule]="rule"
    [field]="field"
    [fields]="fields"
    [template]="template"
    [metadataList]="metadataList"
    [companyId]="companyId"
    [parentId]="parentId"
    [fieldUpdated]="fieldUpdated"
    [templateOrientation]="templateOrientation"
    (remove)="onRemove(rule)"
    (clone)="onClone(i)"
    (updated)="updated.emit(field); fieldUpdated.emit(field)"
    (validate)="validateLogicRules()"
    (removeField)="handleFormulaFieldsRemoval($event)"
    [hasReachedPlanLimit]="hasReachedPlanLimit"
  ></cc-logic-rule>
</div>
