import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { SegmentService } from 'app/segment/segment.service';
import { LogicRuleWeb } from 'app/shared/model/item.model';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';

@Component({
  selector: 'cc-yes-no-button-template',
  templateUrl: 'yes-no-button-template.component.html',
  styleUrls: ['../../common/template/form-control.component.scss', 'yes-no-button-template.component.scss'],
})
export class YesNoButtonTemplateComponent extends TemplateFieldBaseComponent implements AfterViewInit {
  buttonType;

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly segmentService: SegmentService,
    private readonly logicRuleService: LogicRuleService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (!this.model.hasNaButton) {
      this.model.hasNaButton = false;
    }
    if (this.hasNaButton && this.hasTextInput) {
      this.buttonType = this.controlType.yesNoButtonType.yesNoNAText;
    } else if (this.hasTextInput) {
      this.buttonType = this.controlType.yesNoButtonType.yesNoText;
    } else if (this.hasNaButton) {
      this.buttonType = this.controlType.yesNoButtonType.yesNoNA;
    } else {
      this.buttonType = this.controlType.yesNoButtonType.yesNo;
    }
    this.changeDetectionRef.detectChanges();
  }

  get hasNaButton() {
    return this.model.hasNaButton;
  }

  get hasTextInput() {
    return this.model.hasTextInput;
  }

  onAddLogic() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.TemplateAddLogicClicked, {
        Context: this.model as unknown as Record<string, string>,
      }),
      this.segmentService,
    );
    this.model.logicRules = this.logicRuleService.addLogicRule(this.model) as LogicRuleWeb[];
    this.updated.emit(this.model);
  }

  updateModel() {
    // eslint-disable-next-line default-case
    switch (this.buttonType) {
      case this.controlType.yesNoButtonType.yesNo:
        this.model.hasNaButton = false;
        this.model.hasTextInput = false;
        break;
      case this.controlType.yesNoButtonType.yesNoNA:
        this.model.hasNaButton = true;
        this.model.hasTextInput = false;
        break;
      case this.controlType.yesNoButtonType.yesNoText:
        this.model.hasNaButton = false;
        this.model.hasTextInput = true;
        break;
      case this.controlType.yesNoButtonType.yesNoNAText:
        this.model.hasNaButton = true;
        this.model.hasTextInput = true;
        break;
    }

    this.updated.emit(this.model);
  }
}
