import { Component, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-page-break-diff',
  templateUrl: './form-page-break-diff.component.html',
})
export class FormPageBreakDiffComponent {
  @Input() field: FieldWeb;
}
