<ng-container
  [ngTemplateOutlet]="
    option.value === userPermissionTypes.ProjectMember || option.value === userPermissionTypes.CompanyMember
      ? newPermissionTypeOptions
      : permissionTypeOptions
  "
  [ngTemplateOutletContext]="{ $implicit: option }"
>
</ng-container>

<ng-template #permissionTypeOptions let-option>
  <span>{{ option.label }}</span>
</ng-template>

<ng-template #newPermissionTypeOptions let-option>
  <cc-popover
    placement="left"
    containerClass="sm-w-full"
    container="body"
    popoverCustomClass="signature-logic-list-option-popover"
  >
    <span content i18n
      >{{ option.label }} is not yet supported on the Dashpivot mobile app. {{ option.label }}s will not be
      able to sign on the mobile app.</span
    >

    <div trigger class="sm-flex sm-justify-between">
      <span content i18n>{{ option.label }}</span>
      <cc-badge text="Beta" type="info" class="sm-mx-1 sm-mr-0.4" textSize="sm-text-xs"> </cc-badge>
    </div>
  </cc-popover>
</ng-template>
