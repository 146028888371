export class FormFieldColumn {
  id: string;
  kind: string;
  content: string;
  static: boolean;
  rowSpan: number;
  renderer;

  constructor(item, renderer) {
    this.id = item._id;
    this.kind = item.kind;
    this.content = item.content || item.value;
    this.static = !!item.static;
    this.rowSpan = item.rowSpan || 0;
    this.renderer = renderer;
  }
}
