import { Component, OnInit } from '@angular/core';

import { HeaderFooterFieldKinds, Template } from '@site-mate/dashpivot-shared-library';

import { IField } from 'app/apps/template-default-fields/field.model';

import { PrefilledTextFieldCharacterLimit } from './header-footer-prefilled-text-field/prefilled-text-field-character-limit';
import { TemplateFieldBaseComponent } from '../../common/template/template-field-base.component';

@Component({
  selector: 'cc-header-footer-field-item',
  templateUrl: './header-footer-field-item.component.html',
  styleUrls: [
    './header-footer-field-item.component.scss',
    '../../../apps/template-edit/template-field-group/template-field-group.component.scss',
  ],
})
export class HeaderFooterFieldItemComponent extends TemplateFieldBaseComponent implements OnInit {
  app: Template;
  headerFooterField: IField;
  hasPreviewText: boolean;
  hasPrefilledTextError: boolean;
  characterLimit: number;
  readonly headerFooterFieldKinds = HeaderFooterFieldKinds;

  ngOnInit() {
    this.setFieldWithPreviewText();
    this.characterLimit = PrefilledTextFieldCharacterLimit[this.app.orientation];
  }

  setFieldWithPreviewText() {
    const previewTextFields = [
      HeaderFooterFieldKinds.Filepath,
      HeaderFooterFieldKinds.TemplateId,
      HeaderFooterFieldKinds.TemplateVersion,
      HeaderFooterFieldKinds.FormVersion,
    ];

    this.hasPreviewText = previewTextFields.includes(this.headerFooterField.kind as HeaderFooterFieldKinds);
  }

  setPrefilledTextError(event: boolean) {
    this.hasPrefilledTextError = event;
  }
}
