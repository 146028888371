import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ComplementarySignatureRuleLogic,
  IField,
  LogicOperators,
  SignatureRuleLogic,
} from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';

import {
  ComplementaryDropdownNames,
  DropdownOptionData,
  LogicOperatorToDropdownMap,
  LogicTypeToDropdownMap,
  TypesDropdowns,
  getTypesToOperatorAndValuesMap,
} from '../signature-logic-rule-helpers';

@Component({
  selector: 'cc-signature-logic-inputs',
  templateUrl: 'signature-logic-inputs.component.html',
  styleUrls: ['../complementary-logic/complementary-logic.component.scss'],
})
export class SignatureLogicInputsComponent {
  @Input() complementaryRule: ComplementarySignatureRuleLogic;
  @Input() rule: SignatureRuleLogic;
  @Input() canUpgradePlan: boolean;
  @Input() inputRequiredMessage: string;
  @Input() disabled: boolean;
  @Input() fields: IField[];
  @Output() delete = new EventEmitter<string>();
  @Output() validateLogicRules = new EventEmitter<void>();

  readonly complementaryLogicOperators = LogicOperators;
  readonly complementaryDropdownNames = ComplementaryDropdownNames;
  readonly complementaryTypesDropdowns = TypesDropdowns;
  complementaryTypesToOperatorAndValuesMap: ReturnType<typeof getTypesToOperatorAndValuesMap>;
  selectedComplementaryType: DropdownOptionData;
  selectedComplementaryOperator: DropdownOptionData;
  selectedComplementaryValues: DropdownOptionData[] | DropdownOptionData = [];

  constructor(private readonly templatesService: TemplatesService) {}

  ngOnInit(): void {
    if (this.complementaryRule.type) {
      this.selectedComplementaryType = LogicTypeToDropdownMap[this.complementaryRule.type];
    }

    if (this.complementaryRule.operator) {
      this.selectedComplementaryOperator = LogicOperatorToDropdownMap[this.complementaryRule.operator];
    }

    this.templatesService.templateUpdatedEvent.subscribe(() => {
      this.complementaryTypesToOperatorAndValuesMap = getTypesToOperatorAndValuesMap(this.fields);

      if (this.complementaryRule.type && this.complementaryRule.values?.length) {
        const validValues = this.complementaryTypesToOperatorAndValuesMap[this.complementaryRule.type];
        const selectedValues = this.complementaryRule.values
          .filter((value) => {
            return validValues.values.find((item) => {
              if (
                typeof item.value === 'object' &&
                'fieldId' in item.value &&
                typeof value === 'object' &&
                'fieldId' in value
              ) {
                return item.value.fieldId === value.fieldId;
              }
              if (
                typeof item.value === 'object' &&
                'formRole' in item.value &&
                typeof value === 'object' &&
                'formRole' in value
              ) {
                return item.value.formRole === value.formRole;
              }
              return item.value === value;
            });
          })
          .map((value) => {
            return validValues.values.find((item) => {
              if (
                typeof item.value === 'object' &&
                'fieldId' in item.value &&
                typeof value === 'object' &&
                'fieldId' in value
              ) {
                return item.value.fieldId === value.fieldId;
              }
              return item.value === value;
            });
          });
        this.selectedComplementaryValues = selectedValues;
        this.complementaryRule.values = this.selectedComplementaryValues.map((value) => value.value);
      }

      const isEqualsOperator = this.complementaryRule.operator === LogicOperators.Equals;
      this.selectedComplementaryValues = isEqualsOperator
        ? this.selectedComplementaryValues
        : this.selectedComplementaryValues[0] || [];
    });

    this.templatesService.onTemplateUpdateChange();
  }

  isInputInvalid(input: ComplementaryDropdownNames): boolean {
    return (
      this.complementaryRule._invalid &&
      this.complementaryRule._invalidInputs?.includes(`${input}-${this.complementaryRule._id}`)
    );
  }

  validateComplementaryLogicRules() {
    this.validateLogicRules.emit();
  }

  get complementaryOperatorsDropdown(): ReadonlyArray<Readonly<DropdownOptionData>> {
    return this.complementaryTypesToOperatorAndValuesMap[this.complementaryRule.type]?.operators || [];
  }

  get complementaryValuesDropdown(): ReadonlyArray<Readonly<DropdownOptionData>> {
    return this.complementaryTypesToOperatorAndValuesMap[this.complementaryRule.type]?.values || [];
  }

  onDelete(): void {
    this.delete.emit(this.complementaryRule._id);
  }

  onInputChange(selected: any, input: string, isInvalidRule: boolean): void {
    const changedInputType: ComplementaryDropdownNames = input as ComplementaryDropdownNames;
    const isDifferentInput = selected !== this.complementaryRule[input];

    const valuesInputChanged = changedInputType === this.complementaryDropdownNames.Values;
    const operatorInputChanged =
      changedInputType === this.complementaryDropdownNames.Operator && isDifferentInput;
    const typeInputChanged = changedInputType === this.complementaryDropdownNames.Type && isDifferentInput;

    if (valuesInputChanged) {
      this.changeValues(selected, input);
    }
    if (operatorInputChanged) {
      this.changeOperator(selected, input);
    }
    if (typeInputChanged) {
      this.changeType(selected, input);
    }

    if (isInvalidRule) {
      this.validateComplementaryLogicRules();
    }
  }

  private changeValues(selected: any, input: string): void {
    const isEqualsOperator = this.complementaryRule.operator === this.complementaryLogicOperators.Equals;
    this.complementaryRule[input] = isEqualsOperator ? selected?.map((item) => item.value) : [selected];
  }

  private changeOperator(selected: any, input: string): void {
    this.complementaryRule.values = [];
    this.selectedComplementaryValues = [];
    this.complementaryRule[input] = selected;
  }

  private changeType(selected: any, input: string): void {
    this.complementaryRule.values = [];
    this.complementaryRule.operator = null;
    this.selectedComplementaryOperator = null;
    this.selectedComplementaryValues = [];
    this.complementaryRule[input] = selected;
  }
}
