import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { TemplateFieldBaseComponent } from 'app/form-fields/common/template/template-field-base.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Component({
  selector: 'cc-page-break-template',
  templateUrl: './page-break-template.component.html',
  styleUrls: ['./page-break-template.component.scss', '../../common/template/form-control.component.scss'],
})
export class PageBreakTemplateComponent extends TemplateFieldBaseComponent implements OnInit, OnDestroy {
  errorMessage = null;
  isValid = true;
  errorHash = {
    isFirstField: 'pageBreakFirstFieldErrorLabel',
    isLastField: 'pageBreakLastFieldErrorLabel',
    isBesideAnotherPageBreak: 'pageBreakConsecutiveFieldError',
  };

  private unSubscribe = new Subject<void>();

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly templatesService: TemplatesService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.validateAndSetErrorMessage();
    this.listenToTemplateUpdates();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  private validateAndSetErrorMessage() {
    const invalidKey = this.validatePageBreakPosition();
    this.isValid = !invalidKey;

    if (!this.isValid) {
      const key = this.errorHash[invalidKey];
      this.errorMessage = this.i18nService.getMessage(key);
    }
  }

  private validatePageBreakPosition() {
    const modelIndex = this.controls.findIndex((control) => control._id === this.model._id);
    const controlsLength = this.controls.length;
    const previousField = this.controls[modelIndex - 1];
    const nextField = this.controls[modelIndex + 1];

    const isFirstField = modelIndex === 0;
    const isLastField = controlsLength > 1 && modelIndex === controlsLength - 1;
    const isBesideAnotherPageBreak =
      previousField?.kind === FieldKinds.PageBreak || nextField?.kind === FieldKinds.PageBreak;

    const fieldsValidation = { isFirstField, isLastField, isBesideAnotherPageBreak };
    const invalidKeys = Object.keys(fieldsValidation).filter((key) => fieldsValidation[key]);

    return invalidKeys.length ? invalidKeys[0] : null;
  }

  private listenToTemplateUpdates() {
    this.templatesService.templateUpdatedEvent.pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.validateAndSetErrorMessage();
    });
  }
}
