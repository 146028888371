import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ObjectId } from 'bson';
import { cloneDeep, findLastIndex, remove } from 'lodash-es';
import { Observable } from 'rxjs';

import { List, LogicRule, Template, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { TemplateListPropertyService } from 'app/apps/template-list-property.service';
import { TemplatesService } from 'app/apps/templates.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { FormulasVersion } from 'app/shared/service/formula-engine.service';
import { FormulaV2TemplateEngineService } from 'app/shared/service/formula-v2-template-engine/formula-v2-template-engine.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-logic-rule-list',
  templateUrl: './logic-rule-list.component.html',
  styleUrls: ['./logic-rule-list.component.scss'],
})
export class LogicRuleListComponent {
  private readonly templateListPropertyService = inject(TemplateListPropertyService);
  @Input() field: FieldWeb;
  @Input() fields: FieldWeb[];
  @Input() template: Template;
  @Input() metadataList: Observable<List[]>;
  @Input() companyId: string;
  @Input() parentId: string;
  @Input() templateOrientation: TemplateOrientations;
  @Input() fieldUpdated: EventEmitter<FieldWeb>;
  @Input() hasReachedPlanLimit: boolean;
  @Input() workspaceItemsLimit: number;

  @Output() updated = new EventEmitter<FieldWeb>();

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly templatesService: TemplatesService,
    private readonly logicRuleService: LogicRuleService,
    private readonly toastrService: ToastrService,
    protected readonly formulaV2TemplateEngineService: FormulaV2TemplateEngineService,
  ) {}

  async onRemove(rule: LogicRule) {
    try {
      await this.confirmService.confirmDelete('deleteRule');
      remove(this.field.logicRules, (logicRule) => rule.id === logicRule.id);
      const logicFields = remove(this.fields, (field: FieldWeb) => field.dependsOn?.logicRuleId === rule.id);
      this.templateListPropertyService.decrementTableCellCounter(logicFields);

      this.validateLogicRules();
      this.handleFormulaFieldsRemoval(logicFields);
      this.updated.emit(this.field); // TODO: remove this event
      this.fieldUpdated.emit(this.field);
    } catch {
      // no-op
    }
  }

  async onClone(index: number) {
    const ruleToClone = this.field.logicRules[index];
    const newId = new ObjectId().toHexString();
    const clonedRule = { ...cloneDeep(ruleToClone), id: newId, _id: newId };

    const clonedFields = await this.getFieldsToClone(this.fields, ruleToClone, clonedRule);

    if (!clonedFields) {
      return;
    }

    const totalFieldsAfterClone = this.fields.length + clonedFields.length;
    if (totalFieldsAfterClone > this.workspaceItemsLimit) {
      this.toastrService.errorByKey('cloningWillExceedLimit');
      return;
    }

    this.field.logicRules.splice(index + 1, 0, clonedRule);

    if (clonedFields.length) {
      const insertionIndex =
        findLastIndex(this.fields, (field) => field.dependsOn?.logicRuleId === ruleToClone.id) + 1;
      this.fields.splice(insertionIndex, 0, ...clonedFields);
    }

    this.validateLogicRules();
    this.fieldUpdated.emit(this.field);
  }

  private async getFieldsToClone(fields: FieldWeb[], ruleToClone: LogicRule, clonedRule: LogicRule) {
    const clonedFields: FieldWeb[] = [];

    fields
      .filter((field) => field.dependsOn?.logicRuleId === ruleToClone.id)
      .forEach((field) => {
        const [clonedField] = this.templatesService.cloneField(field, fields);
        clonedField.dependsOn.logicRuleId = clonedRule.id;
        clonedFields.push(clonedField);
      });

    const canProceed = await this.templateListPropertyService.enforceListPropertyTableCellLimit(clonedFields);

    return canProceed ? clonedFields : null;
  }

  validateLogicRules() {
    this.logicRuleService.sharedService.isValid(this.field, this.fields);
  }

  handleFormulaFieldsRemoval(removedFields: FieldWeb[]) {
    if ((this.template.formulasVersion as FormulasVersion) !== FormulasVersion.v2) {
      return;
    }

    this.formulaV2TemplateEngineService.removeTablesFromFormulaEngine(removedFields);
  }
}
