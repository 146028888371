import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  templateUrl: './person-template-preview.component.html',
  styleUrls: ['../person-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonTemplatePreviewComponent {
  @Input() field: FieldWeb;
}
