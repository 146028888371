import { Filter } from '../filter';

export class TextFilter extends Filter {
  value;

  constructor(value, renderer) {
    super(renderer);
    this.value = value;
    this.options = [];
  }

  queryParams() {
    return this.value;
  }

  get status() {
    return this.value ? 1 : 0;
  }

  set(value) {
    if (!value) {
      this.reset();
      return;
    }

    this.value = value;
  }

  reset() {
    delete this.value;
  }
}
