import { Component, Input, OnInit } from '@angular/core';

import { Field, FieldKinds, PhotoThumbnailSizes } from '@site-mate/dashpivot-shared-library';

import { IFieldSettingsAction, FieldSettingsNames } from 'app/form-fields/common/types/field-settings.type';

@Component({
  selector: 'cc-template-field-settings',
  templateUrl: './template-field-settings.component.html',
  styleUrls: ['./template-field-settings.component.scss'],
})
export class TemplateFieldSettingsComponent implements OnInit {
  isExcludedInFormCloning = FieldSettingsNames.isExcludedInFormCloning;
  isSignatureField = false;
  readonly photoSizesWithTagsAndDescription = [PhotoThumbnailSizes.Large, PhotoThumbnailSizes.ExtraLarge];
  hasPhotoTagsAndDescription = false;

  @Input() field: Field;
  @Input() actions: IFieldSettingsAction[];

  isExpanded = false;

  ngOnInit() {
    this.isSignatureField = [FieldKinds.Signature, FieldKinds.SignatureArray].includes(this.field?.kind);

    if (this.isSignatureField) {
      this.field.isExcludedInFormCloning = true;
    }

    this.hasPhotoTagsAndDescription =
      this.field?.kind === FieldKinds.Photo &&
      this.photoSizesWithTagsAndDescription.includes(this.field?.photoThumbnailSize);
  }

  handleExpandableState() {
    this.isExpanded = !this.isExpanded;
  }
}
