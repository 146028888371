import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { PhotoThumbnailSizes, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  templateUrl: './photo-template-preview.component.html',
  styleUrls: ['./photo-template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoTemplatePreviewComponent implements OnInit {
  @Input() field: FieldWeb;
  @Input() orientation: TemplateOrientations = TemplateOrientations.Portrait;
  thumbnailSrc = '';
  readonly photoSizesWithLandscapeSize = [PhotoThumbnailSizes.ExtraLarge];

  ngOnInit() {
    this.setPhotoThumbnailChange();
  }

  setPhotoThumbnailChange() {
    this.thumbnailSrc = this.getThumbnailSrc();
  }

  getThumbnailSrc() {
    const thumbnailSize = this.field.photoThumbnailSize || PhotoThumbnailSizes.Small;
    const shouldAddLandscapeOrientation =
      this.photoSizesWithLandscapeSize.includes(thumbnailSize) &&
      this.orientation === TemplateOrientations.Landscape;
    const orientation = shouldAddLandscapeOrientation ? '-landscape' : '';
    return `assets/images/template-editor/photo-thumbnail-sizes/thumbnail-${thumbnailSize}${orientation}.svg`;
  }
}
