import { Component, Input, inject, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, catchError, combineLatest, map, of, switchMap } from 'rxjs';

import {
  IPersonFieldData,
  IUser,
  IUserWithSummaryOrWithoutData,
  PersonFieldUtils,
} from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';

import { FormPersonService } from '../form-person.service';

export interface IPersonDiff {
  _id: string;
  label: string;
}

@Component({
  selector: 'cc-form-person-diff',
  templateUrl: './form-person-diff.component.html',
})
export class FormPersonDiffComponent {
  @Input() field: FieldWeb;
  destroyRef = inject(DestroyRef);
  loading: boolean;
  error: boolean = false;
  personsToDisplay: IPersonDiff[];

  constructor(
    private readonly formPersonService: FormPersonService,
    private readonly cdr: ChangeDetectorRef,
    private readonly errorHandler: ErrorHandler,
  ) {}

  ngOnInit(): void {
    this.error = false;

    this.formPersonService
      .getTeamUsersInOpenedForm()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(({ contributors, visitors }) => this.mergePeople(contributors, visitors)),
        switchMap((persons) => this.processPeople(persons)),
      )
      .subscribe((persons: IPersonDiff[]) => {
        this.personsToDisplay = persons;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private mergePeople(contributors, visitors): IUser[] {
    return [...contributors, ...visitors];
  }

  private processPeople(persons: IUser[]) {
    const userIds = (this.field.data as IPersonFieldData)?.userIds;
    if (userIds.length === 0) {
      return of(null);
    }

    const userIdsSet: Set<string> = new Set(userIds);
    const matchingUsersObservable = persons
      .filter((user) => userIdsSet.has(user.sitemateUserId))
      .map((person) => of({ _id: person.sitemateUserId, label: person.fullName } as IPersonDiff));
    let missingUsersObservable = [];
    if (matchingUsersObservable.length !== userIdsSet.size) {
      const nonMatchingUsers = [...userIdsSet].filter(
        (_id) => !persons.some((person) => person.sitemateUserId === _id),
      );
      missingUsersObservable = nonMatchingUsers.map((personId) => this.fetchUserDetails(personId));
    }

    return combineLatest(matchingUsersObservable.concat(missingUsersObservable));
  }

  private fetchUserDetails(userId: string): Observable<IPersonDiff> {
    this.loading = true;

    return this.formPersonService.searchUser(userId).pipe(
      map((user: IUserWithSummaryOrWithoutData): IPersonDiff => {
        return {
          _id: userId,
          label: PersonFieldUtils.personFieldLabel(user),
        } as IPersonDiff;
      }),
      catchError((error) => {
        this.errorHandler.handle(error);
        this.error = true;
        return of(null);
      }),
    );
  }
}
