import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, iif, Observable, of, switchMap, take } from 'rxjs';

import { ILegacyList, IList } from '@site-mate/dashpivot-shared-library';

import { ListQueryCacheService } from 'app/lists/list-query-cache.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { Query } from 'app/shared/model/query/query.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { TeamService } from 'app/shared/service/team.service';

export type ListIdentifierOnly = { _id: ILegacyList['_id']; name: ILegacyList['name'] };

type ITableListMap = Map<FieldWeb['_id'], BehaviorSubject<IListMap>>;
type IListMap = Map<number, ILegacyList['_id']>;

@Injectable({ providedIn: 'root' })
export class TemplateListService {
  private readonly cacheService = inject(ListQueryCacheService);
  private readonly tableListColumnMap: ITableListMap = new Map();
  private readonly listIdentifiersSubject = new BehaviorSubject<Query<ListIdentifierOnly[]>>({
    state: QueryStates.Loading,
  });
  listIdentifiers$ = this.listIdentifiersSubject.asObservable();

  private readonly fullLegacyListMap = new Map<string, BehaviorSubject<Query<ILegacyList>>>();
  private readonly fullListMap = new Map<string, BehaviorSubject<Query<IList>>>();

  constructor(
    private readonly teamService: TeamService,
    private readonly httpClientService: HttpClientService,
    private readonly errorHandler: ErrorHandler,
  ) {}

  getListColumnMap(tableId: FieldWeb['_id']): Observable<IListMap> {
    return this.getListColumnMapSubject(tableId).asObservable();
  }

  setListColumnMap(tableId: FieldWeb['_id'], listColumnMap: IListMap) {
    this.getListColumnMapSubject(tableId).next(listColumnMap);
  }

  removeListColumnMap(tableId: FieldWeb['_id']) {
    this.tableListColumnMap.delete(tableId);
  }

  onListReferenceChange(tableId: string, columnIndex: number, reference: ILegacyList['_id']) {
    const columnMap = new Map(this.getListColumnMapSubject(tableId).value);

    if (!reference) {
      columnMap.delete(columnIndex);
    } else {
      columnMap.set(columnIndex, reference);
    }

    this.setListColumnMap(tableId, columnMap);
  }

  private getListColumnMapSubject(tableId: FieldWeb['_id']): BehaviorSubject<IListMap> {
    if (!this.tableListColumnMap.has(tableId)) {
      this.tableListColumnMap.set(tableId, new BehaviorSubject<IListMap>(new Map()));
    }

    return this.tableListColumnMap.get(tableId);
  }

  fetchListIdentifiersForCurrentFolder() {
    this.listIdentifiersSubject.next({ state: QueryStates.Loading });

    this.teamService.currentTeam
      .pipe(
        filter((folder: TeamWeb) => Boolean(folder.id || folder._id)),
        take(1),
        switchMap(this.getAPICallBasedOnFolderType.bind(this)),
        switchMap(this.mapToListIdentifierQuery.bind(this)),
        catchError((error) => {
          this.errorHandler.handle(error);
          return of({ state: QueryStates.Error, error } as Query<ListIdentifierOnly[]>);
        }),
      )
      .subscribe((listIdentifiersQuery) => {
        this.listIdentifiersSubject.next(listIdentifiersQuery);
      });

    return this.listIdentifiers$;
  }

  private getAPICallBasedOnFolderType(folder: TeamWeb) {
    return iif(
      () => this.teamService.isTeam(folder),
      this.httpClientService.get<Partial<ILegacyList>[]>(
        `v1/projects/${folder.projectId}/lists?onlyNames=true`,
      ),
      this.httpClientService.get<Partial<ILegacyList>[]>(`v1/companies/${folder._id}/lists?onlyNames=true`),
    );
  }

  private mapToListIdentifierQuery(partialLists: Partial<ILegacyList>[]) {
    const listIdentifiers = partialLists.map(
      (partialList) => ({ _id: partialList._id, name: partialList.name }) as ListIdentifierOnly,
    );

    return of({ state: QueryStates.Fetched, data: listIdentifiers } as Query<ListIdentifierOnly[]>);
  }

  getFullLegacyList(listId: string): Observable<Query<ILegacyList>> {
    const URL = `v1/lists/${listId}`;
    return this.cacheService.getCachedData<ILegacyList>(listId, URL, this.fullLegacyListMap);
  }

  getFullList(listId: string): Observable<Query<IList>> {
    const URL = `gi/legacy/${listId}`;
    return this.cacheService.getCachedData<IList>(listId, URL, this.fullListMap);
  }

  clearMetadata() {
    this.fullLegacyListMap.clear();
    this.fullListMap.clear();
    this.tableListColumnMap.clear();
  }
}
