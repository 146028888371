import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class PhotoColumn extends FormFieldColumn {
  photos: any[];
  readonly metadata: Record<string, string>;

  constructor(item, renderer, metadata?: Record<string, string>) {
    super(item, renderer);

    this.photos = item.photos;
    this.metadata = { ...metadata };
  }
}
