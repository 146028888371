<div class="column" [class.column-sm]="column.static">
  <cc-form-photo-list
    *ngIf="column.photos?.length"
    [photos]="column.photos"
    [readOnly]="true"
    [item]="column"
    (viewPhoto)="onViewPhoto($event)"
  >
  </cc-form-photo-list>
  <div *ngIf="!column.photos?.length" class="text-muted" i18n>-</div>
</div>
