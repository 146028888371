<ng-select
  [ngModel]="column.kind"
  (change)="onColumnKindChange($event.kind)"
  appendTo="body"
  [clearable]="false"
  [items]="columnTypes"
  bindValue="kind"
  (open)="handleTypeDropdownOpen()"
>
  <ng-template ng-option-tmp let-item="item">
    <ng-container *ngIf="!item.disabled; else disabledOption">
      <span>{{ item.label }}</span>
    </ng-container>
    <ng-template #disabledOption>
      <ng-container *ngIf="item.kind === TableCellKinds.Location">
        <div
          class="sm-flex sm-flex-1 sm-cursor-not-allowed sm-flex-row sm-items-center sm-justify-between sm-gap-x-1 sm-text-default-text sm-opacity-50"
        >
          <span i18n>{{ item.label }}</span>
          <gw-badge color="blue" text="Coming soon" size="small" [disabled]="true"></gw-badge>
        </div>
      </ng-container>
      <cc-popover *ngIf="item.kind === TableCellKinds.ListProperty" placement="right" container="body">
        <span i18n content>Maximum of 3 List Property Table Cells can be added to a template.</span>
        <div
          trigger
          class="sm-flex sm-flex-1 sm-cursor-not-allowed sm-flex-row sm-items-center sm-gap-x-1 sm-text-secondary-text"
        >
          <span>{{ item.label }}</span>
        </div>
      </cc-popover>
    </ng-template>
  </ng-template>
</ng-select>

<ng-container [ngSwitch]="column.kind">
  <ng-container *ngSwitchCase="TableCellKinds.List" #tableCell></ng-container>
  <ng-container *ngSwitchCase="TableCellKinds.Formula" #tableCell></ng-container>
  <ng-container *ngSwitchCase="TableCellKinds.ListProperty" #tableCell></ng-container>

  <div data-test="attachment-cell" *ngSwitchCase="TableCellKinds.Attachment">
    <button type="button" class="btn btn-primary btn-wrapped disabled sm-cursor-grab" i18n>
      <span class="sm-pl-1">Add PDF</span><i class="cc-icon cc-icon-attachment sm-px-1"></i>
    </button>
  </div>
  <div data-test="manual-signature-cell" *ngSwitchCase="TableCellKinds.ManualSignature">
    <button type="button" class="btn btn-success btn-wrapped disabled sm-cursor-grab">
      <span class="sm-pl-1">Sign manually</span> <i class="cc-icon cc-icon-signature sm-px-1"></i>
    </button>
  </div>

  <div data-test="number-cell" *ngSwitchCase="TableCellKinds.Number">
    <ng-container *ngTemplateOutlet="labelTextInput"></ng-container>
  </div>

  <div data-test="photo-cell" *ngSwitchCase="TableCellKinds.Photo">
    <button type="button" class="btn btn-primary btn-wrapped disabled sm-cursor-grab" i18n>
      <span class="sm-pl-1">Add photos</span>
      <i class="cc-icon cc-icon-photo sm-px-1"></i>
    </button>
  </div>
  <div data-test="prefilled-text-cell" *ngSwitchCase="TableCellKinds.PrefilledText">
    <div
      class="text-editor-preview rich-generated-content"
      (click)="setEditingPrefillText(true)"
      (focus)="setEditingPrefillText(true)"
      [hidden]="editingPrefillText"
      tabindex="0"
    >
      <div *ngIf="!column.content" class="text-muted" i18n>Click to edit</div>
      <div *ngIf="column.content" class="editor-content" [innerHTML]="column.content | safeHtml"></div>
    </div>
    <cc-rich-editor
      *ngIf="editingPrefillText"
      (blur)="setEditingPrefillText(false)"
      [model]="column"
      [editorToolbarOption]="editorToolbarOption"
      [autoFocus]="true"
    ></cc-rich-editor>
  </div>
  <div data-test="signature-cell" *ngSwitchCase="TableCellKinds.Signature">
    <button type="button" class="btn btn-success btn-wrapped disabled sm-cursor-grab" i18n>
      <span class="sm-pl-1">Sign</span>
      <i class="cc-icon cc-icon-signature sm-px-1"></i>
    </button>
  </div>
  <div data-test="text-cell" *ngSwitchCase="TableCellKinds.Text">
    <ng-container *ngTemplateOutlet="labelTextInput"></ng-container>
  </div>
  <div data-test="location-cell" *ngSwitchCase="TableCellKinds.Location">
    <cc-template-table-location-cell></cc-template-table-location-cell>
  </div>
</ng-container>
<ng-template #labelTextInput>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="column.description"
    name="{{ 'column-text-' + id }}"
    i18n-placeholder="Add label text"
    placeholder="Add label text"
  />
</ng-template>
