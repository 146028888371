import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BehaviorSubject, debounceTime, filter, Subject, take, takeUntil, tap } from 'rxjs';

import {
  GlobalItemModels,
  ILegacyList,
  IList,
  IListProperty,
  TableColumn,
} from '@site-mate/dashpivot-shared-library';

import { isFetchedQuery } from 'app/shared/model/query/query-predicates';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { Query } from 'app/shared/model/query/query.model';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { ListCellTypes } from './list-cell-types';
import { ListIdentifierOnly, TemplateListService } from '../../../../../apps/template-list.service';
import { ITemplateTableCell } from '../template-table-cell.interface';

interface IAvailableList extends ListIdentifierOnly {
  columnIndex: number;
}

interface ISelectListProperty extends Omit<IListProperty, 'subscribedUsers'> {
  disabled?: boolean;
}

@Component({
  selector: 'cc-template-table-list-property-cell',
  standalone: true,
  imports: [CommonModule, FormsModule, UIComponentsModule, NgSelectModule, ReactiveFormsModule],
  templateUrl: './template-table-list-property-cell.component.html',
  styleUrls: ['./template-table-list-property-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateTableListPropertyCellComponent implements ITemplateTableCell, OnDestroy {
  private readonly templateListsService = inject(TemplateListService);
  private readonly fb = inject(FormBuilder);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly unSubscribe = new Subject<void>();
  readonly GlobalItemModels = GlobalItemModels;
  readonly articleLink =
    'https://intercom.help/dashpivot/en/articles/9120893-template-editing-on-dashpivot-web-list-property-table-cell';
  readonly QueryStates = QueryStates;

  @Input() cellData: TableColumn;
  @Input() tableColumns: TableColumn[];
  @Input() tableId: string;
  @Output() onChange = new EventEmitter<void>();

  form = this.fb.group({
    selectedList: new FormControl<IAvailableList>(null, Validators.required),
    selectedProperty: new FormControl<ISelectListProperty>(
      { value: null, disabled: true },
      Validators.required,
    ),
    selectedPropertyType: new FormControl<string>(null),
  });

  protected readonly listsStatus = new BehaviorSubject<Query<ListIdentifierOnly[]>>({
    state: QueryStates.Loading,
  });
  protected readonly propertiesStatus = new BehaviorSubject<Partial<Query<IList>>>({
    state: QueryStates.Empty,
  });

  listProperties: ISelectListProperty[] = [];
  selectableLists = new Map<number, IAvailableList>();
  private allLists = new Map<string, ListIdentifierOnly>();

  ngOnInit() {
    this.cellData.metadata = this.cellData.metadata || {
      listId: null,
      legacyListId: null,
      referenceTableColumnId: null,
    };

    this.cellData.data = this.cellData.data || { itemId: null, legacyId: null };
    this.setupFormData();
    this.getColumnData();
  }

  private fetchColumnOptions() {
    this.templateListsService
      .getListColumnMap(this.tableId)
      .pipe(takeUntil(this.unSubscribe), debounceTime(100))
      .subscribe((listColumnMap) => {
        this.getColumnOptions(listColumnMap);
      });
  }

  public checkForErrors() {
    if (!this.selectedList.value) {
      this.selectedList.markAsTouched();
    }
    if (!this.selectedProperty.value) {
      this.selectedProperty.markAsTouched();
    }

    this.cdr.markForCheck();
  }

  private setupFormData() {
    this.form
      .get('selectedList')
      .valueChanges.pipe(takeUntil(this.unSubscribe))
      .subscribe((event) => this.updateList(event));
    this.form
      .get('selectedProperty')
      .valueChanges.pipe(takeUntil(this.unSubscribe))
      .subscribe((property) => this.updateProperty(property));
  }

  private updateList(event: IAvailableList) {
    const { _id, columnIndex } = event || {};
    const tableColumn = this.tableColumns[columnIndex];
    this.cellData.model = null;
    this.cellData.data = { itemId: null, legacyId: null };
    this.cellData.metadata = {
      legacyListId: _id || null,
      listId: null,
      referenceTableColumnId: tableColumn?._id || tableColumn?.id || null,
    };

    if (_id) {
      this.fetchListProperties(_id);
    }
  }

  private updateProperty(event: ISelectListProperty) {
    const { model, _id, legacyId } = event || {};
    this.cellData.data = { itemId: _id, legacyId };
    this.cellData.model = model;
    this.form.get('selectedPropertyType').patchValue(ListCellTypes[model]);
    this.onChange.emit();
  }

  private getColumnData() {
    this.templateListsService.listIdentifiers$.pipe(filter(isFetchedQuery), take(1)).subscribe(({ data }) => {
      this.allLists.clear();
      data.forEach((list) => this.allLists.set(list._id, list));
      this.fetchColumnOptions();
    });
  }

  private getColumnOptions(listColumnMap: Map<number, ILegacyList['_id']>) {
    this.selectableLists.clear();

    const selectedList = this.loadSelectableLists(listColumnMap);
    this.updateFormValues(selectedList);

    this.selectedList.setErrors({ ...this.selectedList.errors, empty: this.selectableLists.size === 0 });

    if (this.selectableLists.size === 0) {
      this.selectedList.markAsTouched();
    }

    this.listsStatus.next({ state: QueryStates.Fetched } as Query<ListIdentifierOnly[]>);
    this.restoreData();
    this.cdr.markForCheck();
  }

  private loadSelectableLists(listColumnMap: Map<number, ILegacyList['_id']>): IAvailableList {
    let selectedList: IAvailableList = null;

    listColumnMap.forEach((listId, columnIndex) => {
      if (this.allLists.has(listId)) {
        const { name, _id } = this.allLists.get(listId);
        this.selectableLists.set(columnIndex, { name, _id, columnIndex });
      }

      if (listId === this.cellData.metadata.legacyListId) {
        selectedList = this.selectableLists.get(columnIndex);
      }
    });

    return selectedList;
  }

  private updateFormValues(selectedList: IAvailableList) {
    const previousSelectedListId = this.cellData.metadata.legacyListId;
    if (selectedList) {
      this.selectedList.patchValue(selectedList, { emitEvent: false });
      return;
    }

    if (!this.selectedList.errors?.deleted) {
      this.selectedList.patchValue(null, { emitEvent: false });
      this.selectedProperty.patchValue(null, { emitEvent: false });
      this.selectedProperty.disable({ emitEvent: false });
      this.form.get('selectedPropertyType').patchValue(null, { emitEvent: false });
    }

    if (previousSelectedListId) {
      if (!isFetchedQuery(this.listsStatus.value)) {
        this.selectedList.setErrors({ deleted: true });
      }

      this.selectedList.markAsTouched();
      this.updateList(null);
    }
  }

  get selectedList(): AbstractControl<IAvailableList> {
    return this.form.get('selectedList');
  }

  get selectedProperty(): AbstractControl<ISelectListProperty> {
    return this.form.get('selectedProperty');
  }

  private restoreData() {
    if (this.cellData.data.itemId && this.selectedList.value) {
      this.fetchListProperties(this.cellData.metadata.legacyListId);
    }
  }

  fetchListProperties(listId: IList['_id']) {
    this.selectedProperty.patchValue(null, { emitEvent: false });
    this.propertiesStatus.next({ state: QueryStates.Loading });
    this.templateListsService
      .getFullList(listId)
      .pipe(
        tap(({ state }) => this.propertiesStatus.next({ state })),
        filter(isFetchedQuery),
        take(1),
      )
      .subscribe(({ data: list }) => {
        this.cellData.metadata.listId = list._id;
        this.updateSelectedProperty(list.data.properties);
        this.handleFetchedProperties(list.data.properties);
        this.cdr.markForCheck();
      });
  }

  private updateSelectedProperty(properties: ISelectListProperty[]) {
    this.listProperties = properties;
    this.listProperties.forEach((property) => {
      if (property._id === this.cellData.data.itemId) {
        this.selectedProperty.patchValue(property, { emitEvent: false });
        this.form.get('selectedPropertyType').patchValue(ListCellTypes[property.model]);
      }
      if (property.model === GlobalItemModels.AttachmentSimple) {
        property.disabled = true;
      }
    });
  }

  private handleFetchedProperties(properties: ISelectListProperty[]) {
    this.selectedProperty.enable({ emitEvent: false });

    if (!properties.length) {
      this.selectedProperty.setErrors({ empty: true });
    }

    if (this.cellData.data.itemId && !this.selectedProperty.value) {
      this.selectedProperty.setErrors({ deleted: true });
    }

    if (this.selectedProperty.errors?.empty || this.selectedProperty.errors?.deleted) {
      delete this.cellData.data.itemId;
      this.selectedProperty.markAsTouched();
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
