<div class="font-weight-bold mb-3">{{ field.description }}</div>
<div class="font-weight-bold mb-3" *ngIf="field.isRequired">
  <span i18n>Required:</span> <input type="checkbox" disabled [ngModel]="field.isRequired" />
</div>

<div class="app-control-control">
  <div
    class="sketch-canvas"
    [class.custom-background]="field.background"
    [style.background-image]="getBackgroundUrl()"
  ></div>
</div>
