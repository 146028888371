import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-signature-list-diff',
  templateUrl: './form-signature-list-diff.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignatureListDiffComponent {
  @Input() field: FieldWeb;
  @Input() isLandscapeMode: boolean = false;
}
