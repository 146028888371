<div class="app-category-item">
  <div *ngIf="!isEdit" class="d-flex">
    <div class="form-field-overflow sm-flex-row">{{ row.value }}</div>
    <div *ngIf="showControl" class="mx-2">
      <a (click)="edit()" class="text-link">
        <small i18n>Edit</small>
      </a>
    </div>
  </div>
  <div *ngIf="isEdit" class="w-100">
    <input
      type="text"
      class="form-control form-control-sm"
      name="category-item"
      [(ngModel)]="editedRowName"
      ccFocus
      (keyup.enter)="saveEdit()"
    />
    <a (click)="saveEdit()" class="text-link">
      <small i18n>Done</small>
    </a>
    <a (click)="cancelEdit()" class="text-link">
      <small i18n>Cancel</small>
    </a>
  </div>

  <div class="category-item-control" *ngIf="!isEdit">
    <cc-style-color-picker
      *ngIf="displayColorPicker"
      [(style)]="row.style"
      (styleChange)="styleUpdated($event)"
      [disabled]="!showControl"
    ></cc-style-color-picker>
    <button
      *ngIf="showControl"
      class="sm-ml-1"
      (click)="remove.emit(row.id)"
      title="Remove"
      i18n-title="Remove"
      type="button"
    >
      <i class="cc-icon cc-icon-close sm-align-middle sm-text-default-red hover:sm-text-secondary-red"> </i>
    </button>
  </div>
</div>
