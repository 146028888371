import { Component, Input, EventEmitter } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

import { IFieldSettingsAction, FieldSettingsNames } from '../../types/field-settings.type';

export type FieldActionsType = 'clone' | 'delete';

export interface IFieldActionsConfig {
  isVisible: boolean;
  onUpdate: EventEmitter<any>;
}

@Component({
  selector: 'cc-template-field-actions',
  templateUrl: './template-field-actions.component.html',
  styleUrls: ['./template-field-actions.component.scss'],
})
export class TemplateFieldActionsComponent {
  hasRequiredField: boolean;

  @Input() field: FieldWeb;

  @Input() fieldActionsConfig: Record<FieldActionsType, IFieldActionsConfig>;

  @Input() fieldSettingsActions: IFieldSettingsAction[];

  @Input() disabled: boolean;

  ngOnInit() {
    this.hasRequiredField = this.fieldSettingsActions.some(
      (action) => action.fieldName === FieldSettingsNames.IsRequired && action.isVisible,
    );
  }
}
