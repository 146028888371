import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TemplatesService } from 'app/apps/templates.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FormulasVersion } from 'app/shared/service/formula-engine.service';
import {
  FormulasErrors,
  FormulaErrorTypes,
} from 'app/shared/service/formula-v2-validation/formula-error-messages';

import { FormulaBarService, IFormulaBar } from '../../formula-bar/formula-bar.service';
import { ITemplateTableCell } from '../template-table-cell.interface';

@Component({
  templateUrl: 'template-table-formula-cell.component.html',
  styleUrls: ['template-table-formula-cell.component.scss'],
})
export class TemplateTableFormulaCellComponent implements ITemplateTableCell, OnInit, OnDestroy {
  @Input() cellData: IFormulaBar;
  @Input() tableId: string;
  @Output() change: EventEmitter<string>;
  unSubscribe = new Subject<void>();
  emptyFormulaError: string;
  usingLegacyFormulaEngine: boolean;
  legacyFormulaEngineWarningText: string;
  isFocused = false;

  constructor(
    private readonly formulaBarService: FormulaBarService,
    private readonly i18nService: TmpI18NService,
    private readonly templatesService: TemplatesService,
  ) {}

  ngOnInit(): void {
    this.emptyFormulaError = FormulasErrors.get(FormulaErrorTypes.EMPTY).message;
    this.legacyFormulaEngineWarningText = this.i18nService.getMessage('legacyFormulaEngineWarning');
    this.subscribeToFormulasVersion();
    this.subscribeToFormulaFocusUpdates();
    this.subscribeToFormulaBarEvents();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  private subscribeToFormulaFocusUpdates() {
    this.formulaBarService.formulaOnFocus$.pipe(takeUntil(this.unSubscribe)).subscribe((formula) => {
      this.isFocused = this.isCurrentFormula(formula);
    });
  }

  onFormulaChanged(formula: string) {
    this.cellData.formula = formula;
    this.change.emit();
    this.setFormulaCellValues();
  }

  private subscribeToFormulaBarEvents() {
    this.formulaBarService.formulaBarValue$
      .pipe(takeUntil(this.unSubscribe), filter(this.isCurrentFormula.bind(this)))
      .subscribe((formulaCellData: IFormulaBar) => {
        this.cellData.formula = formulaCellData.formula;
        this.change.emit();

        if (!formulaCellData.formulaBarClosed) {
          this.setFormulaCellValues(); // This is needed to be after the change event to propagate the errors back to the formula bar
        }
      });
  }

  private isCurrentFormula(formula: IFormulaBar) {
    return formula?.id === this.cellData.id && formula?.tableId === this.tableId;
  }

  private setFormulaCellValues() {
    this.formulaBarService.setFormulaCellValue({
      id: this.cellData.id,
      tableId: this.tableId,
      formula: this.cellData.formula,
      formulaError: this.cellData.formulaError,
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.formulaBarService.openFormulaBar({ ...this.cellData, tableId: this.tableId });
  }

  private subscribeToFormulasVersion() {
    this.templatesService.currentOpenTemplateFormulasVersion
      .pipe(
        takeUntil(this.unSubscribe),
        filter((version) => Boolean(version)),
      )
      .subscribe((version: FormulasVersion) => {
        this.usingLegacyFormulaEngine = version !== FormulasVersion.v2;
        if (this.isFocused) {
          this.setFormulaCellValues();
        }
      });
  }
}
