import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PhotoModalService } from 'app/photo/photo-modal.service';
import { PhotoService } from 'app/photo/photo.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';

import { PhotoColumn } from './photo-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  selector: 'cc-photo-column',
  templateUrl: 'photo-column.component.html',
  styleUrls: ['../../common/register/form-field-column/form-field-column.component.scss'],
})
export class PhotoColumnComponent extends FormFieldColumnComponent implements OnInit, OnDestroy {
  private kmlFile: string;
  private unSubscribe = new Subject<void>();
  @Input() column: PhotoColumn;

  constructor(
    private readonly photoService: PhotoService,
    private readonly photoModal: PhotoModalService,
    private readonly errorHandler: ErrorHandler,
  ) {
    super();
  }

  ngOnInit(): void {
    this.photoService.kmlFileEvent.pipe(takeUntil(this.unSubscribe)).subscribe((kmlFile) => {
      this.kmlFile = kmlFile;
    }, this.errorHandler.handle.bind(this.errorHandler));
  }

  onViewPhoto({ item, index }) {
    this.photoModal.open({
      timeLineIndex: 0,
      timeLines: [{ photos: item.photos }],
      photoIndex: index,
      kmlFile: this.kmlFile,
      hideDeleteButton: true,
      teamId: this.column.metadata.team,
      projectId: this.column.metadata.project,
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
