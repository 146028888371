<div *ngIf="field.startDate || field.endDate">
  <div>
    <small i18n *ngIf="field.isExpiryDate">Expiry:</small>
    <small *ngIf="!field.isSingleDate" i18n>Start :</small>
    <small *ngIf="field.isSingleDate && !field.isExpiryDate" i18n>Date :</small>
    <small *ngIf="field.isDateAndTime">{{ field.startDate | amDateFormat: 'LT' }}</small>
    <small>{{ field.startDate | amDateFormat: 'll' }}</small>
  </div>

  <div *ngIf="!field.isSingleDate" class="d-inline-block">
    <small i18n>End :</small>
    <small *ngIf="field.isDateAndTime">{{ field.endDate | amDateFormat: 'LT' }}</small>
    <small>{{ field.endDate | amDateFormat: 'll' }}</small>
  </div>
</div>
<div *ngIf="!field.startDate && !field.endDate">
  <small i18n>No date selected</small>
</div>
